<template>
  <div id="history" class="fill-height">
    <Navbar />
    <v-container fluid class="pt-0 pb-0 fill-height">
      <v-row class="fill-height">
        <v-col cols="12" sm="12" order="2" order-sm="2" order-md="1" order-lg="1"
          class="pt-5 pl-2">
          <h4 class="title">Materi Pembelajaran</h4>
          <template>
            <v-snackbar
              v-model="snackbar"
              :timeout="timeout"
            >
              {{ text }}
              <template v-slot:action="{ attrs }">
                <v-btn
                  color="blue"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  Close
                </v-btn>
              </template>
            </v-snackbar>
        <v-dialog v-model="konfirmHapus" max-width="400px">
          <v-card>
            <v-card-title>
            <span>Konfirmasi</span>
            </v-card-title>
            <v-card-text>
            <v-container>
              <span>Anda yakin menghapus data ini?</span>
            </v-container>
            </v-card-text>

            <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn color="blue darken-1" @click="konfirmHapus = false">Batal</v-btn>
              <v-btn color="blue darken-1" @click="delData">Ya</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="fmAddMedia" max-width="400px">
          <v-card>
            <v-card-title>
            <span>Tambah Media</span>
            </v-card-title>
            <v-card-text>
            <v-container>
          <v-col  cols="12" sm="12" md="12">
                  <v-select item-text="nm_media" item-value="jm_id" :items="lsJMedia"
                    label="Jenis Media" v-model="jm_id" required></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="12" v-if="jm_id=='1'">
                  <v-file-input hidden
                  label="File"
                  filled
                  prepend-icon="mdi-file-pdf-box"
                  v-model="files"
                  accept="application/pdf"
                  @change="pilihGbr"
                  >
                  </v-file-input>
                </v-col>
                <v-col cols="12" sm="12" md="12" v-else>
                  <v-text-field label="Youtube" v-model="yt_id"></v-text-field>
                </v-col>
            </v-container>
            </v-card-text>

            <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn color="blue darken-1" @click="fmAddMedia = false">Batal</v-btn>
              <v-btn color="blue darken-1" @click="saveMedia">Ok</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
          <v-row align="center" class="px-5 mx-auto mt-20" v-if="lsStatus==true">
            <!--
              <v-col cols="12" md="4">
              <v-select item-text="nm_ta" item-value="ta_id" :items="lsTA" label="Tahun Ajaran" v-model="ta_id" 
              required v-on:change="gtTALoad()"></v-select>
             </v-col>
             -->
            <v-col cols="12" md="4">
              <v-text-field v-model="text_cari" label="cari..." v-on:keyup="inputKeydown">></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
                <v-btn color="cyan" class="mb-0" @click="fnBaru()" small>+ Data</v-btn>
            </v-col>
            <v-col cols="12" sm="12">
              <v-card class="mx-auto" tile>
                <v-data-table
                  :headers="headers"
                  :items="ftData"
                  :disable-pagination="false"
                  :hide-default-footer="false"
                  :loading="loading"
                  loading-text="Loading... Please wait"
                >
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="fnEdit(item.gm_id)">mdi-pencil </v-icon>
                    <v-icon small @click="fnKonfirmasi(item.gm_id,'data',1)">mdi-delete</v-icon>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        <v-row v-if="lsStatus==false" class="pt-5 pl-2">
          <v-spacer></v-spacer>
              <br><br><br>
              <v-col  cols="12" sm="4" md="4">
              <v-select item-text="nm_kelas" item-value="kelas_id" :items="lsKelas" label="Kelas" 
              v-model="kelas_id" required v-on:change="gtKelas()"></v-select>
            </v-col>
          <v-col  cols="12" sm="4" md="4">
            <v-select item-text="nm_lokal_kelas" item-value="kelas_lok_id" outlined :items="ftKelasLokal" label="Lokal"
              v-model="kelasLokal_id" required multiple chips></v-select>
          </v-col>
            <v-col  cols="12" sm="4" md="4">
              <v-select item-text="label" item-value="id" :items="ddSemester" label="Semester" v-model="semester_id" required></v-select>
            </v-col>
              <v-col  cols="12" sm="4" md="4">
              <v-select item-text="label" item-value="id"  :items="ddMapel" label="Mata Pelajaran" v-model="mapel_id" required></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field label="Bahasan" v-model="bahasan"></v-text-field>
            </v-col>
          <v-spacer></v-spacer>

          <v-btn color="cyan" @click="fnAddMedia">+ Media</v-btn>
           <v-col cols="12" sm="6" md="6">
              <v-btn color="cyan" @click="lsStatus = true">Tutup</v-btn>
              <v-btn color="cyan" @click="saveData">Save</v-btn>
            </v-col>
            <v-col cols="12" sm="12" md="12">
            <div v-for="item in lsMedia" :key="item.gm_id2">
              <v-list-item two-line>
                <v-list-item-content>
                  <div v-if="item.jMedia==1">
                    <v-list-item-title @click="gotoPdf(item.file)">
                      <v-list-item-avatar>
                      <v-img src="@/assets/pdf.png"></v-img>
                    </v-list-item-avatar>
                      file : {{ item.file }} </v-list-item-title>
                    <v-list-item-subtitle>PDF</v-list-item-subtitle>
                  </div>
                  <div v-else>
                    <v-list-item-title @click="gotoGoogle(item.yt_id)">
                      <v-list-item-avatar>
                      <v-img src="@/assets/yt.png" @click="gotoGoogle(item.yt_id)"></v-img>
                    </v-list-item-avatar>
                    {{ item.yt_id }} link Youtube
                      </v-list-item-title>
                    <v-list-item-subtitle>Youtube</v-list-item-subtitle>
                  </div>
                  <v-icon @click="fnKonfirmasi(item.gm_id2,'media',item.st)">mdi-delete</v-icon>
                </v-list-item-content>
              </v-list-item>
            </div>
          </v-col>
        </v-row>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import { api } from '@/components/Apiku'
import axios from 'axios'
import readXlsxFile from 'read-excel-file';
import "vue-wysiwyg/dist/vueWysiwyg.css";

export default {
  data: () => ({
    snackbar: false, fmAddMedia: false,
    lsStatus:true,
    konfirmHapus:false,st_form:'add',
    text: 'OK',
    timeout: 2000, text_cari:'',
    title:'',
    loading: true, ftKelasLokal :[],
    lsData : [], lsKelasLokal :[], kelasLokal_id : [],
    ftData : [],
    dialog : false,
    menuKat : false,
    dialogImpor:false,
    searchTextsLoading: true,
    bahasan: '',
    kode: '',
    myHTML:'',
    yt_id: '',
    id: 0,
    lokal:'',
    ddMapel : [],
    dtImpor:[],
    files: null,
    mapel_id:'',
    kelas_id:1,
    guru_id:0, ta_id:0,
    semester_id:0, lsGuru:[],
    lsKelas:[],
    lsTA:[],
    lsJMedia:[],
    jm_id:1,is_yt:false,st_hapus2:'',
    image: {
    uploadURL: "/api/myEndpoint",
    dropzoneOptions: {},st_hapus:''
  },

  maxHeight: "500px",
  lsMedia:[],
  ddSemester:[{"id":"1","label":"Semester 1"},{"id":"2","label":"Semester 2"}],
  headers: [
    { text: "Bahasan", align: "start", sortable: false, value: "bahasan" },
    { text: "Mata Pelajaran", value: "nm_mapel", sortable: false },
    { text: "Kelas", value: "nm_kelas", sortable: false },
    { text: "Lokal", value: "lokal_kelas", sortable: false },
    { text: "Aksi", value: "actions", sortable: false },
  ]
  }),
  components:{
    Navbar
  },
  methods: {
    inputKeydown: function () {
      if(this.text_cari.length>0){
        this.ftData = this.lsData.filter(m => m.bahasan.toUpperCase().includes(this.text_cari.toUpperCase()))
      }else{
        this.ftData = this.lsData
      }
    },
    gtTALoad(){
      //this.ftData = this.lsData.filter(m => m.ta_id.toUpperCase().includes(this.ta_id.toUpperCase()))
      //this.fnData()
    },
    onFileChange(event) {
      let xlsxfile = event.target.files ? event.target.files[0] : null;
      readXlsxFile(xlsxfile).then((rows) => {
        this.dtImpor=rows
      })
    },
    gotoGoogle(id) {
      window.open("https://www.youtube.com/watch?v="+ id);
    },
    gotoPdf(id) {
      window.open("https://admin.jidismart.com/pdf/"+ id);
    },
    gtKelas(){
      this.kelasLokal_id=[]
      this.ftKelasLokal = this.lsKelasLokal.filter(m => m.kelas_id.includes(this.kelas_id))
    },
    pilihGbr () {
      if(this.files){
        //console.log('cek ', this.files)  
        const formData = new FormData()
        formData.append("uploadfile", this.files)
        axios
          .post(api + 'uploadPdf', formData)
          .then(response => {
            this.fileGbr = response.data.data
            this.fileGbr2 = response.data.fgbr
            if (response.data.cek === 1) {
              //console.log(' sukses ')
            } else {
              //console.log(' tidak sukses ')
            }
          })
        // console.log(' gbr ', file)
      }
    },
    fnKonfirmasi(id,st,st2){
      this.id_hapus=id
      this.konfirmHapus=true
      this.st_hapus = st
      this.st_hapus2 = st2
      console.log('cek id ',id, ' file ',st2 )
    },
    fnAddMedia(){
      this.yt_id = ""
      this.file = ""
      this.fmAddMedia = true
      //this.konfirmHapus=true
    },
    delData () {
      if(this.st_hapus=='data'){
      const dataku = {
        user_id: this.$store.state.user.user_id,
        id: this.id_hapus
      }
      axios
        .post(api + 'delGMateri', dataku)
        .then(response => {
          //console.log('cek ', response.data)
          if (response.data.cek === 1) {
            this.text = 'Data tersimpan'
            this.snackbar=true
            this.konfirmHapus=false
            this.fnData()
          } else {
            //console.log(' tidak sukses ')
          }
        })
      }else{
        if(this.st_hapus2 == 'baru'){
          const cekIndex = this.lsMedia.findIndex(cek => cek.gm_id === this.id_hapus)
          this.lsMedia.splice(cekIndex, 1)
          this.konfirmHapus=false
        }else{
          const dataku = {
            user_id: this.$store.state.user.user_id,
            id: this.id_hapus
          }
          axios
            .post(api + 'delGMatMedia', dataku)
            .then(response => {
              if (response.data.cek === 1) {
                this.text = 'Data tersimpan'
                this.snackbar=true
                this.konfirmHapus=false
                this.fnData()
              } else {
                //console.log(' tidak sukses ')
              }
            })
        }
      }
    },
    async fnKelasLokal() {
      axios
        .get(api + 'lsKelasLokal&sk_id=' + this.$store.state.user.sk_id+'&jenjang_id='+this.$store.state.user.jenjang_id)
        .then(response => {
          //console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.lsKelasLokal = response.data.data
          }
        })
    },
    saveData () {
      //if (this.kelas_id === 0) {
        //this.snackbar =true
     // } else {
        //console.log('cek mapel ', this.jm_id)
        const dataku = {
          user_id: this.$store.state.user.user_id,
          kelas_id: this.kelas_id,
          semester_id: this.semester_id,
          mapel_id: this.mapel_id,
          guru_id: this.$store.state.user.guru_id,
          bahasan: this.bahasan,
          kode:this.kode,
          id: this.id,
          sk_id:this.$store.state.user.sk_id,
          kelasLokal_id:this.kelasLokal_id,
          media:this.lsMedia,
          st_form:this.st_form
        }
        //console.log('saveGMateri', dataku);
        axios
          .post(api + 'saveGMateri', dataku)
          .then(response => {
            console.log('cek save : ', response.data)
            if (response.data.cek === 1) {
              this.text = 'Data tersimpan'
              this.snackbar=true
              this.dialog = false
              this.fnData()
              //this.clsForm()
              this.lsStatus = true
            } else {
              //console.log(' tidak sukses ')
            }
          })
      //}
    },
    async fnKelas() {
      axios
        .get(api + 'lsKelas&sk_id=' + this.$store.state.user.sk_id+'&jenjang_id='+this.$store.state.user.jenjang_id)
        .then(response => {
          //console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.lsKelas = response.data.data
          }
        })
    },
    async fnGuru() {
      axios
        .get(api + 'lsGuru&merID=2')
        .then(response => {
          //console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.lsGuru = response.data.data
          }
        })
    },
    async fnMapel () {
      axios
        .get(api + 'ddMapel&merID=2')
        .then(response => {
          if (response.data.cek === 1) {
            this.ddMapel = response.data.data
          }
        })
    },
    async fnJMedia () {
      axios
        .get(api + 'lsJMedia&merID=2')
        .then(response => {
          if (response.data.cek === 1) {
            this.lsJMedia = response.data.data
            //console.log('cek lsJMedia ', response.data.data)
          }
        })
    },
    async fnTA () {
      axios
      .get(api + 'lsTA&merID=2')
      .then(response => {
        if (response.data.cek === 1) {
          this.lsTA = response.data.data
        }
      })
    },
    async fnData () {
      axios
        .get(api + 'lsGMateri&user_id=' + this.$store.state.user.user_id)
        .then(response => {
          if (response.data.cek === 1) {
            this.lsData = response.data.data
            console.log('data awal ', response.data)
            this.ftData = response.data.data
          }
        })
    },
    async fnBaru() {
      this.id=0
      this.st_form = 'add'
      this.jm_id='1'
      this.lsStatus=false
      this.lsMedia=[]
      this.clsForm()
      //this.dialog=true
    },
    async fnEdit (id) {
      this.lsMedia=[]
      const cekIndex = this.lsData.findIndex(cek => cek.gm_id === id)
      const dtEdit = this.lsData[cekIndex]
      this.id = id
      this.kelas_id = dtEdit.kelas_id
      this.judul = dtEdit.judul
      this.mapel_id = dtEdit.mapel_id
      this.j_perangkat_id = dtEdit.j_perangkat
      this.semester_id = dtEdit.semester_id
      this.fileGbr = dtEdit.file
      this.bahasan = dtEdit.bahasan
      this.kode = dtEdit.kode
      this.yt_id = dtEdit.file
      this.file = dtEdit.file
      this.jm_id = dtEdit.j_media
      this.kelasLokal_id = dtEdit.lokalx
      this.lsMedia =dtEdit.media
      this.st_form = 'edit'
      this.ftKelasLokal = this.lsKelasLokal.filter(m => m.kelas_id.toUpperCase().includes(this.kelas_id.toUpperCase()))
      this.lsStatus=false
    },
    saveMedia() {
      const dataku = {
        jMedia :this.jm_id,
        file : this.fileGbr,
        yt_id : this.yt_id,
        gm_id2 : Math.floor(Math.random() * 10000),
        st : 'baru'
      }
      if(this.jm_id==='1' && !this.fileGbr){
        console.log('cek')
      }else{
        this.lsMedia.push(dataku)
        this.fmAddMedia = false
      }

    },
    clsForm () {
      this.judul = ''
      this.fileGbr = ''
      this.kelasLokal_id = null
      this.bahasan = ''
      this.kode = ''
      this.yt_id = ''
      this.kelas_id = null
      this.mapel_id = this.ddMapel[0]['id']
      this.j_perangkat_id = this.lsJPerangkat[0]['j_perangkat_id']
      this.semester_id = this.ddSemester[0]['id']
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
      this.searchTextsLoading = false
    }, 1000)
    //console.log('cet ddfa ', this.items, ' cek lg ' ,this.ddSemester)
    this.fnJMedia()
    this.fnKelas()
    this.fnData()
    this.fnMapel()
    this.fnGuru()
    this.fnTA()
    this.fnKelasLokal()
    
  }
}
</script>

<style lang="scss" scoped></style>
