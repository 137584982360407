import { render, staticRenderFns } from "./Lokal_kelas.vue?vue&type=template&id=6056c7ac&scoped=true&"
import script from "./Lokal_kelas.vue?vue&type=script&lang=js&"
export * from "./Lokal_kelas.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6056c7ac",
  null
  
)

export default component.exports