<template>
<div>
  <Navbar />
  <v-container fluid>
    <h3 class="headline font-weight-medium mb-2">Selamat datang di JidiSmart</h3>
      <v-row class="fill-height">
      <v-col
        cols="12"
        sm="12"
        order="2"
        order-sm="2"
        order-md="1"
        order-lg="1"
        md="12"
        lg="12"
        class="pt-0 pl-3"
      >

        <v-carousel cycle height="250" hide-delimiter-background show-arrows-on-hover>
          <v-carousel-item
            v-for="(slide, i) in slides"
            :key="i"
          >
            <v-sheet
              :color="colors[i]"
              height="100%"
            >
              <v-row
                class="fill-height"
                align="center"
                justify="center"
              >
                <div class="text">
                  <img src="https://demo.jidismart.com/image/Apple_Desk_1366_x_768_HDTV.jpg"/>
                </div>
              </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </v-col>

    </v-row>

  <v-row class="fill-height">
    <v-col cols="6" sm="6" order="1" md="3" lg="3" >
      <v-card color="red" dark>
        <v-card-title class="text-h5">
          Jadwal
        </v-card-title>
        <v-card-subtitle>Manajemen jadwal per kelas</v-card-subtitle>
        <v-card-actions>
          <v-btn text href="/g_jadwal" target="_blank">
            Jadwal
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>

      <v-col cols="6" sm="6" order="1" md="3" lg="3" >
      <v-card color="indigo" dark>
        <v-card-title class="text-h5">
          Infomasi
        </v-card-title>
        <v-card-subtitle>Informasi terbaru</v-card-subtitle>
        <v-card-actions>
          <v-btn text href="/g_info" target="_blank">
            Info
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>

      <v-col cols="6" sm="6" order="1" md="3" lg="3" >
      <v-card color="cyan" dark>
        <v-card-title class="text-h5">
          Kelas Online
        </v-card-title>


        <v-card-actions>
          <v-btn text href="/g_kol" target="_blank">
            Info
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
      <v-col cols="6" sm="6" order="1" md="3" lg="3" >
      <v-card color="green" dark>
        <v-card-title class="text-h5">
          Materi Pelajaran
        </v-card-title>

        <v-card-subtitle></v-card-subtitle>

        <v-card-actions>
          <v-btn text href="/g_mapel" target="_blank">
            Info
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>

  <v-card class="transparent" flat>
    <v-slide-group class="pa-4" multiple show-arrows>
      <v-card
        class="content-bg card mx-auto pa-1 transparent"
        flat max-width="250" v-for="item in lsData" v-bind:key="item.e_learn_id">
        <router-link :to="item.url"><v-img :src="item.gbrYT"></v-img></router-link> 
        {{ item.judul }} : {{ item.nm_mapel}}
      </v-card>
    </v-slide-group>
  </v-card>
    </v-container>
</div>
</template>

<script>
//import VideoCard from '@/components/VideoCard'
import Navbar from '../components/Navbar'
import { api } from '@/components/Apiku'
import axios from 'axios'
export default {
  name: 'Home',
  components: {
    Navbar,
    //VideoCard,
  
  },
  data: () => ({
    dtUser:[],
    lsData:[],
    loading: true,
    channel: {
      url: '/channel',
      avatar: 'profile.png'
    },
    //dt_user:this.$store.state.user,
    colors: [
          'indigo',
          'warning',
          'pink darken-2',
          'red lighten-1',
          'deep-purple accent-4',
        ],
        slides: [
          'First',
          'Second',
          'Third',
          'Fourth',
          'Fifth',
        ],
        items: [
        {
          color: '#1F7087',
          src: 'https://cdn.vuetifyjs.com/images/cards/foster.jpg',
          title: 'Supermodel',
          artist: 'Foster the People',
        },
        {
          color: '#952175',
          src: 'https://cdn.vuetifyjs.com/images/cards/halcyon.png',
          title: 'Halcyon Days',
          artist: 'Ellie Goulding',
        },
      ],
  }),
  methods: {
    async fnData () {
      axios
      .get(api + 'lsElearnUsr&merID=2')
      .then(response => {
        //console.log('cek data halooooo ', response.data)
        if (response.data.cek === 1) {
          this.lsData = response.data.data
        }
      })
    },
    fnView(){
       window.location = '/v'
    }
  },
  async mounted() {
    this.dtUser = this.$store.state.user
    this.loading = false
    setTimeout(() => {
      this.loading = false
    }, 2000)
    //console.log('cek loadning ', this.loading)
    //console.log('user ', this.$store.state.user)
    //console.log('menu ', this.$store.state.menuNav)
    this.fnData()
  }
}
</script>
<style lang="scss">

</style>
