<template>
  <div id="history" class="fill-height">
    <Navbar />
    <v-container fluid class="pt-0 pb-0 fill-height">
      <v-row class="fill-height">
        <v-col
          cols="12"
          sm="12"
          order="2"
          order-sm="2"
          order-md="1"
          order-lg="1"
          md="12"
          lg="12"
          class="pt-8 pl-8"
        >
          <h4 class="title font-weight-medium pb-5">Laporan Absensi Guru</h4>
          <template>
            <v-snackbar
              v-model="snackbar"
              :timeout="timeout"
            >
              {{ text }}

              <template v-slot:action="{ attrs }">
                <v-btn
                  color="blue"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  Close
                </v-btn>
              </template>
            </v-snackbar>
        <template>
        </template>

        <v-row align="center" class="list px-0 mx-auto">
          <v-col cols="12">
            <v-radio-group v-model="jenis_absensi">
              <v-radio
                label="Absensi Masuk Sekolah" value="1"
              >
              </v-radio>
              <v-radio
                label="Absensi Kelas / Mata Pelajaran" value="2"
              ></v-radio>
              </v-radio-group>
          </v-col>    
          <v-col  cols="4" sm="3" md="3">
            <input type="date" v-model="tgl1" format="DD/MM/YYYY"  placeholder="Tanggal">
          </v-col>
          <v-col  cols="1" sm="1" md="1">
            Sampai
          </v-col>
          <v-col  cols="4" sm="3" md="3">
            <input type="date" v-model="tgl2" format="DD/MM/YYYY"  placeholder="Tanggal">
          </v-col>

          <v-col  cols="12" sm="2" md="2">
            <v-btn small @click="fnData">Cari..</v-btn>
            <v-btn small @click="fnExcel()">Excel</v-btn>
          </v-col>
          <v-col cols="12" sm="12">
            <v-card class="mx-auto" tile id="print">
              <v-data-table
                :headers="headers"
                :items="ftData"
                disable-pagination
                :hide-default-footer="true"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small class="mr-2" @click="fnEdit(item.prodID)">mdi-pencil </v-icon>
                  <v-icon small @click="deleteTutorial(item.prodID)">mdi-delete</v-icon>
                </template>
                <template v-slot:[`item.lok_klik`]="{ item }">
                  <a v-if="item.lat!='0' && item.lat!==null" :href="'https://maps.google.com/?q='+item.lat+','+item.lon" target="_blank">{{item.lat}}, {{item.lon}}</a>
                  <span v-else>{{item.lok}}</span>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import { api } from '@/components/Apiku'
import axios from 'axios'
import XLSX from 'xlsx';
export default {
  data: () => ({
    snackbar: false,
    tgl1:"",
    tgl2:"",
    jenis_absensi: "1",
    menu1: false,
    menu2: false, kelas_lok_id:null,
    text: 'OK', lsth: null,
    timeout: 2000, bulan_id:1,th:null,
    title:'',
    loading: true, kelas_id:null, kelasLokal_id:null,
    lsData : [], lsKelas:[], ftKelasLokal:[],
    ftData : [], lsKelasLokal:[],
    dialog : false,
    menuKat : false,
    searchTextsLoading: true,
    tutorials: [],
    headers: [
      { text: "Tanggal", align: "start", sortable: false, value: "tglvw",width:"100px" },
      { text: "Nama", align: "start", sortable: false, value: "nama",width:"200px" },
      { text: "Jenis Absen", align: "start", width:50,sortable: false, value: "jenis_absen" },
      { text: "Jadwal Mapel", align: "start", sortable: false, value: "ket_jadwal", width:"200px" },
      { text: "Jam Absen", align: "start", width:50,sortable: false, value: "jam_absen" },
      { text: "Lokasi", align: "start", sortable: false, value: "lok_klik", width:"200px" },
      { text: "Keterangan", align: "start", sortable: false, value: "ket" },
    ],
    lsBulan: [
      { id: 1, nmBulan: "Januari"},
      { id: 2, nmBulan: "Februari"},
      { id: 3, nmBulan: "Maret"},
      { id: 4, nmBulan: "April"},
      { id: 5, nmBulan: "Mei"}, { id: 6, nmBulan: "Juni"},{ id: 7, nmBulan: "Juli"},
      { id: 8, nmBulan: "Agustus"},{ id: 9, nmBulan: "September"},{ id: 10, nmBulan: "Oktober"},
      { id: 11, nmBulan: "NoVember"},{ id: 12, nmBulan: "Desember"},
    ],
  }),
  components:{
    Navbar
  },
  methods: {
    fnExcel() {
      const dataExcel = []
      let judul = [
        'Laporan Data Absensi Guru'
      ]
      dataExcel.push(judul)
      judul = [
        this.$store.state.user.nmSekolah
      ]
      dataExcel.push(judul)

      judul = [
        'Tanggal : '  + this.tgl1
      ]
      dataExcel.push(judul)

      let dataku = [
        'No',
        'Tanggal',
        'Nama Guru',
        'Jenis Absen',
        'Jam Absen',
        'Lokasi',
        'Keterangan'
      ]
      dataExcel.push(dataku)

      let i=1
      this.ftData.forEach((value ) => {
        const dataku = [
          i,value['tglvw'],value['nama'],
          value['jenis_absen'],value['jam_absen'],
          (value['lat']!="0" && value['lat']!==null ? value['lat']+', '+value['lon'] : value['lok']),value['keterangan']
        ]
        dataExcel.push(dataku)
        i++
      })
      var wscols = [
        {wch:10},
        {wch:10},
        {wch:30},
        {wch:15},
        {wch:10},
        {wch:30},
        {wch:30},
        //{wch:15},
        //{wch:20},
        //{wch:20},
        //{wch:20}
      ];
      const data = XLSX.utils.json_to_sheet(dataExcel)
      const wb = XLSX.utils.book_new()
      data["!cols"] = wscols
      const huruf = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']
      for(let i of huruf){
        //const datax ={ text: ""+i+"", value: ""+i+"", sortable: false };
        let cek = i+'1'
        data[cek]=''
        //dataku.push(i);
      }
      XLSX.utils.book_append_sheet(wb, data, 'data')
      XLSX.writeFile(wb,'absensi_guru.xlsx')
    }, 
    async fnData () {
      let functionname = this.jenis_absensi=='1' ? 'lapAbsenGuruLok' : 'lapAbsenGuruLokMapel';
      console.log('functionname', functionname);
      axios
      .get(api + functionname + '&sk_id=' + this.$store.state.user.sk_id  + '&tgl1=' + this.tgl1  + '&tgl2=' + this.tgl2)
      .then(response => {
        console.log('cek tgl ',  this.tgl1)
        console.log('cek data absen ', response.data)
        if (response.data.cek === 1) {
          this.lsData = response.data.data
          this.ftData = response.data.data
        }else{
          this.lsData = []
          this.ftData = []
        }
      })
    },
    clsForm () {
      this.nama = ''
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
      this.searchTextsLoading = false
    }, 2000)

  }
}
</script>

<style lang="scss" scoped></style>
