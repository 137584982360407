<template>
  <div id="history" class="fill-height">
    <Navbar />
    <v-container fluid class="pt-0 pb-0 fill-height">
      <v-row class="fill-height">
        <v-col cols="12" sm="12" order="2" order-sm="2" order-md="1" order-lg="1" md="7" lg="9" class="pt-8 pl-8">
          <h2 class="title font-weight-medium pb-5">Master Data Pelanggan</h2>
          <template>
            <v-snackbar v-model="snackbar" :timeout="timeout">
              {{ text }}
              <template v-slot:action="{ attrs }">
                <v-btn
                  color="blue"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                > Close
                </v-btn>
              </template>
            </v-snackbar>
      <v-dialog v-model="dialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Data Pelanggan</span>
          </v-card-title>
          <v-card-text>
            <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field label="Nama *" v-model="nama" required color="green"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field label="No HP" v-model="hp" required color="green"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field label="alamat" v-model="alamat"
                  required
                  color="green"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="px-12" outlined color="green" @click="dialog = false">Cancel</v-btn>
            <v-btn color="green" @click="saveData">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
            <v-row align="center" class="list px-0 mx-auto">
              <v-col cols="12" md="6">
                <v-text-field prepend-inner-icon="mdi-magnify" v-model="cariNama" label="Search"></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-btn small @click="fnCari">Search</v-btn>
                <v-btn class="px-12" outlined color="green" @click="fnBaru">+ Pelanggan</v-btn>
              </v-col>

              <v-col cols="12" sm="12">
                <v-card class="mx-auto" tile>
                  <v-data-table
                    :headers="headers"
                    :items="ftData"
                    disable-pagination
                    :hide-default-footer="true"
                  >
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-icon small class="mr-2" @click="fnEdit(item.pID)">mdi-pencil </v-icon>
                      <v-icon small @click="fnDel(item.pID)">mdi-delete</v-icon>
                    </template>
                  </v-data-table>

                  <v-card-actions v-if="tutorials.length > 0">
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          order="1"
          order-sm="1"
          order-md="2"
          order-lg="2"
          md="3"
          lg="3"
          :class="[
            'pa-0',
            { 'fill-height': $vuetify.breakpoint.name.smAndDown ? true : false }
          ]"
        >
          <v-card
            id="card-radiobox"
            class="fill-height pa-10"
          >
          <v-row>
            <v-col cols="8">
              <v-text-field
                hide-details
                prepend-inner-icon="mdi-magnify"
                placeholder="Search"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import { api } from '@/components/Apiku'
import axios from 'axios'
export default {
  data: () => ({
    snackbar: false,
    text: 'OK',
    timeout: 2000,
    cariNama:'',
    loading: true,
    lsData : [],
    ftData : [],
    dialog : false,
    menuKat : false,
    searchTextsLoading: true,
    tutorials: [],
    nama: '',
    hp: '',
    alamat: '',
    id: 0,
    files: null,
    headers: [
      { text: "Nama", align: "start", sortable: false, value: "nama" },
      { text: "HP", align: "start", sortable: false, value: "hp" },
      { text: "Alamat", align: "start", sortable: false, value: "alamat" },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),
  components:{
    Navbar
  },
  methods: {
    fnCari () {
      this.ftData =  this.lsData.filter(post => post.nama.toLowerCase().includes(this.cariNama))
      console.log('cek data awal ', this.lsData)
      console.log('cek data ', this.ftData)
    },
    saveData () {
      if (this.nama === '') {
        this.snackbar =true
      } else {
        const dataku = {
          userID: this.$store.state.user.userID,
          nama: this.nama,
          hp: this.hp,
          alamat: this.alamat,
          id: this.id,
          merID: this.$store.state.user.merID
        }
        axios
          .post(api + 'savePelanggan', dataku)
          .then(response => {
            console.log('cek ', response.data)
            if (response.data.cek === 1) {
              this.text = 'Data tersimpan'
              this.snackbar=true
              this.dialog = false
              this.fnData()
              this.clsForm()
              
            } else {
              console.log(' tidak sukses ')
            }
          })
      }
    },
    async fnData () {
      axios
        .get(api + 'getPelanggan&merID=' + this.$store.state.user.merID)
        .then(response => {
          console.log('cek data halooooo ', response.data)
          if (response.data.cek === 1) {
            this.lsData = response.data.data
            this.ftData = response.data.data
          }
        })
    },
    formatPrice (value) {
      const val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    async fnBaru() {
      this.clsForm()
      this.dialog=true
    },
    async fnEdit (id) {
      console.log(' cek show dtEdit.katID ', id)
      const cekIndex = this.lsData.findIndex(cek => cek.pID === id)
      const dtEdit = this.lsData[cekIndex]
      this.id = dtEdit.pID
      this.nama = dtEdit.nama
      this.dialog = true
    },
    async fnDel (id) {
      axios
        .get(api + 'delPelanggan&id=' + id)
        .then(response => {
          console.log('cek data halooooo ', response.data.data)
          if (response.data.cek === 1) {
            this.text = 'Data terhapus'
            this.snackbar=true
            this.fnData()
          }
        })
    },
    clsForm () {
      this.nama = ''
      this.hp = ''
      this.alamat = ''
      this.hpp = 0
      this.id = 0
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
      this.searchTextsLoading = false
    }, 2000)
     // this.fnKat()
    this.fnData()
  }
}
</script>

<style lang="scss" scoped></style>
