<template>
  <div id="history" class="fill-height">
    <Navbar />
    <v-container fluid class="pt-0 pb-0 fill-height">
      <v-row class="fill-height">
        <v-col
          cols="12"
          sm="12"
          order="2"
          order-sm="2"
          order-md="1"
          order-lg="1"
          md="12"
          lg="12"
          class="pt-8 pl-8"
        >
          <h1 class="title font-weight-medium pb-5">Laporan Penjualan</h1>
          <template>
            <v-snackbar
              v-model="snackbar"
              :timeout="timeout"
            >
              {{ text }}

              <template v-slot:action="{ attrs }">
                <v-btn
                  color="blue"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  Close
                </v-btn>
              </template>
            </v-snackbar>

        <template>
          
        </template>
         
        <v-row align="center" class="list px-0 mx-auto">
          <v-menu
            ref="menu1"
            v-model="menu1"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="tg1"
              label="Mulai Tgl"
              persistent-hint
              prepend-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            no-title
            @click:date="TgClick"
            @input="menu1 = false"
          ></v-date-picker>
        </v-menu>

        <v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="tg2"
              label=" s/d Tgl"
              persistent-hint
              prepend-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date2"
            no-title
            @click:date="TgClick2"
            @input="menu2 = false"
          ></v-date-picker>
        </v-menu>

      <v-col cols="12" md="6">
        <div id="print2">
      <h1>Print me</h1>
</div>
        <v-btn small @click="fnData">
          Cari..
        </v-btn>
        <button @click="print">Print this component</button>
      </v-col>

          <v-col cols="12" sm="12">
            <v-card class="mx-auto" tile id="print">
              <v-data-table
                :headers="headers"
                :items="ftData"
                disable-pagination
                :hide-default-footer="true"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small class="mr-2" @click="fnEdit(item.prodID)">mdi-pencil </v-icon>
                  <v-icon small @click="deleteTutorial(item.prodID)">mdi-delete</v-icon>
                </template>
              </v-data-table>

              <v-card-actions v-if="tutorials.length > 0">
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import { api } from '@/components/Apiku'
import axios from 'axios'
export default {
  data: () => ({
    snackbar: false,
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    tg1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    tg2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    menu1: false,
    menu2: false,
    text: 'OK',
    timeout: 2000,
    title:'',
    loading: true,
    lsData : [],
    ftData : [],
    dialog : false,
    menuKat : false,
    searchTextsLoading: true,
    tutorials: [],
    
    headers: [
        { text: "Tanggal", align: "start", sortable: false, value: "ftgl" },
        { text: "No Order", value: "nomor", sortable: false },
        { text: "Total Tagihan", value: "tot_tagihan", sortable: false },
        { text: "Harga", value: "harga", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
  }),
  components:{
    Navbar
  },
  methods: {
    TgClick (date) {
       console.log('cek', date)
       this.tg1 = date
    },
    TgClick2 (date) {
       console.log('cek', date)
       this.tg2 = date
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      console.log(' cek', day, month)
      return `${month}/${day}/${year}`

    },
    parseDate (date) {
      console.log('cek', date)
      if (!date) return null

      const [month, day, year] = date.split('/')
      console.log(' cek', day, month)
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    async fnData () {
      axios
        .get(api + 'lapJual&merID=' + this.$store.state.user.merID + '&t1=' + this.tg1 + '&t2=' + this.tg2 )
        .then(response => {
          console.log(' tgl ', this.tg1)
          console.log('cek data halooooo ', response.data)
          if (response.data.cek === 1) {
            this.lsData = response.data.data
            this.ftData = response.data.data
            this.tutorials = response.data.data
          }
        })
    },
    formatPrice (value) {
      const val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    clsForm () {
      this.nama = ''
    },
    print () {
        var prtContent = document.getElementById("print");
         var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
         WinPrint.document.write(prtContent.innerHTML);
         WinPrint.document.close();
         WinPrint.focus();
         WinPrint.print();
         WinPrint.close();
      // return false
      // window.print()
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
      this.searchTextsLoading = false
    }, 2000)
    this.fnData()
  }
}
</script>

<style lang="scss" scoped></style>
