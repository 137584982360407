<template>
  <div id="history" class="fill-height">
    <Navbar />
    <v-container fluid class="pt-0 pb-0 fill-height">
      <v-row class="fill-height">
        <v-col cols="12" sm="12" class="pt-5 pl-2">
          <h4 class="title">Kelas Online</h4>
          <template>
            <v-snackbar v-model="snackbar" :timeout="timeout">
              {{ text }}
              <template v-slot:action="{ attrs }">
                <v-btn
                  color="blue"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  Close
                </v-btn>
              </template>
            </v-snackbar>
      
      <v-dialog v-model="dialogImpor" max-width="700px">
        <v-card>
          <v-card-title>
            <span>Impor Data</span>
          </v-card-title>
          <v-card-text>
            <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
            <input type="file" @change="onFileChange" />
            <input type="file" class="my_input" @change="importExcel()" id="upload" />Import
              </v-col>
            </v-row>
          </v-container>
          <small>*harus diisi</small>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="cyan" @click="dialogImpor = false">Cancel</v-btn>
            <v-btn color="cyan" @click="saveImpor">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <v-dialog v-model="konfirmHapus" max-width="400px">
      <v-card>
        <v-card-title>
        <span>Konfirmasi</span>
        </v-card-title>
        <v-card-text>
        <v-container>
          <span>Anda yakin menghapus data ini?</span>
        </v-container>
        </v-card-text>

        <v-card-actions>
        <v-spacer></v-spacer>
          <v-btn color="blue darken-1" @click="konfirmHapus = false">Batal</v-btn>
          <v-btn color="blue darken-1" @click="delData">Ya</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
        <v-row align="center" class="px-5 mx-auto mt-20" v-if="lsStatus">
          <v-col cols="12" md="6">
            <v-text-field v-model="title" label="cari..."></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-btn small>
              Search
            </v-btn>
              <v-btn color="cyan" class="mb-0" @click="fnBaru()" small>+ Data</v-btn>
          </v-col>

          <v-col cols="12" sm="12">
            <v-card class="mx-auto" tile>
              <v-data-table
                :headers="headers"
                :items="ftData"
                :disable-pagination="false"
                :hide-default-footer="false"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small class="mr-2" @click="fnEdit(item.gk_id)">mdi-pencil </v-icon>
                  <v-icon small @click="fnKonfirmasi(item.gk_id)">mdi-delete</v-icon>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-else class="pt-5 pl-2">
        <v-spacer></v-spacer>
            <br><br><br>
            <v-col  cols="12" sm="4" md="4">
            <v-select item-text="nm_kelas" item-value="kelas_id" :items="lsKelas" label="Kelas" v-model="kelas_id" required></v-select>
          </v-col>
          <v-col  cols="12" sm="4" md="4">
            <v-select item-text="nm_lokal_kelas" item-value="kelas_lok_id" outlined :items="lsKelasLokal" label="Lokal"
              v-model="kelasLokal_id" required multiple chips></v-select>
          </v-col>
          <v-col  cols="12" sm="4" md="4">
            <v-select item-text="label" item-value="id" :items="ddSemester" label="Semester" v-model="semester_id" required></v-select>
          </v-col>

        <v-col  cols="12" sm="6" md="6">
            <v-select item-text="label" item-value="id" outlined :items="ddMapel" label="Mata Pelajaran"
              v-model="mapel_id" required ></v-select>
          </v-col>
           <v-col cols="12" sm="6" md="6">
            <v-text-field label="Bahasan" v-model="bahasan"></v-text-field>
          </v-col>

 <v-col cols="12" sm="4" md="4">
            Tanggal
            <input type="date" v-model="tgl1" format="DD/MM/YYYY"  placeholder="Tanggal">
          </v-col>
          <v-col cols="12" sm="4" md="4">
            Jam
            <input type="time" v-model="jam" format="hh:mm"  placeholder="Jam">
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-text-field label="Jenis Host" v-model="jhost"></v-text-field>
          </v-col>
           <v-col cols="12" sm="4" md="4">
            <v-text-field label="Host" v-model="host"></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-text-field label="Kode" v-model="kode"></v-text-field>
          </v-col>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="6" md="6">
          <v-btn color="cyan" @click="lsStatus = true">Cancel</v-btn>
          <v-btn color="cyan" @click="saveData">Save</v-btn>
        </v-col>

            
            </v-row>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import { api } from '@/components/Apiku'
import axios from 'axios'
//import XLSX from 'xlsx';
import readXlsxFile from 'read-excel-file';
import "vue-wysiwyg/dist/vueWysiwyg.css";
const { GoogleSpreadsheet } = require('google-spreadsheet');


export default {
  data: () => ({
    snackbar: false,
    lsStatus:true,
    tgl1:new Date(),
    st_menu:'list', kode:'',
    jam:0,jhost:'',host:'',
    ar_mapel:[],
    text: 'OK',
    timeout: 2000,
    title:'',
    loading: true,
    lsData : [],
    ftData : [],lsKelasLokal :[], kelasLokal_id : [],
    dialog : false,
    menuKat : false,
    dialogImpor:false,
    searchTextsLoading: true,
    judul: '',bahasan:'',
    kompetensi: '',
    myHTML:'',
    yt_id: '',
    id: '0',
    lokal:'',
    ddMapel : [],
    dtImpor:[],
    files: null,
    mapel_id:'',
    kelas_id:1,
    guru_id:0, ta_id:0,
    semester_id:0, lsGuru:[],
    lsKelas:[],
    lsTA:[],
    lsJPerangkat:[],
    j_perangkat_id:'',id_hapus:0,konfirmHapus:false,
    image: {
    uploadURL: "/api/myEndpoint",
    gsheet_url : "https://docs.google.com/spreadsheets/d/1exJxOHpFAJ04eEh84dWvCvm6K24v0X_YZdq8mLbjPvQ/edit#gid=0",
    dropzoneOptions: {}
  },

  // limit content height if you wish. If not set, editor size will grow with content.
  maxHeight: "500px",
    ddSemester:[{"id":"1","label":"Semester 1"},{"id":"2","label":"Semester 2"}],
    headers: [
        { text: "No", width:"20", value: "nomor", sortable: false },
        { text: "Bahasan", align: "start",width:"40%", sortable: false, value: "bahasan" },
        { text: "Mata Pelajaran", value: "nm_mapel", sortable: false },
        { text: "Kelas", value: "nm_kelas", sortable: false },
        { text: "Aksi", value: "actions", sortable: false },
      ]
  }),
  components:{
    Navbar
  },
  methods: {
    cek_sheet(){
      const doc = new GoogleSpreadsheet('1exJxOHpFAJ04eEh84dWvCvm6K24v0X_YZdq8mLbjPvQ');
      console.log('cej ', doc)
       doc.useServiceAccountAuth({
  // env var values are copied from service account credentials generated by google
  // see "Authentication" section in docs for more info
  client_email: process.env.GOOGLE_SERVICE_ACCOUNT_EMAIL,
  private_key: process.env.GOOGLE_PRIVATE_KEY,
});
      const sheet = doc.sheetsByIndex[0]; // or use doc.sheetsById[id] or doc.sheetsByTitle[title]
      console.log(sheet.title);
      console.log(sheet.rowCount);
    },
    onFileChange(event) {
      let xlsxfile = event.target.files ? event.target.files[0] : null;
      readXlsxFile(xlsxfile).then((rows) => {
        this.dtImpor=rows
        console.log("rows:", rows)
      })
    },
    pilihGbr () {
      if(this.files){
        console.log('cek ', this.files)  
        const formData = new FormData()
        formData.append("uploadfile", this.files)
        axios
        .post(api + 'uploadPdf', formData)
        .then(response => {
          console.log('cek ', response.data)
          this.fileGbr = response.data.data
          this.fileGbr2 = response.data.fgbr
          if (response.data.cek === 1) {
            console.log(' sukses ')
          } else {
            console.log(' tidak sukses ')
          }
        })
      }
    },
    saveData () {
      //if (this.kelas_id === 0) {
        //this.snackbar =true
     // } else {
        //console.log('cek mapel ', this.j_perangkat_id)
        console.log('cek id : ',this.kelasLokal_id)
        const dataku = {
          user_id: this.$store.state.user.user_id,
          kelas_id: this.kelas_id,
          semester_id: this.semester_id,
          mapel_id: this.mapel_id,
          guru_id: this.$store.state.user.guru_id,
          bahasan: this.bahasan,jhost:this.jhost,host:this.host,kode:this.kode,
          id: this.id,
          tgl1:this.tgl1,
          jam: this.jam,
          file:this.fileGbr,
          sk_id:this.$store.state.user.sk_id,
          kelasLokal_id:this.kelasLokal_id
         // id:0
        }
        axios
          .post(api + 'saveGKOL', dataku)
          .then(response => {
            console.log('cek ', response.data)
            if (response.data.cek === 1) {
              this.text = 'Data tersimpan'
              this.snackbar=true
              
              this.fnData()
              this.clsForm()
              this.lsStatus = true
            } else {
              console.log(' tidak sukses ')
            }
          })
      //}
    },
    fnKonfirmasi(id){
      this.id_hapus=id
      this.konfirmHapus=true
    },
    delData () {
      const dataku = {
        user_id: this.$store.state.user.user_id,
        id: this.id_hapus
      }
      axios
        .post(api + 'delGTugas', dataku)
        .then(response => {
          console.log('cek ', response.data)
          if (response.data.cek === 1) {
            this.text = 'Data tersimpan'
            this.snackbar=true
            this.konfirmHapus=false
            this.fnData()
            //this.clsForm()
            
          } else {
            console.log(' tidak sukses ')
          }
        })
    },
    saveImpor () {
      const cekImpor = Number(this.dtImpor.length);
      console.log('cek im', cekImpor)
      if (cekImpor < 3) {
        this.snackbar =true
      } else {
        console.log('cek mapel ', this.dtImpor)
        const dataku = {
          userID: 1,
          //judul: this.judul,
          //kompetensi: this.kompetensi,
          //katID: this.kat,
          //yt_id: this.yt_id,
          //semester_id: this.semester_id,
          //gbr: this.fileGbr,
          //id:0,
          data:this.dtImpor
        }
        axios
          .post(api + 'imporGuru', dataku)
          .then(response => {
            console.log('cek ', response.data)
            if (response.data.cek === 1) {
              this.text = 'Data tersimpan'
              this.snackbar=true
              this.dialog = false
              this.fnData()
              this.clsForm()
              
            } else {
              console.log(' tidak sukses ')
            }
          })
      }
    },
    async fnKelasLokal() {
      axios
        .get(api + 'lsKelasLokal&sk_id=' + this.$store.state.user.sk_id+'&jenjang_id='+this.$store.state.user.jenjang_id)
        .then(response => {
          console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.lsKelasLokal = response.data.data
          }
        })
    },
    async fnKelas() {
      axios
        .get(api + 'lsKelas&sk_id=' + this.$store.state.user.sk_id+'&jenjang_id='+this.$store.state.user.jenjang_id)
        .then(response => {
          console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.lsKelas = response.data.data
          }
        })
    },
    async fnGuru() {
      axios
        .get(api + 'lsGuru&merID=2')
        .then(response => {
          console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.lsGuru = response.data.data
          }
        })
    },
    async fnMapel () {
      axios
        .get(api + 'ddMapel&merID=2')
        .then(response => {
          if (response.data.cek === 1) {
            this.ddMapel = response.data.data
          }
        })
    },
    async fnJPerangkat () {
      axios
        .get(api + 'lsJ_perangkat&merID=2')
        .then(response => {
          if (response.data.cek === 1) {
            this.lsJPerangkat = response.data.data
            console.log('cek perang ', response.data.data)
          }
        })
    },
    async fnTA () {
      axios
        .get(api + 'lsTA&merID=2')
        .then(response => {
          console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.lsTA = response.data.data
          }
        })
    },
    async fnData () {
      axios
        .get(api + 'lsGKOL&user_id=' + this.$store.state.user.user_id)
        .then(response => {
          console.log('cek data halooooo ', response.data)
          if (response.data.cek === 1) {
            this.lsData = response.data.data
            this.ftData = response.data.data
            //this.tutorials = response.data.data
          }
        })
    },
    async fnBaruKat() {
      this.menuKat = true
    },
    async fnBaru() {
      this.id=0
      this.clsForm()
      //this.dialog=true
    },
    async fnImpor() {
      this.clsForm()
      this.dialogImpor=true
    },
    async fnEdit (id) {
      const cekIndex = this.lsData.findIndex(cek => cek.gk_id === id)
      console.log('cek ', this.lsData, ' id : ',id)
      console.log(' cek data ', cekIndex, 'data : ', this.lsData[cekIndex])
      const dtEdit = this.lsData[cekIndex]
      this.id = id
      this.kelas_id = dtEdit.kelas_id
      this.bahasan = dtEdit.bahasan
      this.jhost = dtEdit.jhost
      this.host = dtEdit.host
      this.kode = dtEdit.kode
      this.mapel_id = dtEdit.mapel_id
      this.semester_id = dtEdit.semester_id
      this.tgl1 = dtEdit.tgl
      this.jam = dtEdit.jam 
      this.kelasLokal_id = dtEdit.lokalx
      //console.log('cek gambar ', dtEdit.file)
      this.lsStatus=false
    },
    g_sheet () {
      console.log('cekk yaaa')
      axios
        .get(this.gsheet_url)
        .then(response => (
            console.log('gsheet ', response.data)
            //parseData(response.data.feed.entry)
        ))
    },
    clsForm () {
     
      this.judul = ''
      this.fileGbr = ''
      this.mapel_id = this.ddMapel[0]['id']
      this.j_perangkat_id = this.lsJPerangkat[0]['j_perangkat_id']
      this.kelas_id = this.lsKelas[0]['kelas_id']
      this.semester_id = this.ddSemester[0]['id']
      console.log('ceh mapel ', this.ddMapel[0])
      this.kode = ''
      this.hpp = 0
      //this.id = 0
      //this.namaKat = ''
      //this.katID = 0
      this.lsStatus=false
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
      this.searchTextsLoading = false
    }, 2000)
     // this.fnKat()
    this.cek_sheet()
    this.g_sheet()
    this.fnKelas()
    this.fnData()
    this.fnMapel()
    this.fnGuru()
    this.fnTA()
    this.fnJPerangkat()
    this.fnKelasLokal()
  }
}
</script>

<style lang="scss" scoped></style>
