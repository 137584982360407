import Vue from 'vue';
import Vuex from 'vuex';
import Axios from 'axios';
//import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex);
const getDefaultState = () => {
  return {
    token: '',
    userx: {},
    count: 2,
    Usr_kasirr:[],
    nmUser:'aaa',
    user:'c'
  };
};
export default new Vuex.Store({
  strict: true,
  state:{
    user: [],
    count: 0,
    userID:0,
    menuNav:[],
    url_file:'http://207.148.67.12:92/'
  },
  getters: {
    isLoggedIn: state => {
      return state.token;
    },
    getUser: state => {
      return state.user;
    },
    getUserx: state => {
        return state.Usr_kasirr;
      },
    getnmUser: state => {
    return state.nmUser;
    },
    getcount: state => {
      return state.count;
    }
  },
  mutations: {
    incrementx (state) {
        state.count++
    },
    increment (state) {
        state.count++
    },
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_userID: (state, user) => {
        state.userID = user;
    }, 
    SET_menuNav: (state, data) => {
      state.menuNav = data;
    },
    SET_USER: (state, user) => {
        state.user = user;
    },
    set_nmUser: (state, user) => {
       state.nmUser = user;
       //console.log('cek error')
    },
    SET_USERX: (state, user) => {
        state.Usr_kasirr = user;
    },
    SET_RESET: (state) => {
      // console.log('cek error', user)
       //state.user = user;
       Object.assign(state, getDefaultState());
   },
    RESET: state => {
      Object.assign(state, getDefaultState());
    }
  },
  actions: {
    login: ({ commit }, { token, user }) => {
      commit('SET_TOKEN', token);
      commit('SET_USER', user);
      Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    },
    logout: ({ commit }) => {
      commit('RESET', '');
    },
    nmUser: ({ commit },{ token, user }) => {
        console.log('', token)
        commit('set_nmUser', user);
    }
  }
});