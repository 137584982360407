<template>
  <div id="history" class="fill-height">
    <Navbar />
    <v-container fluid class="pt-0 pb-0 fill-height">
      <v-row class="fill-height">
        <v-col
          cols="12"
          sm="12"
          order="2"
          order-sm="2"
          order-md="1"
          order-lg="1"
          md="7"
          lg="9"
          class="pt-8 pl-8"
        >
          <h1 class="title font-weight-medium pb-5">{{ historyType }}</h1>
          <template>
            <v-snackbar
              v-model="snackbar"
              :timeout="timeout"
            >
              {{ text }}

              <template v-slot:action="{ attrs }">
                <v-btn
                  color="blue"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  Close
                </v-btn>
              </template>
            </v-snackbar>

        <template>
          
        </template>
      <v-dialog v-model="dialog" max-width="700px">

        <v-card>
          <v-card-title>
            <span>Produk</span>
          </v-card-title>
          <v-card-text>
            <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-file-input
                  label="File input"
                  filled
                  prepend-icon="mdi-camera"
                  v-model="files"
                  @change="pilihGbr"
                ></v-file-input>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  label="Nama Produk*"
                  v-model="nama"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  label="SKU (Kode Produk)"
                  v-model="kode"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-select
                  item-text="nama"
                  item-value="katID"
                  :items="ddKat"
                  label="Kategori*"
                  v-model="kat"
                  required
                ></v-select>
              </v-col>
             
             <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  label="Harga Modal (HPP)"
                  v-model="hpp"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  label="Harga Jual"
                  v-model="harga"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" @click="pilihGbr">Upload</v-btn>
            <v-btn color="blue darken-1" @click="dialog = false">Cancel</v-btn>
            <v-btn color="blue darken-1" @click="saveData">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <v-dialog v-model="menuKat" max-width="500px">
      <v-card>
        <v-card-title>
        <span class="headline">Kategori Produk</span>
        </v-card-title>
        <v-card-text>
        <v-container>
        <v-row>
            <v-col
            cols="12"
            sm="12"
            md="12"
            >
            
            <v-text-field
                label="kategori Produk*"
                v-model="namaKat"
                required
            ></v-text-field>
            </v-col>
           </v-row>  
        </v-container>
        <small>*indicates required field</small>
        </v-card-text>

        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" @click="menuKat = false">Cancel</v-btn>
        <v-btn color="blue darken-1" @click="saveKat">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
             <v-row align="center" class="list px-0 mx-auto">
              <v-col cols="12" md="6">
                <v-text-field v-model="title" label="Search by Title"></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-btn small>
                  Search
                </v-btn>
                 <v-btn color="success" dark class="mb-0"  @click="fnBaru">+ Produk</v-btn>
                 <v-btn color="success" dark class="mb-0" @click="fnBaruKat">+ Kategori</v-btn>
              </v-col>

              <v-col cols="12" sm="12">
                <v-card class="mx-auto" tile>
                  <v-data-table
                    :headers="headers"
                    :items="tutorials"
                    disable-pagination
                    :hide-default-footer="true"
                  >
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-icon small class="mr-2" @click="fnEdit(item.prodID)">mdi-pencil </v-icon>
                      <v-icon small @click="deleteTutorial(item.prodID)">mdi-delete</v-icon>
                    </template>
                  </v-data-table>

                  <v-card-actions v-if="tutorials.length > 0">
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          order="1"
          order-sm="1"
          order-md="2"
          order-lg="2"
          md="3"
          lg="3"
          :class="[
            'pa-0',
            { 'fill-height': $vuetify.breakpoint.name.smAndDown ? true : false }
          ]"
        >
          <v-card
            id="card-radiobox"
            class="fill-height pa-10"
          >
          <v-row>
            <v-col cols="8">
              <v-text-field
                hide-details
                prepend-inner-icon="mdi-magnify"
                placeholder="Search"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
            <v-radio-group v-model="historyType">
              <p class="title font-weight-regular pl-5 mb-2">History Type</p>
              <v-list>
                <v-list-item-group>
                  <template v-for="(item, i) in items">
                    <v-divider :key="i"></v-divider>
                    <v-list-item
                      active-class="grey lighten-4"
                      :key="`item-${i}`"
                      class="py-2"
                      @click="clickItem(item)"
                    >
                      <v-list-item-content>
                        <v-list-item-title v-text="item"></v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-radio :key="item" :value="item"></v-radio>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
              </v-radio-group>
                <v-list>
                <v-list-item-group>
                  <v-list-item
                    v-for="(link, i) in links"
                    :key="i"
                  >
                    <v-list-item-icon>
                      <v-icon v-text="link.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="text-uppercase">{{link.text}} {{ historyType }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import { api } from '@/components/Apiku'
import axios from 'axios'
export default {
  data: () => ({
    snackbar: false,
    text: 'OK',
    timeout: 2000,
    title:'',
    loading: true,
    lsData : [],
    ftData : [],
    dialog : false,
    menuKat : false,
    searchTextsLoading: true,
    tutorials: [],
    nama: '',
    kode: '',
    id: 0,
    hpp:0,
    harga:0,
    stokMin: 0,
    gbr: '',
    fileGbr: '',
    fileGbr2: '',
    namaKat: '',
    katID: 0,
    ddKat : [],
    files: null,
    kat:'',

    headers: [
        { text: "Produk", align: "start", sortable: false, value: "nama" },
        { text: "Kategori", value: "nmKat", sortable: false },
        { text: "HPP", value: "hpp", sortable: false },
        { text: "Harga", value: "harga", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
    items: [
      'Watch History',
      'Community'
    ],
    links: [
        { text: 'Clear all', icon: 'mdi-delete' },
        { text: 'Pause', icon: 'mdi-pause-circle-outline' },
        { text: 'Manage all activity', icon: 'mdi-cog' },
      ],
    historyType: 'Watch History'
  }),
  components:{
    Navbar
  },
  methods: {
    clickItem(item) {
      console.log(item)
      this.historyType = item
    },
    fnTes () {
      console.log('cekkkkkkkkkkkkkkk')
    },
    pilihGbr () {
      if(this.files){
        console.log('cek ', this.files)  
        const formData = new FormData()
        formData.append("uploadfile", this.files)
        axios
          .post(api + 'uploadGbr', formData)
          .then(response => {
            console.log('cek ', response.data)
            this.fileGbr = response.data.data
            this.fileGbr2 = response.data.fgbr
            if (response.data.cek === 1) {
              console.log(' sukses ')
            } else {
              console.log(' tidak sukses ')
            }
          })
        // console.log(' gbr ', file)
      }
    },
    saveData () {
      if (this.nama === '') {
        this.snackbar =true
      } else {
        console.log('cek kat ', this.kat)
        const dataku = {
          userID: 1,
          nama: this.nama,
          kode: this.kode,
          katID: this.kat,
          id: this.id,
          stok: 0,
          harga: this.harga,
          hpp: this.hpp,
          merID: 2,
          gbr: this.fileGbr
        }
        axios
          .post(api + 'saveProduk', dataku)
          .then(response => {
            console.log('cek ', response.data)
            if (response.data.cek === 1) {
              this.text = 'Data tersimpan'
              this.snackbar=true
              this.dialog = false
              this.fnData()
              this.clsForm()
              
            } else {
              console.log(' tidak sukses ')
            }
          })
      }
    },
    saveKat () {
      if (this.namakat === '') {
        this.text = 'Data tidak lengkap'
        this.snackbar=true
      } else {
        console.log('cek kat ', this.katID)
        const dataku = {
          userID: 1,
          namaKat: this.namaKat,
          katID: this.katID,
          merID: 2
        }
        axios
          .post(api + 'saveKat', dataku)
          .then(response => {
            console.log('cek ', response.data)
            if (response.data.cek === 1) {
              this.text = 'Data tersimpan'
              this.snackbar=true
              this.clsForm()
              this.fnData()
            } else {
              console.log(' tidak sukses ')
            }
          })
      }
    },
    async fnKat () {
      axios
        .get(api + 'kat_produk&merID=2')
        .then(response => {
          console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.ddKat = response.data.data
          }
        })
    },
    async fnData () {
      axios
        .get(api + 'produk&merID=2')
        .then(response => {
          console.log('cek data halooooo ', response.data)
          if (response.data.cek === 1) {
            this.lsData = response.data.data
            this.ftData = response.data.data
            this.tutorials = response.data.data
          }
        })
    },
    formatPrice (value) {
      const val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    async fnBaruKat() {
      this.menuKat = true
    },
    async fnBaru() {
      this.clsForm()
      this.dialog=true
    },
    async fnEdit (id) {
      const cekIndex = this.lsData.findIndex(cek => cek.prodID === id)
      console.log(' cek data ', cekIndex, 'data : ', this.lsData[cekIndex])
      const dtEdit = this.lsData[cekIndex]
      this.id = dtEdit.prodID
      this.nama = dtEdit.nama
      this.hpp = dtEdit.hpp
      this.harga = dtEdit.harga
      this.kode = dtEdit.kode
      this.kat = dtEdit.katID
      this.fileGbr = dtEdit.image
      this.katID = dtEdit.katID
      this.fileGbr2 = dtEdit.fgbr
      this.dialog = true
      console.log(' cek show dtEdit.katID ', dtEdit.katID)
      // this.itemsPaginated[cekIndex].qty = Number(this.itemsPaginated[cekIndex].qty) + 1
    },
    clsForm () {
      this.nama = ''
      this.harga = ''
      this.fileGbr = ''
      this.kode = ''
      this.hpp = 0
      this.id = 0
      this.namaKat = ''
      this.katID = 0
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
      this.searchTextsLoading = false
    }, 2000)
     // this.fnKat()
    this.fnData()
    this.fnKat()
  }
}
</script>

<style lang="scss" scoped></style>
