import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VueSession from 'vue-session'
import store from './store';
import wysiwyg from "vue-wysiwyg";
import VueSanitize from "vue-sanitize";

import '@morioh/v-quill-editor/dist/editor.css';
import Editor from '@morioh/v-quill-editor'

//import { api } from '@/components/Apiku'
//import MathType from '@wiris/mathtype-ckeditor4';
//import VueQuillEditor from 'vue-quill-editor'
//Vue.use( CKEditor ); 
//import CKEditor from '@ckeditor/ckeditor5-react';
//import CKEditor from '@ckeditor/ckeditor5-vue';
//import VueAutosuggest from 'vue-autosuggest';
import CKEditor from 'ckeditor4-vue'
import MathType from '@wiris/mathtype-ckeditor4';
//import PlainTextClipboard from 'quill-plain-text-paste'
//Editor.register('modules/clipboard', PlainTextClipboard)
//Vue.use( CKEditor ); 
//Vue.use(VueAutosuggest);
Vue.use(MathType);
Vue.use(VueSanitize);
Vue.use(Editor);

Vue.use( CKEditor );
Vue.use(wysiwyg, { hideModules: { "headings":true,
    "code":false, "link":true,"removeFormat":true },
    forcePlainTextOnPaste: true, 
    
  },
);
/*
image: {
      uploadURL: api + 'uploadSoalGbr2',
      dropzoneOptions: {}
    },
*/
//import store from './store'
//import store from './store'
// import store from './store'


Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  VueSession,
  store,
  render: h => h(App)
}).$mount('#app')
