<template>
  <v-app id="inspire" :style="{backgroundImage:'url(https://admin.jidismart.com/image/Apple_Desk_1366_x_768_HDTV.jpg)'}">
      <v-container class="fill-height" fluid>
        <v-row justify="center">
          <v-col cols="12" sm="12" md="5">
            <v-card class="elevation-12" sm="8" md="8">
              <v-window v-model="step">
                <v-window-item :value="1">
                  <v-row>
                    <v-snackbar
                    v-model="snackbar"
                    :timeout="timeout"
                  >
                    {{ text }}

                    <template v-slot:action="{ attrs }">
                      <v-btn
                        color="blue"
                        text
                        v-bind="attrs"
                        @click="snackbar = false"
                      >
                        Close
                      </v-btn>
                    </template>
                  </v-snackbar>
                    <v-col cols="12">
                      <v-card-text class="mt-12">
                        <h3
                          class="text-center display-3 orange--text text--accent-3"
                        >JidiSmart</h3>
                        <h4 class="text-center mt-4">Wujudkan Jaringan Media Sekolah Pintar</h4>

                        <v-form ref="form" v-model="valid" lazy-validation>
                        <v-select item-text="nama" item-value="ugID" :items="lsUG" label="User Group" v-model="ugID" required></v-select>
                          <v-text-field label="Email" v-model="email" :rules="emailRules"  prepend-icon="email" type="text" color="teal accent-3"/>

                          <v-text-field
                            v-model="pwd" :rules="nameRules"
                            label="Password"
                            name="password"
                            prepend-icon="lock"
                            :type="show1 ? 'text' : 'password'"
                            color="teal accent-3"
                            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                            @click:append="show1 = !show1"
                          />
                        </v-form>
                        <h3 class="text-center mt-4">Lupa password ?</h3>
                      </v-card-text>
                      <div class="text-center mt-3 mb-2">
                        <v-btn rounded color="orange accent-3" dark @click="tbLogin">Login</v-btn>
                      </div>
                    </v-col>
                    
                  </v-row>
                </v-window-item>
                <v-window-item :value="2">
                  <v-row class="fill-height">
                    <v-col cols="12" md="4" class="teal accent-3">
                      <v-card-text class="white--text mt-12">
                        <h1 class="text-center display-1">Selamat datang!</h1>
                        <h5
                          class="text-center"
                        >Silahkan login with your personnel info</h5>
                      </v-card-text>
                      <div class="text-center">
                        <v-btn rounded outlined dark @click="step--">Sign in</v-btn>
                      </div>
                    </v-col>

                    <v-col cols="12" md="8">
                      <v-card-text class="mt-12">
                        <h1 class="text-center display-2 teal--text text--accent-3">Buat Akun Baru</h1>
                        <h4 class="text-center mt-4">Form Pendaftaran</h4>
                        <v-form>
                          <v-text-field
                            label="Nama Merchan / Cafee"
                            v-model="nama_merchan"
                            type="text"
                            color="teal accent-3"
                          />
                          <v-text-field
                            label="Kota"
                            v-model="kota"
                            type="text"
                            color="teal accent-3"
                          />
                          <v-text-field
                            label="Alamat"
                            v-model="alamat"
                            type="text"
                            color="teal accent-3"
                          />
                          <v-text-field
                            label="UserName"
                            v-model="nama_user"
                            prepend-icon="person"
                            type="text"
                            color="teal accent-3"
                          />
                          <v-text-field
                            label="Email"
                            v-model="email_user"
                            prepend-icon="email"
                            type="text"
                            color="teal accent-3"
                          />

                          <v-text-field
                            label="Password"
                            v-model="pwd_user"
                            prepend-icon="lock"
                            type="password"
                            color="teal accent-3"
                          />
                        </v-form>
                      </v-card-text>
                      <div class="text-center mt-n5">
                        <v-btn rounded color="teal accent-3" dark @click="fnDaftar">Daftar</v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-window-item>
                <br>
              </v-window>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
  
  </v-app>
</template>

<script>
import { api } from '@/components/Apiku'
import axios from 'axios'
export default {
  data: () => ({
    step: 1, show1: false,
    valid: false,
    name: '',
    nameRules: [
      v => !!v || 'Wajib diisi',
      v => (v && v.length >= 2) || 'Data salah',
    ],
    emailRules: [
      v => !!v || 'E-mail wajib diisi',
      v => /.+@.+\..+/.test(v) || 'E-mail harus valid',
    ],
    kode:'1234',
    email:'a@a.com',
    pwd:'aa',
    nama_merchan:'',
    kota:'',
    nama_user:'',
    email_user:'',
    pwd_user:'',
    snackbar : false,
    dialog: true,
    count:2,
    ugID:0,
    lsUG:[],
    timeout: 2000,
    text: 'OK',
    alamat:''
  }),
  methods: {
     validate () {
        this.$refs.form.validate()
      },
      tbLogin () {
        this.validate()
      if (this.valid===false || this.ugID ===0) {
        this.text = 'Data tidak lengkap.'
        this.snackbar =true
      } else {
        const dataku = {
          ug_id: this.ugID,
          email: this.email,
          pwd: this.pwd
        }
        console.log('cek ', dataku)
        axios
          .post(api + 'admLogin', dataku)
          .then(response => {
            console.log('cek data ', response.data)
            if (response.data.cek === 1) {
              this.text = 'Berhasil'
              this.snackbar=true
              localStorage.setItem('Usr-jidismart', JSON.stringify(response.data.data))
              localStorage.setItem('nav-jidismart', JSON.stringify(response.data.navMenu))
              this.$store.commit('SET_USER', response.data.data)
              this.$store.commit('SET_menuNav', JSON.stringify(response.data.navMenu))
              window.location = '/'
            } else {
              this.text = 'Salah'
              this.snackbar =true
            }
          })
      }
    },
    fnUG() {
      axios
        .get(api + 'lsUGLogin')
        .then(response => {
          if (response.data.cek === 1) {
            this.lsUG = response.data.data
          }
        })
    },
    fnDaftar () {
      if (this.kode === '' || this.email === '' || this.pwd === '') {
        this.text = ' Data tidak lengkap'
        this.snackbar =true
      } else {
        const dataku = {
          nama_user: this.nama_user,
          email: this.email_user,
          pwd: this.pwd_user,
          alamat:this.alamat,
          nama_merchan:this.nama_merchan,
          kota:this.kota
        }
        axios
          .post(api + 'saveDaftar', dataku)
          .then(response => {
            console.log('cek daftar ', response.data)
            if (response.data.cek === 1) {
              this.text = 'Data tersimpan'
              this.snackbar=true
              localStorage.setItem('Usr-jidismart', JSON.stringify(response.data.data))
              this.$store.commit('SET_USER', 'user')
              window.location = '/'
            } else {
              this.text = ' error'
              this.snackbar =true
            }
          })
      }
    },
  },
  mounted() {
      setTimeout(() => {
        this.loading = false
        this.searchTextsLoading = false
      }, 2000)
      this.fnUG()
    },
  props: {
    source: String
  }
};
</script>