<template>
  <div id="history" class="fill-height">
    <Navbar />
    <v-container fluid class="pt-0 pb-0 fill-height">
      <v-row class="fill-height">
        <v-col cols="12" sm="12">
          <h2 class="title font-weight-medium pb-5">Profil Sekolah</h2>
          <template>
            <v-snackbar v-model="snackbar" :timeout="timeout">
              {{ text }}
              <template v-slot:action="{ attrs }">
                <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
              </template>
            </v-snackbar>
            <v-card>
              <v-card-text>
                <v-container>
                  <v-col class="mx-auto" cols="3" sm="3" md="5" lg="3">
                    <v-list-item-avatar size="150" @click="pilihGbr">
                      <v-img :src="url_logo" v-if="url_logo"></v-img>
                      <div color="blue" class="avatar rounded-circle mr-10">
                        <h2>{{ nama }} </h2>
                      </div>
                    </v-list-item-avatar>
                  </v-col>
                <v-row>

                  <v-col cols="12" sm="6" md="6">
                    <div class="hiideb">
                    <v-file-input hidden
                    label="File input"
                    filled
                    prepend-icon="mdi-camera"
                    v-model="files"
                    @change="pilihGbr"
                    >
                    </v-file-input>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field label="Nama Sekolah*" v-model="nama" required color="green"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field label="NPSN*" v-model="npsn" required color="green"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field label="alamat" v-model="alamat" required color="green"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field label="email" v-model="email" required color="green"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field label="No Telp" v-model="hp" required color="green"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field label="Nama Kepala Sekolah" v-model="nama_kepsek" required color="green"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field label="NIP Kepala Sekolah" v-model="nip_kepsek" required color="green"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
              <small>*wajib diisi!!!</small>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green" @click="saveData" dark>Save</v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import { api } from '@/components/Apiku'
import axios from 'axios'
export default {
  data: () => ({
    snackbar: false,
    text: 'OK', url_logo:'',
    timeout: 2000,
    cariNama:'', npsn:'',
    loading: true,
    lsData : [],
    ftData : [],
    ddKatMR : [],
    katmrID :1,
    dialog : false,
    searchTextsLoading: true,
    tutorials: [],
    nama: '',
    hp: '',
    alamat: '',nama_kepsek: '',nip_kepsek: '',
    email: '',
    id: 0,
    files: null,
    fileGbr: '',
    fileGbr2: '',
    headers: [
      { text: "Nama", align: "start", sortable: false, value: "nama" },
      { text: "Email", align: "start", sortable: false, value: "email" },
      { text: "HP", align: "start", sortable: false, value: "hp" },
      { text: "Group User", align: "start", sortable: false, value: "nmGroup" },
      { text: "Password", align: "start", sortable: false, value: "pwd" },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),
  components:{
    Navbar
  },
  methods: {
    fnCari () {
      this.ftData =  this.lsData.filter(post => post.nama.toLowerCase().includes(this.cariNama))
    },
    pilihGbr () {
      if(this.files){
        const formData = new FormData()
        formData.append("uploadfile", this.files)
        axios
          .post(api + 'uploadSKFoto', formData)
          .then(response => {
            console.log('cek ', response.data)
            this.fileGbr = response.data.data
            this.fileGbr2 = response.data.fgbr
            this.url_logo = response.data.fgbr
            if (response.data.cek === 1) {
              console.log(' sukses ')
            } else {
              alert(response.data.error_msg);
              console.log(' tidak sukses ')
            }
          })
      }
    },
    reLogin(){
      const dataku = {
        user_id: this.$store.state.user.user_id,
      }
      axios
        .post(api + 'reLogin', dataku)
        .then(response => {
          console.log('relogin..................')
          if (response.data.cek === 1) {
            this.text = 'Berhasil'
            this.snackbar=true
            localStorage.setItem('Usr-jidismart', JSON.stringify(response.data.data))
            localStorage.setItem('nav-jidismart', JSON.stringify(response.data.navMenu))
            this.$store.commit('SET_USER', response.data.data)
            this.$store.commit('SET_menuNav', JSON.stringify(response.data.navMenu))
            window.location = '/'
          } else {
            this.text = 'Salah'
            this.snackbar =true
          }
        })
    },
    saveData () {
      if (this.nama === '') {
        this.snackbar =true
      } else {
        const dataku = {
          userID: this.$store.state.user.userID,
          nama: this.nama,nama_kepsek: this.nama_kepsek,
          hp: this.hp,nip_kepsek: this.nip_kepsek,
          email: this.email,
          alamat: this.alamat,
          npsn: this.npsn,
          sk_id: this.$store.state.user.sk_id,
          logo: this.fileGbr
        }
        axios
          .post(api + 'saveProfilSekolah', dataku)
          .then(response => {
            console.log('cek ', response.data)
            if (response.data.cek === 1) {
              this.text = 'Data tersimpan'
              this.snackbar=true
              this.dialog = false
              this.reLogin()
              this.fnData()
              
            } else {
              this.text = 'Error!!!'
              this.snackbar=true
            }
          })
      }
    },
    async fnData () {
      //axios
       // .get(api + 'getMer&merID=' + this.$store.state.user.merID)
       // .then(response => {
          //console.log('cek data halooooo ', response.data)
          //if (response.data.cek === 1) {
            //this.ftData = response.data.data
            let datax = this.$store.state.user
            console.log('cek ', datax)
            this.nama = datax['nmSekolah']
            this.email = datax.emailSK
            this.hp = datax.hpSK
            this.npsn = datax.npsn
            this.alamat = datax['alamatSK']
            this.katmrID =datax.katmrID
            this.fileGbr = datax.fgbr
            this.files = datax.fgbr
            this.url_logo = datax.flogo
            this.nama_kepsek = datax.nama_kepsek
            this.nip_kepsek = datax.nip_kepsek
          //}
        //})
    },
    async fnBaru() {
      this.clsForm()
      this.dialog=true
    },
    async fnEdit (id) {
      //console.log(' cek show dtEdit.katID ', id)
      const cekIndex = this.lsData.findIndex(cek => cek.userID === id)
      const dtEdit = this.lsData[cekIndex]
      this.id = id
      this.nama = dtEdit.nama
      this.email = dtEdit.email
      this.hp = dtEdit.hp
      this.pwd = dtEdit.pwd
      this.ugID = dtEdit.ugID
      this.nama_kepsek = dtEdit.nama_kepsek
      this.nip_kepsek = dtEdit.nip_kepsek
      this.dialog = true
    },
    clsForm () {
      this.nama = ''
      this.hp = ''
      this.alamat = ''
      this.hpp = 0
      this.id = 0
    },
    async fnddKatMR () {
      axios
        .get(api + 'ddKatMR')
        .then(response => {
          //console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.ddKatMR = response.data.data
          }
        })
    },
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
      this.searchTextsLoading = false
    }, 2000)
     // this.fnKat()
    this.fnData()
    this.fnddKatMR()
  }
}
</script>

<style lang="scss" scoped></style>
