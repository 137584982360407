<template>
  <div id="history" class="fill-height">
    <Navbar />
    <v-container fluid class="pt-0 pb-0 fill-height">
      <v-row class="fill-height">
        <v-col cols="12" sm="12" order="2" order-sm="2" order-md="1" order-lg="1"
          class="pt-5 pl-2">
          <h4 class="title">Laporan Perangkat Mengajar</h4>
          <template>
            <v-snackbar
              v-model="snackbar"
              :timeout="timeout"
            >
              {{ text }}
              <template v-slot:action="{ attrs }">
                <v-btn
                  color="blue"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  Close
                </v-btn>
              </template>
            </v-snackbar>
            <v-dialog v-model="konfirmHapus" max-width="400px">
              <v-card>
                <v-card-title>
                <span>Konfirmasi</span>
                </v-card-title>
                <v-card-text>
                <v-container>
                  <span>Anda yakin menghapus data ini?</span>
                </v-container>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" @click="konfirmHapus = false">Batal</v-btn>
                  <v-btn color="blue darken-1" @click="delData">Ya</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-row align="center" class="px-5 mx-auto mt-20" v-if="lsStatus">
            <v-col  cols="12" sm="4" md="4">
              <v-select item-text="label" item-value="id"  :items="ddMapel" label="Mata Pelajaran"
               v-model="mapel_id" required></v-select>
            </v-col>
            <v-col  cols="3" sm="3" md="3">
              <v-select item-text="nm_kelas" item-value="kelas_id" :items="lsKelas" label="Kelas" v-model="kelas_id" 
              required v-on:change="gtKelas()"></v-select>
            </v-col>
            <v-col cols="3" md="3">
                <v-btn cclass="mb-0" @click="fnData()" small>Cari</v-btn>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" md="6">
              <v-text-field v-model="text_cari" label="cari..." v-on:keyup="inputKeydown">></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <!--
              <v-btn color="cyan" @click="fnBaru()" small>+ Data</v-btn>
              <v-btn @click="fnExcel()" small>Excel</v-btn>
              -->
            </v-col>

            <v-col cols="12" sm="12">
              <v-card class="mx-auto" tile>
                <v-data-table
                  :headers="headers"
                  :items="ftData"
                  :disable-pagination="false"
                  :hide-default-footer="false"
                  :loading="loading"
                  loading-text="Loading... Please wait"
                >
                  <template v-slot:[`item.actions`]="{ item }">
                    
                    <v-icon small class="mr-2" @click="fnView(item.file)" v-if="item.file">mdi-eye</v-icon>
                    <!--
                    <v-icon small class="mr-2" @click="fnEdit(item.guru_perangkat_id)">mdi-pencil </v-icon>
                    <v-icon small @click="fnKonfirmasi(item.guru_perangkat_id)">mdi-delete</v-icon>
                    -->
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-else class="pt-5 pl-2">
          <v-spacer></v-spacer>
              <br><br><br>
              <v-col  cols="12" sm="4" md="4">
              <v-select item-text="nm_perangkat" item-value="j_perangkat_id" :items="lsJPerangkat"
                label="Jenis Perangkat" v-model="j_perangkat_id" required></v-select>
            </v-col>
              <v-col  cols="12" sm="4" md="4">
              <v-select item-text="label" item-value="id"  :items="ddMapel" label="Mata Pelajaran"
               v-model="mapel_id" required></v-select>
            </v-col>
            <v-col  cols="12" sm="4" md="4">
              <v-select item-text="nm_kelas" item-value="kelas_id" :items="lsKelas" label="Kelas" v-model="kelas_id" 
              required v-on:change="gtKelas()"></v-select>
            </v-col>
            <v-col  cols="12" sm="4" md="4">
            <v-select item-text="nm_lokal_kelas" item-value="kelas_lok_id" outlined :items="ftKelasLokal" label="Lokal"
              v-model="kelasLokal_id" required multiple chips></v-select>
          </v-col>
            <v-col  cols="12" sm="4" md="4">
              <v-select item-text="label" item-value="id" :items="ddSemester" label="Semester" v-model="semester_id" required></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field label="Judul" v-model="judul"></v-text-field>
            </v-col>
            
            <v-col cols="12" sm="4" md="4">
              <div class="hiideb">
              <v-file-input hidden
              label="File PDF"
              filled
              prepend-icon="mdi-file-pdf-box"
              v-model="files"
              @change="pilihGbr"
              >
              </v-file-input>
              </div>
            </v-col>
          <v-spacer></v-spacer>
          <v-btn color="cyan" @click="lsStatus = true">Cancel</v-btn>
          <v-btn color="cyan" @click="saveData">Save</v-btn>
            
            </v-row>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import { api } from '@/components/Apiku'
import axios from 'axios'
import readXlsxFile from 'read-excel-file';
import "vue-wysiwyg/dist/vueWysiwyg.css";
import XLSX from 'xlsx';

export default {
  data: () => ({
    snackbar: false,
    lsStatus:true, text_cari:'',st_form:'add',
    konfirmHapus:false,
    text: 'OK',
    timeout: 2000,
    title:'',
    loading: true, ftKelasLokal:[],
    lsData : [], lsKelasLokal :[], kelasLokal_id : [],
    ftData : [],
    dialog : false,
    menuKat : false,
    dialogImpor:false,
    searchTextsLoading: true,
    judul: '',
    kompetensi: '',
    myHTML:'',
    yt_id: '',
    id: 0,
    lokal:'',
    ddMapel : [],
    dtImpor:[],
    files: null,
    mapel_id:'',
    kelas_id:1,
    guru_id:0, ta_id:0,
    semester_id:0, lsGuru:[],
    lsKelas:[],
    lsTA:[],
    lsJPerangkat:[],
    j_perangkat_id:'',
    image: {
    uploadURL: "/api/myEndpoint",
    dropzoneOptions: {}
  },

  // limit content height if you wish. If not set, editor size will grow with content.
  maxHeight: "500px",
    ddSemester:[{"id":"1","label":"Semester 1"},{"id":"2","label":"Semester 2"}],
    headers: [
        { text: "Judul", align: "start", sortable: false, value: "judul" },
        { text: "Perangkat", value: "nm_perangkat", sortable: false },
        { text: "Mata Pelajaran", value: "nm_mapel", sortable: false },
        { text: "Kelas", value: "nm_kelas", sortable: false },
        { text: "Semester", value: "nm_semester", sortable: false },
        { text: "Aksi", value: "actions", sortable: false },
      ]
  }),
  components:{
    Navbar
  },
  methods: {
    inputKeydown: function () {
      if(this.text_cari.length>0){
        this.ftData = this.lsData.filter(m => m.judul.toUpperCase().includes(this.text_cari.toUpperCase()))
      }else{
        this.ftData = this.lsData
      }
    },
    fnExcel() {
      const dataExcel = []
      const judul = [
        'Laporan Data Perangkat Ajar'
      ]
      dataExcel.push(judul)
      const dataku = [
        'Judul',
        'Perangkat',
        'Mata Pelajaran',
        'Kelas',
        'Semester'
      ]
      dataExcel.push(dataku)
      this.ftData.forEach((value,index ) => {
        console.log('cek excel ', value['judul'], ' dfad ', index)
        const dataku = [
          value['judul'],
          value['nm_perangkat'],
          value['nm_mapel'],
          value['nm_kelas'],
          value['nm_semester']
        ]
        dataExcel.push(dataku)
      })
      var wscols = [
        {wch:20},
        {wch:15},
        {wch:20},
        {wch:20},
        {wch:20}
      ];
      const data = XLSX.utils.json_to_sheet(dataExcel)
      const wb = XLSX.utils.book_new()
      data["!cols"] = wscols
      data["A1"]=''
      data["B1"]=''
      data["C1"]=''
      data["D1"]=''
      data["E1"]=""
      console.log('cek data ku ' , dataExcel )
      XLSX.utils.book_append_sheet(wb, data, 'data perangkat')
      XLSX.writeFile(wb,'perangkat.xlsx')
    },
    onFileChange(event) {
      let xlsxfile = event.target.files ? event.target.files[0] : null;
      readXlsxFile(xlsxfile).then((rows) => {
        this.dtImpor=rows
        console.log("rows:", rows)
      })
    },
    fnView(id) {
      window.open(this.$store.state.url_file+"pdf/"+ id);
    },
    gtKelas(){
      this.kelasLokal_id=[]
      this.ftKelasLokal = this.lsKelasLokal.filter(m => m.kelas_id.includes(this.kelas_id))
    },
    fnKonfirmasi(id){
      this.id_hapus=id
      this.konfirmHapus=true
    },
    delData () {
      const dataku = {
        user_id: this.$store.state.user.user_id,
        id: this.id_hapus
      }
      axios
        .post(api + 'delGPerangkat', dataku)
        .then(response => {
          console.log('cek ', response.data)
          if (response.data.cek === 1) {
            this.text = 'Data tersimpan'
            this.snackbar=true
            this.konfirmHapus=false
            this.fnData()          
          } else {
            console.log(' tidak sukses ')
          }
        })
    },
    async fnKelas() {
      axios
        .get(api + 'lsKelas&sk_id=' + this.$store.state.user.sk_id+'&jenjang_id='+this.$store.state.user.jenjang_id)
        .then(response => {
          if (response.data.cek === 1) {
            this.lsKelas = response.data.data
          }
        })
    },
    async fnGuru() {
      axios
        .get(api + 'lsGuru&merID=2')
        .then(response => {
          //console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.lsGuru = response.data.data
          }
        })
    },
    async fnMapel () {
      axios
        .get(api + 'ddMapel&merID=2')
        .then(response => {
          if (response.data.cek === 1) {
            this.ddMapel = response.data.data
          }
        })
    },
    async fnJPerangkat () {
      axios
        .get(api + 'lsJ_perangkat&merID=2')
        .then(response => {
          if (response.data.cek === 1) {
            this.lsJPerangkat = response.data.data
            //console.log('cek perang ', response.data.data)
          }
        })
    },
    async fnTA () {
      axios
        .get(api + 'lsTA&merID=2')
        .then(response => {
          if (response.data.cek === 1) {
            this.lsTA = response.data.data
          }
        })
    },
    async fnData () {
      axios
        .get(api + 'lsAGuruPerangkat&mapel_id=' + this.mapel_id + '&kelas_id=' + this.kelas_id+'&sk_id=' + this.$store.state.user.sk_id)
        .then(response => {
          if (response.data.cek === 1) {
            this.lsData = response.data.data
            this.ftData = response.data.data
          }
        })
    },
    async fnBaru() {
      this.id=0
      this.st_form = 'add'
      this.clsForm()
    },
    async fnKelasLokal() {
      axios
        .get(api + 'lsKelasLokal&sk_id=' + this.$store.state.user.sk_id+'&jenjang_id='+this.$store.state.user.jenjang_id)
        .then(response => {
          //console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.lsKelasLokal = response.data.data
          }
        })
    },
    async fnEdit (id) {
      const cekIndex = this.lsData.findIndex(cek => cek.guru_perangkat_id === id)
      const dtEdit = this.lsData[cekIndex]
      this.id = dtEdit.guru_perangkat_id
      this.kelasLokal_id = dtEdit.lokalx
      this.kelas_id = dtEdit.kelas_id
      this.judul = dtEdit.judul
      this.mapel_id = dtEdit.mapel_id
      this.j_perangkat_id = dtEdit.j_perangkat
      this.semester_id = dtEdit.semester_id
      this.fileGbr = dtEdit.file
      this.st_form = 'edit'
      this.lsStatus=false
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
      this.searchTextsLoading = false
    }, 1000)
     // this.fnKat()
    this.fnKelas()
    //this.fnData()
    this.fnMapel()
    this.fnGuru()
    this.fnTA()
    this.fnJPerangkat()
    //this.fnKelasLokal()
  }
}
</script>

<style lang="scss" scoped></style>
