<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  data () {
    const data = JSON.parse(localStorage.getItem('Usr-jidismart'))
    if (data !== null) {
      this.$store.commit('SET_USER', data)
      this.$store.commit('SET_menuNav', JSON.parse(localStorage.getItem('nav-jidismart')))
      this.$store.commit('SET_userID', data['user_id'])
    }else{
      //window.location = '/login'
    }

  }

};
</script>

<style>
  /* width */
  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(175, 175, 175);
    background: inset 0 0 6px     rgba(0,0,0,0.1);
    scrollbar-width: thin;
    border-radius: 10px;

  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    background: inset 0 0 6px     rgba(0,0,0,0.2);
  }
</style>
