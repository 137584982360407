<template>
  <div id="history" class="fill-height">
    <Navbar />
    <v-container fluid class="pt-0 pb-0 fill-height">
      <v-row class="fill-height">
        <v-col cols="12" sm="12" class="pt-5 pl-2">
          <h4 class="title">Jadwal Guru</h4>
          <template>
            <v-snackbar v-model="snackbar" :timeout="timeout">
              {{ text }}
              <template v-slot:action="{ attrs }">
                <v-btn
                  color="blue"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  Close
                </v-btn>
              </template>
            </v-snackbar>
      
      
    <v-dialog v-model="konfirmHapus" max-width="400px">
      <v-card>
        <v-card-title>
        <span>Konfirmasi</span>
        </v-card-title>
        <v-card-text>
        <v-container>
          <span>Anda yakin menghapus data ini?</span>
        </v-container>
        </v-card-text>

        <v-card-actions>
        <v-spacer></v-spacer>
          <v-btn color="blue darken-1" @click="konfirmHapus = false">Batal</v-btn>
          <v-btn color="blue darken-1" @click="delData">Ya</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
        <v-row align="center" class="px-5 mx-auto mt-20" v-if="lsStatus">
          <v-col cols="12" md="6">
            <v-text-field v-model="text_cari" label="cari..." v-on:keyup="inputKeydown">></v-text-field>
          </v-col>
          <v-col cols="12" sm="12">
            <v-card class="mx-auto" tile>
              <v-data-table
                :headers="headers"
                :items="ftData"
                :disable-pagination="false"
                :hide-default-footer="false"
                :loading="loading"
                loading-text="Loading... Please wait"
              >
              <!--
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small class="mr-2" @click="fnEdit(item.gt_id)">mdi-pencil </v-icon>
                  <v-icon small @click="fnKonfirmasi(item.gt_id)">mdi-delete</v-icon>
                </template>
                -->
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-else class="pt-5 pl-2">
        <v-spacer></v-spacer>
            <br><br><br>
          <v-col cols="12" sm="6" md="6">
            <v-text-field label="Judul" v-model="judul"></v-text-field>
          </v-col>
         <v-col  cols="12" sm="4" md="4">
            <v-select item-text="nm_kelas" item-value="kelas_id" :items="lsKelas" label="Kelas" v-model="kelas_id" required></v-select>
          </v-col>
           <v-col cols="12" sm="6" md="6">
            <v-textarea label="Isi Info" v-model="isi"></v-textarea>
          </v-col>

        <v-spacer></v-spacer>
        <v-btn color="cyan" @click="lsStatus = true">Cancel</v-btn>
        <v-btn color="cyan" @click="saveData">Save</v-btn>
            
            </v-row>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import { api } from '@/components/Apiku'
import axios from 'axios'
//import XLSX from 'xlsx';
//import readXlsxFile from 'read-excel-file';
import "vue-wysiwyg/dist/vueWysiwyg.css";

export default {
  data: () => ({
    snackbar: false,
    lsStatus:true,  text_cari:'',
    ar_mapel:[],
    text: 'OK',
    timeout: 2000,
    title:'',
    loading: true,
    lsData : [],
    ftData : [],lsKelasLokal :[], kelasLokal_id : [],
    dialog : false,
    menuKat : false,
    dialogImpor:false,
    searchTextsLoading: true,
    judul: '',
    isi: '',
    yt_id: '',
    id: '0',
    lokal:'',
    ddMapel : [],
    dtImpor:[],
    files: null,
    mapel_id:'',
    kelas_id:1,
    guru_id:0, ta_id:0,
    semester_id:0, lsGuru:[],
    lsKelas:[],
    lsTA:[],
    lsJPerangkat:[],
    j_perangkat_id:'',id_hapus:0,konfirmHapus:false,
    image: {
    uploadURL: "/api/myEndpoint",
    dropzoneOptions: {}
  },

  maxHeight: "500px",
  ddSemester:[{"id":"1","label":"Semester 1"},{"id":"2","label":"Semester 2"}],
  headers: [
    { text: "No", width:"20", value: "nomor", sortable: false },
    { text: "Hari", align: "start", sortable: false, value: "nm_hari" },
    { text: "Jam", align: "start", sortable: false, value: "ket_jam" },
    { text: "Kelas", value: "lokal_kelas", sortable: false },
    { text: "Mapel", align: "start", sortable: false, value: "nm_mapel" },
  ]
  }),
  components:{
    Navbar
  },
  methods: {
    inputKeydown: function () {
      if(this.text_cari.length>0){
        this.ftData = this.lsData.filter(m => m.nm_hari.toUpperCase().includes(this.text_cari.toUpperCase()))
      }else{
        this.ftData = this.lsData
      }
    },
    saveData () {
      //if (this.kelas_id === 0) {
        //this.snackbar =true
     // } else {
        //console.log('cek mapel ', this.j_perangkat_id)
        //console.log('cek id : ',this.id)
        const dataku = {
          user_id: this.$store.state.user.user_id,
          kelas_id: this.kelas_id,
          semester_id: this.semester_id,
          mapel_id: this.mapel_id,
          guru_id: this.guru_id,
          judul: this.judul,
          id: this.id,
          sk_id:this.$store.state.user.sk_id,
          isi:this.isi,
         // id:0
        }
        axios
          .post(api + 'saveGInfo', dataku)
          .then(response => {
            console.log('cek ', response.data)
            if (response.data.cek === 1) {
              this.text = 'Data tersimpan'
              this.snackbar=true
              
              this.fnData()
              this.clsForm()
              this.lsStatus = true
            } else {
              //console.log(' tidak sukses ')
            }
          })
      //}
    },
    fnKonfirmasi(id){
      this.id_hapus=id
      this.konfirmHapus=true
    },
    delData () {
      const dataku = {
        user_id: this.$store.state.user.user_id,
        id: this.id_hapus
      }
      axios
        .post(api + 'delGPustakas', dataku)
        .then(response => {
          console.log('cek ', response.data)
          if (response.data.cek === 1) {
            this.text = 'Data tersimpan'
            this.snackbar=true
            this.konfirmHapus=false
            this.fnData()
            //this.clsForm()
            
          } else {
            //console.log(' tidak sukses ')
          }
        })
    },
    async fnData () {
      axios
        .get(api + 'lsGJadwal&guru_id=' + this.$store.state.user.guru_id)
        .then(response => {
          if (response.data.cek === 1) {
            this.lsData = response.data.data
            this.ftData = response.data.data
          }
        })
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
      this.searchTextsLoading = false
    }, 2000)
    this.fnData()
  }
}
</script>

<style lang="scss" scoped></style>
