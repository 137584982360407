<template>
  <div id="history" class="fill-height">
    <Navbar />
    <v-container fluid class="pt-0 pb-0 fill-height">
      <v-row class="fill-height">
        <v-col cols="12" sm="12" class="pt-5 pl-2">
          <h4 class="title">Tugas</h4>
          <template>
            <v-snackbar v-model="snackbar" :timeout="timeout">
              {{ text }}
              <template v-slot:action="{ attrs }">
                <v-btn
                  color="blue"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  Close
                </v-btn>
              </template>
            </v-snackbar>

        <v-dialog v-model="dialogNilai" max-width="300px">
          <v-card>
            <v-card-title>
              <span>Nilai Tugas</span>
            </v-card-title>
            <v-card-text>
              <v-container>
              <v-row>
                <v-col  cols="12" sm="12" md="12">
                  <v-text-field label="Nilai" v-model="nilai"></v-text-field>
                </v-col>
                
              </v-row>
            </v-container>
            <small>*harus diisi</small>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="cyan" @click="dialogNilai = false">Cancel</v-btn>
              <v-btn color="cyan" @click="saveNilai">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

          <v-dialog v-model="konfirmHapus" max-width="400px">
            <v-card>
              <v-card-title>
              <span>Konfirmasi</span>
              </v-card-title>
              <v-card-text>
              <v-container>
                <span>Anda yakin menghapus data ini?</span>
              </v-container>
              </v-card-text>
              <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn color="blue darken-1" @click="konfirmHapus = false">Batal</v-btn>
                <v-btn color="blue darken-1" @click="delData">Ya</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogUlang" max-width="500px">
            <v-card>
              <v-card-title>
                <span>Ulang Ujian</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                <v-row>
                  <v-col  cols="12" sm="12" md="12">
                  Siswa akan mengulang kirim ujian?
                  </v-col>
                </v-row>
              </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="cyan" @click="dialogUlang = false">Batal</v-btn>
                <v-btn color="cyan" @click="saveUlang()">Ya</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-row h-align="center" class="px-5 mx-auto mt-20" v-if="st_menu=='list'">
            <v-col cols="12" md="6">
              <v-text-field v-model="text_cari" label="cari..." v-on:keyup="inputKeydown">></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-btn color="cyan" class="mb-0" @click="fnBaru()" small>+ Data</v-btn>
            </v-col>

            <v-col cols="12" sm="12">
              <v-card class="mx-auto" tile>
                <v-data-table
                  :headers="headers"
                  :items="ftData"
                  :disable-pagination="false"
                  :hide-default-footer="false"
                  :loading="loading"
                  loading-text="Loading... Please wait"
                >
                  <template v-slot:[`item.cek_lokal`]="{ item }">
                    <v-icon class="mr-2" @click="fnHasil(item.gt_id)">mdi-checkbox-multiple-marked-outline</v-icon>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="fnEdit(item.gt_id)">mdi-pencil </v-icon>
                    <v-icon small class="mr-2" @click="fnView(item.file)" v-if="item.file">mdi-eye</v-icon>
                    <v-icon small @click="fnKonfirmasi(item.gt_id)">mdi-delete</v-icon>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>

          <v-row class="pt-5 pl-2" v-if="st_menu=='add'">
              <v-spacer></v-spacer>
                  <br><br><br>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field label="Judul" v-model="judul"></v-text-field>
                </v-col>
              <v-col  cols="12" sm="6" md="6">
                  <v-select item-text="label" item-value="id" outlined :items="ddMapel" label="Mata Pelajaran"
                    v-model="mapel_id" required ></v-select>
                </v-col>
                <v-col  cols="12" sm="4" md="4">
                  <v-select item-text="nm_kelas" item-value="kelas_id" :items="lsKelas" label="Kelas" 
                  v-model="kelas_id" required v-on:change="gtKelas()"></v-select>
                </v-col>
                <v-col  cols="12" sm="4" md="4">
                  <v-select item-text="nm_lokal_kelas" item-value="kelas_lok_id" outlined :items="ftKelasLokal" label="Lokal"
                    v-model="kelasLokal_id" required multiple chips></v-select>
                </v-col>
                <v-col  cols="12" sm="4" md="4">
                  <v-select item-text="label" item-value="id" :items="ddSemester" label="Semester" v-model="semester_id" required></v-select>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-file-input hidden label="File PDF" filled prepend-icon="mdi-file-pdf-box"
                  v-model="files" @change="pilihGbr()">
                  </v-file-input> {{fileGbr}}
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  <v-img v-if="fileGbr" src="@/assets/pdf.png"
                    style="max-width: 100%;width: 100px; object-fit: cover"
                  ></v-img>
                  <v-img v-else src="@/assets/gbr.png" style="max-width: 100%;width: 100px; object-fit: cover"></v-img>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field label="Video Youtbe" v-model="video"></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  Tanggal Akhir Kirim tugas
                  <input type="date" v-model="tgl1" format="DD/MM/YYYY"  placeholder="Tanggal">
                </v-col>
                <v-col cols="12" sm="4" md="4">
                  Jam
                  <input type="time" v-model="jam" format="hh:mm"  placeholder="Jam">
                </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" sm="6" md="6">
                <v-btn color="cyan" @click="st_menu = 'list'">Cancel</v-btn>
                <v-btn color="cyan" @click="saveData">Save</v-btn>
              </v-col>
            </v-row>

            <v-row class="px- mx-auto mt-20" v-if="st_menu=='hasil'">
            <v-col cols="12" md="12">
              <v-icon class="mr-2" @click="st_menu='list'">mdi-keyboard-backspace</v-icon>
             Cek Tugas Siswa
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field v-model="text_cari_sis" label="cari..." v-on:keyup="KeydownSis">></v-text-field>
            </v-col>
            <v-col  cols="6" sm="3" md="3">
              <v-select item-text="nm_lokal_kelas" item-value="kelas_lok_id" :items="lokal_kelas_ujian" label="Lokal"
                v-model="kelas_ujian_id" required v-on:change="gtKelasUjian()"> </v-select>
            </v-col>
            <v-col cols="3" md="3">
              <v-btn @click="fnExcel()" small>Excel</v-btn>
            </v-col>
            <v-col cols="12" md="6" v-if="selectedHasil.length>0">
              <v-btn color="green" class="mb-0" @click="dialogUlang = true" small>
                  <v-icon>mdi-autorenew</v-icon>Kirim Ulang Tugas
                </v-btn>
            </v-col>

            <v-col cols="12" sm="12">
              <v-card class="mx-auto" tile>
                <!-- item-key="sis_ujian_id"-->
                <v-data-table
                  :headers="hdUjian"
                  :items="ftTugasSiswa"
                  :disable-pagination="false"
                  :hide-default-footer="false"
                  :single-select="false"
                  item-key="siswa_id"
                  show-select
                  v-model="selectedHasil"
                  :loading="loadingHasil"
                  loading-text="Loading... Please wait"
                  :footer-props="{
                    'items-per-page-options': [30, 40, 50]
                  }"
                  :items-per-page="30"
                >
                  <template v-slot:[`item.nilai`]="{ item }">
                    <div v-if="item.sis_tugas">
                      {{ item.nilai}}
                      <v-icon class="mr-2" @click="fnNilai(item.sis_tugas,item.nilai)" v-if="item.nm_file">mdi-pencil</v-icon>
                    </div>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <div v-if="item.sis_tugas">
                      <v-icon class="mr-2" @click="fnView(item.nm_file)" v-if="item.nm_file">mdi-eye</v-icon>
                    </div>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import { api } from '@/components/Apiku'
import axios from 'axios'
import XLSX from 'xlsx';
import readXlsxFile from 'read-excel-file';
import "vue-wysiwyg/dist/vueWysiwyg.css";

export default {
  data: () => ({
    snackbar: false,st_menu:'list',
    lsStatus:true, tgl1:new Date(),jam:0,
    ar_mapel:[], text_cari:'', video:'', dialogNilai:false,
    text: 'OK',lokal_kelas_ujian:[],kelas_lok_id:null,
    timeout: 2000,text_cari_sis:'',loadingHasil:false,
    title:'',nilai:'',
    loading: true, fileGbr:'', dialogUlang:false,
    lsData : [], ftKelasLokal:[],
    ftData : [],lsKelasLokal :[], kelasLokal_id : [],
    dialog : false,selectedHasil :[],
    menuKat : false, kelas_ujian_id:null,
    dialogImpor:false,sis_tugas:null,
    searchTextsLoading: true,
    judul: '',
    kompetensi: '',
    myHTML:'',
    yt_id: '',
    id: null,
    lokal:'',
    ddMapel : [],
    dtImpor:[],
    files: null, lsTugasSiswa:[],ftTugasSiswa:[],
    mapel_id:'',
    kelas_id:1,
    guru_id:0, ta_id:0,
    semester_id:0, lsGuru:[],
    lsKelas:[],
    lsTA:[],
    lsJPerangkat:[],
    j_perangkat_id:'',id_hapus:0,konfirmHapus:false,
    image: {
    uploadURL: "/api/myEndpoint",
    dropzoneOptions: {}
  },

  // limit content height if you wish. If not set, editor size will grow with content.
  maxHeight: "500px",
  ddSemester:[{"id":"1","label":"Semester 1"},{"id":"2","label":"Semester 2"}],
  headers: [
    { text: "No", width:"20", value: "nomor", sortable: false },
    { text: "Judul", align: "start",width:"40%", sortable: false, value: "judul" },
    { text: "Mata Pelajaran", value: "nm_mapel", sortable: false },
    { text: "Kelas", value: "nm_kelas", sortable: false },
    { text: "Hasil", value: "cek_lokal", sortable: false },
    { text: "Aksi", value: "actions", sortable: false },
  ],
  hdUjian: [
    { text: "Nama Siswa", value: "nama", sortable: false },
    { text: "Nilai", value: "nilai", sortable: false },
    { text: "Koreksi", value: "actions", sortable: false },
  ],
  }),
  components:{
    Navbar
  },
  methods: {
    inputKeydown: function () {
      if(this.text_cari.length>0){
        this.ftData = this.lsData.filter(m => m.judul.toUpperCase().includes(this.text_cari.toUpperCase()))
      }else{
        this.ftData = this.lsData
      }
    },
    fnNilai(id,nilai){
      this.nilai = nilai
      this.dialogNilai = true
      this.sis_tugas = id
      //console.log('cek ', id)
    },
    saveUlang () {
      const dataku = {
        user_id: this.$store.state.user.user_id,
        data: this.selectedHasil
      }
      axios
        .post(api + 'saveUlangTugas', dataku)
        .then(response => {
          console.log('cek data ', response.data.data)
          if (response.data.cek === 1) {
            this.text = 'Data tersimpan'
            this.snackbar=true
            this.dialogUlang = false
            this.gtKelasUjian()
            //this.fnData()          
          } else {
            console.log(' tidak sukses ')
          }
        })
    },
    async fnHasil(id) {
      this.lsTugasSiswa = []
      this.ftTugasSiswa = []
      console.log('cek data ' , this.lsData) 
      const cekIndex = this.lsData.findIndex(cek => cek.gt_id === id)
      const dtEdit = this.lsData[cekIndex]
      console.log('cek id ', dtEdit)
      this.id = id
      /*
      this.kelas_id = dtEdit.kelas_id
      this.nm_ujian = dtEdit.nm_ujian
      this.kode = dtEdit.kode
      this.jml = dtEdit.jml
      this.menit = dtEdit.menit
      this.tgl1 = dtEdit.tgl_ujian
      this.jam = dtEdit.jam_ujian
      this.is_rand = dtEdit.is_rand
      this.mapel_id = dtEdit.mapel_id
      this.ju_id = dtEdit.ju_id
      this.semester_id = dtEdit.semester_id
      this.fileGbr = dtEdit.file
      this.kelasLokal_id = dtEdit.lokalx
      this.kelas_ujian_id = null
*/
      let datax = this.lsKelasLokal.filter(m => m.kelas_id.includes(dtEdit.kelas_id))
      
      this.lokal_kelas_ujian=[]
      datax.forEach((value ) => {
        let lokalx = dtEdit.lokalx
        lokalx.forEach((val ) => {
          let datab = value.kelas_lok_id
          if(datab === val){
            this.lokal_kelas_ujian.push(value)
          }
        })
      })

      
      this.st_menu = 'hasil'
      console.log('cek mn ', this.st_menu)
    },
    KeydownSis: function () {
      if(this.text_cari_sis.length>0){
        this.ftUjianSiswa = this.lsUjianSiswa.filter(m => m.nama.toUpperCase().includes(this.text_cari_sis.toUpperCase()))
      }else{
        this.ftUjianSiswa = this.lsUjianSiswa
      }
    },
    fnExcel() {
      const kelasku=this.lsKelasLokal.filter(m => m.kelas_lok_id.includes(this.kelas_ujian_id))
      const dataExcel = []
      const judul = [
        'Laporan Data Tugas ' + ' Kelas : ' + kelasku[0]['nm_lokal_kelas']
      ]
      dataExcel.push(judul)
      const dataku = [
        'No',
        'Nama Siswa',
        'Benar',
        'Salah',
        'Nilai'
      ]
      dataExcel.push(dataku)
      let i=1
      this.ftTugasSiswa.forEach((value ) => {
        const dataku = [
          i,value['nama'],
          value['benar'],
          value['salah'],
          value['nilai'],
          //value['nm_semester']
        ]
        dataExcel.push(dataku)
        i++
      })
      var wscols = [
        {wch:10},
        {wch:30},
        {wch:15},
        {wch:20},
        {wch:20},
        {wch:20}
      ];
      const data = XLSX.utils.json_to_sheet(dataExcel)
      const wb = XLSX.utils.book_new()
      data["!cols"] = wscols
      data["A1"]=''
      data["B1"]=''
      data["C1"]=''
      data["D1"]=''
      data["E1"]=""
      //console.log('cek data ku ' , dataExcel )
      XLSX.utils.book_append_sheet(wb, data, 'data Tugas')
      XLSX.writeFile(wb,'hasil_tugas.xlsx')
    }, 
    gtKelasUjian(){
      this.loadingHasil = true
      this.lsTugasSiswa = []
      this.ftTugasSiswa = []
      axios
      .get(api + 'hasilTugas&kelas_lok_id=' + this.kelas_ujian_id + '&gt_id=' + this.id)
      .then(response => {
        console.log(api +'hasilTugas&kelas_lok_id=' + this.kelas_ujian_id + '&gt_id=' + this.id)
        if (response.data.cek === 1) {
          this.lsTugasSiswa = response.data.data
          this.ftTugasSiswa = response.data.data
        }else{
          this.lsTugasSiswa = []
          this.ftTugasSiswa = []
        }
        this.loadingHasil = false
      })
    },
    gtKelas(){
      this.kelasLokal_id=[]
      this.ftKelasLokal = this.lsKelasLokal.filter(m => m.kelas_id.toUpperCase().includes(this.kelas_id.toUpperCase()))
    },
    onFileChange(event) {
      let xlsxfile = event.target.files ? event.target.files[0] : null;
      readXlsxFile(xlsxfile).then((rows) => {
        this.dtImpor=rows
        console.log("rows:", rows)
      })
    },
    pilihGbr() {
      if(this.files['type'] === 'application/pdf'){
        const formData = new FormData()
        formData.append("uploadfile", this.files)
        axios
        .post(api + 'uploadPdf', formData)
        .then(response => {
          //console.log('cek ', response.data)
          this.fileGbr = response.data.data
          this.fileGbr2 = response.data.fgbr
          if (response.data.cek === 1) {
            console.log(' sukses ')
          } else {
            console.log(' tidak sukses ')
          }
        })
      }else{
        this.text = 'Hanya file pdf'
        this.snackbar=true
      }
    },
    saveNilai(){
      const dataku = {
        user_id: this.$store.state.user.user_id,
        sis_tugas: this.sis_tugas,
        nilai: this.nilai
      }
      axios
        .post(api + 'saveNilaiTugas', dataku)
        .then(response => {
          console.log('cek ', response.data)
          if (response.data.cek === 1) {
            this.text = 'Data tersimpan'
            this.snackbar=true
            this.gtKelasUjian()
            this.dialogNilai = false
            //this.fnData()
            //this.clsForm()
            //this.lsStatus = true
          } else {
            console.log(' tidak sukses ')
          }
        })
    },
    saveData () {
      //if (this.kelas_id === 0) {
        //this.snackbar =true
     // } else {
        //console.log('cek mapel ', this.j_perangkat_id)
        //console.log('cek id : ',this.id)
        const dataku = {
          user_id: this.$store.state.user.user_id,
          kelas_id: this.kelas_id,
          semester_id: this.semester_id,
          mapel_id: this.mapel_id,
          guru_id: this.$store.state.user.guru_id,
          judul: this.judul,
          id: this.id,
          file:this.fileGbr,
          sk_id:this.$store.state.user.sk_id,
          kelasLokal_id:this.kelasLokal_id,
          tgl1:this.tgl1, video:this.video,
          jam: this.jam
        }
        axios
          .post(api + 'saveGTugas', dataku)
          .then(response => {
            console.log('cek ', response.data)
            if (response.data.cek === 1) {
              this.text = 'Data tersimpan'
              this.snackbar=true
              this.fnData()
              this.clsForm()
              this.lsStatus = true
            } else {
              console.log(' tidak sukses ')
            }
          })
      //}
    },
    fnKonfirmasi(id){
      this.id_hapus=id
      this.konfirmHapus=true
    },
    delData () {
      const dataku = {
        user_id: this.$store.state.user.user_id,
        id: this.id_hapus
      }
      axios
        .post(api + 'delGTugas', dataku)
        .then(response => {
          console.log('cek ', response.data)
          if (response.data.cek === 1) {
            this.text = 'Data tersimpan'
            this.snackbar=true
            this.konfirmHapus=false
            this.fnData()
            //this.clsForm()
            
          } else {
            console.log(' tidak sukses ')
          }
        })
    },
    async fnKelasLokal() {
      axios
        .get(api + 'lsKelasLokal&sk_id=' + this.$store.state.user.sk_id+'&jenjang_id='+this.$store.state.user.jenjang_id)
        .then(response => {
          //console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.lsKelasLokal = response.data.data
          }
      })
    },
    fnView(id) {
      window.open(this.$store.state.url_file+"pdf/"+ id);
    },
    async fnKelas() {
      axios
        .get(api + 'lsKelas&sk_id=' + this.$store.state.user.sk_id+'&jenjang_id='+this.$store.state.user.jenjang_id)
        .then(response => {
          //console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.lsKelas = response.data.data
          }
        })
    },
    async fnGuru() {
      axios
        .get(api + 'lsGuru&merID=2')
        .then(response => {
          //console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.lsGuru = response.data.data
          }
        })
    },
    async fnMapel () {
      axios
        .get(api + 'ddMapel&merID=2')
        .then(response => {
          if (response.data.cek === 1) {
            this.ddMapel = response.data.data
          }
        })
    },
    async fnJPerangkat () {
      axios
        .get(api + 'lsJ_perangkat&merID=2')
        .then(response => {
          if (response.data.cek === 1) {
            this.lsJPerangkat = response.data.data
            //console.log('cek perang ', response.data.data)
          }
        })
    },
    async fnTA () {
      axios
        .get(api + 'lsTA&merID=2')
        .then(response => {
          //console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.lsTA = response.data.data
          }
        })
    },
    async fnData () {
      axios
        .get(api + 'lsGTugas&user_id=' + this.$store.state.user.user_id)
        .then(response => {
          //console.log('cek data halooooo ', response.data)
          if (response.data.cek === 1) {
            this.lsData = response.data.data
            this.ftData = response.data.data
          }
        })
    },
    async fnBaruKat() {
      this.menuKat = true
    },
    async fnBaru() {
      this.id=0
      this.clsForm()
      this.gtKelas()
    },
    async fnImpor() {
      this.clsForm()
      this.dialogImpor=true
    },
    async fnEdit (id) {
      const cekIndex = this.lsData.findIndex(cek => cek.gt_id === id)
      const dtEdit = this.lsData[cekIndex]
      this.id = id
      this.kelas_id = dtEdit.kelas_id
      this.judul = dtEdit.judul
      this.mapel_id = dtEdit.mapel_id
      this.semester_id = dtEdit.semester_id
      this.kelasLokal_id = dtEdit.lokalx
      this.fileGbr = dtEdit.file
      this.tgl1 = dtEdit.tgl
      this.jam = dtEdit.jam 
      this.video = dtEdit.video 
      this.ftKelasLokal = this.lsKelasLokal.filter(m => m.kelas_id.toUpperCase().includes(this.kelas_id.toUpperCase()))
      this.st_menu='add'
    },
    clsForm () {
      this.judul = ''
      this.fileGbr = ''
      this.mapel_id = this.ddMapel[0]['id']
      this.j_perangkat_id = this.lsJPerangkat[0]['j_perangkat_id']
      this.semester_id = this.ddSemester[0]['id']
      this.st_menu='add'
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
      this.searchTextsLoading = false
    }, 1000)
    this.fnKelas()
    this.fnData()
    this.fnMapel()
    this.fnGuru()
    this.fnTA()
    this.fnJPerangkat()
    this.fnKelasLokal()
  }
}
</script>

<style lang="scss" scoped></style>