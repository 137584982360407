import { render, staticRenderFns } from "./A_Guru_perangkat.vue?vue&type=template&id=d785eba2&scoped=true&"
import script from "./A_Guru_perangkat.vue?vue&type=script&lang=js&"
export * from "./A_Guru_perangkat.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d785eba2",
  null
  
)

export default component.exports