<template>
  <div id="history" class="fill-height">
    <Navbar />
    <v-container fluid class="pt-0 pb-0 fill-height">
      <v-row class="fill-height">
        <v-col cols="12" sm="12" class="pt-5 pl-2">
          <h4 class="title">Informasi</h4>
          <template>
            <v-snackbar v-model="snackbar" :timeout="timeout">
              {{ text }}
              <template v-slot:action="{ attrs }">
                <v-btn
                  color="blue"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  Close
                </v-btn>
              </template>
            </v-snackbar>
      
    <v-dialog v-model="konfirmHapus" max-width="400px">
      <v-card>
        <v-card-title>
        <span>Konfirmasi</span>
        </v-card-title>
        <v-card-text>
        <v-container>
          <span>Anda yakin menghapus data ini?</span>
        </v-container>
        </v-card-text>

        <v-card-actions>
        <v-spacer></v-spacer>
          <v-btn color="blue darken-1" @click="konfirmHapus = false">Batal</v-btn>
          <v-btn color="blue darken-1" @click="delData">Ya</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
        <v-row align="center" class="px-5 mx-auto mt-20">
          <v-col cols="12" md="6">
            <v-text-field v-model="text_cari" label="cari..." v-on:keyup="inputKeydown"></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
              <v-btn color="cyan" class="mb-0" @click="fnBaru()" small>+ Data</v-btn>
          </v-col>

          <v-col cols="12" sm="12">
            <v-card class="mx-auto" tile>
              <v-data-table
                :headers="headers"
                :items="ftData"
                :disable-pagination="false"
                :hide-default-footer="false"
                :loading="loading"
              loading-text="Loading... Please wait"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small class="mr-2" @click="fnEdit(item.gt_id)">mdi-pencil </v-icon>
                  <v-icon small @click="fnKonfirmasi(item.gt_id)">mdi-delete</v-icon>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>

         <v-dialog v-model="dialog" max-width="800px">
        <v-card>
          <v-card-title>
            <span>Form Informasi</span>
          </v-card-title>
          <v-card-text>
           <v-row>
              <v-col cols="6" sm="6" md="6">
                <input type="date" label="Tanggal" v-model="tanggal" format="DD/MM/YYYY" placeholder="Tanggal">
              </v-col>
              <v-col cols="6" sm="6" md="6">
                <v-text-field label="Judul" v-model="judul"></v-text-field>
              </v-col>

            <v-col cols="6" sm="6" md="6">
                <v-select item-text="nm_kelas" item-value="kelas_id" :items="lsKelas" label="Kelas" 
                v-model="kelas_id" required v-on:change="gtKelas()"></v-select>
              </v-col>
              <v-col  cols="6" sm="6" md="6">
                <v-select item-text="nm_lokal_kelas" item-value="kelas_lok_id" outlined :items="ftKelasLokal" 
                label="Lokal" v-model="kelasLokal_id" required multiple chips></v-select>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-textarea label="Isi Info" v-model="isi" outlined></v-textarea>
              </v-col>
              </v-row>
              </v-card-text>
                    <v-card-actions>
                      <v-btn color="cyan" @click="dialog = false">Tutup</v-btn>
                      <v-btn color="cyan" @click="saveData">Save</v-btn>
                    </v-card-actions>
                  </v-card>
            </v-dialog>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import { api } from '@/components/Apiku'
import axios from 'axios'
import "vue-wysiwyg/dist/vueWysiwyg.css";

export default {
  data: () => ({
    snackbar: false,
    lsStatus:true,
    ar_mapel:[],
    text: 'OK',
    timeout: 2000,
    text_cari :'',
    loading: true,
    lsData : [], ftKelasLokal:[],
    ftData : [],lsKelasLokal :[], kelasLokal_id : [],
    dialog : false,
    menuKat : false,
    dialogImpor:false,
    searchTextsLoading: true,
    judul: '',
    isi: '',
    yt_id: '',
    id: '0',
    lokal:'',
    tanggal:(new Date().toISOString().slice(0,10)),
    ddMapel : [],
    dtImpor:[],
    files: null,
    mapel_id:'',
    kelas_id:null,
    guru_id:0, ta_id:0,
    semester_id:0, lsGuru:[],
    lsKelas:[],
    lsTA:[],
    lsJPerangkat:[],
    j_perangkat_id:'',id_hapus:0,konfirmHapus:false,
    image: {
    uploadURL: "/api/myEndpoint",
    dropzoneOptions: {}
  },

  // limit content height if you wish. If not set, editor size will grow with content.
  maxHeight: "500px",
    ddSemester:[{"id":"1","label":"Semester 1"},{"id":"2","label":"Semester 2"}],
    headers: [ 
        { text: "No", width:"20", value: "nomor", sortable: false },
        { text: "Judul", align: "start", width:"60%", sortable: false, value: "judul" },
        { text: "Tanggal", value: "tglvw", sortable: false },
        { text: "Kelas", value: "nm_kelas", sortable: false },
        { text: "Lokal", value: "lokal_kelas", sortable: false },
        { text: "Aksi", value: "actions", sortable: false },
      ]
  }),
  components:{
    Navbar
  },
  methods: {
    inputKeydown: function () {
      if(this.text_cari.length>0){
        this.ftData = this.lsData.filter(m => m.judul.toUpperCase().includes(this.text_cari.toUpperCase()))
      }else{
        this.ftData = this.lsData
      }
    },
    gtKelas(){
      this.kelasLokal_id=[]
      this.ftKelasLokal = this.lsKelasLokal.filter(m => m.kelas_id.includes(this.kelas_id))
    },
    saveData () {
      //if (this.kelas_id === 0) {
        //this.snackbar =true
     // } else {
        //console.log('cek mapel ', this.j_perangkat_id)
        console.log('cek id : ',this.id)
        const dataku = {
          user_id: this.$store.state.user.user_id,
          kelas_id: this.kelas_id,
          semester_id: this.semester_id,
          mapel_id: this.mapel_id,
          guru_id: this.$store.state.user.guru_id,
          judul: this.judul,
          id: this.id,
          tanggal: this.tanggal,
          sk_id:this.$store.state.user.sk_id,
          isi:this.isi,
          kelasLokal_id:this.kelasLokal_id
         // id:0
        }
        axios
          .post(api + 'saveGInfo', dataku)
          .then(response => {
            console.log('cek ', response.data)
            if (response.data.cek === 1) {
              this.text = 'Data tersimpan'
              this.snackbar=true
              this.fnData()
              this.clsForm()
              this.dialog=false
            } else {
              console.log(' tidak sukses ')
            }
          })
      //}
    },
    fnKonfirmasi(id){
      this.id_hapus=id
      this.konfirmHapus=true
    },
    delData () {
      const dataku = {
        user_id: this.$store.state.user.user_id,
        id: this.id_hapus
      }
      axios
        .post(api + 'delGInfo', dataku)
        .then(response => {
          console.log('cek ', response.data)
          if (response.data.cek === 1) {
            this.text = 'Data berhasil dihapus'
            this.snackbar=true
            this.konfirmHapus=false
            this.fnData()      
          } else {
            console.log(' tidak sukses ')
          }
        })
    },
    async fnKelasLokal() {
      axios
        .get(api + 'lsKelasLokal&sk_id=' + this.$store.state.user.sk_id+'&jenjang_id='+this.$store.state.user.jenjang_id)
        .then(response => {
          //console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.lsKelasLokal = response.data.data
          }
        })
    },
    async fnKelas() {
      axios
        .get(api + 'lsKelas&sk_id=' + this.$store.state.user.sk_id+'&jenjang_id='+this.$store.state.user.jenjang_id)
        .then(response => {
          //console.log('kat', response.data)
          this.lsKelas = [{"kelas_id":"0", nm_kelas: "Semua Kelas"}];
          if (response.data.cek === 1) {
            let listData = response.data.data;
            for (let i = 0; i < listData.length; i++) {
              this.lsKelas.push({"kelas_id":listData[i].kelas_id, nm_kelas: listData[i].nm_kelas});
            } 
          }
        })
    },
    async fnGuru() {
      axios
        .get(api + 'lsGuru&merID=2')
        .then(response => {
          //console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.lsGuru = response.data.data
          }
        })
    },
    async fnMapel () {
      axios
        .get(api + 'ddMapel&merID=2')
        .then(response => {
          if (response.data.cek === 1) {
            this.ddMapel = response.data.data
          }
        })
    },
    async fnJPerangkat () {
      axios
        .get(api + 'lsJ_perangkat&merID=2')
        .then(response => {
          if (response.data.cek === 1) {
            this.lsJPerangkat = response.data.data
            //console.log('cek perang ', response.data.data)
          }
        })
    },
    async fnTA () {
      axios
        .get(api + 'lsTA&merID=2')
        .then(response => {
          console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.lsTA = response.data.data
          }
        })
    },
    async fnData () {
      axios
        .get(api + 'lsGInfo&user_id=' + this.$store.state.user.user_id)
        .then(response => {
          //console.log('cek data halooooo ', response.data)
          if (response.data.cek === 1) {
            this.lsData = response.data.data
            this.ftData = response.data.data
            //this.tutorials = response.data.data
          }
        })
    },
    async fnBaruKat() {
      this.menuKat = true
    },
    async fnBaru() {
      this.id=0
      this.clsForm()
      this.gtKelas()
      this.dialog=true
    },
    async fnImpor() {
      this.clsForm()
      this.dialogImpor=true
    },
    async fnEdit (id) {
      const cekIndex = this.lsData.findIndex(cek => cek.gt_id === id)
      const dtEdit = this.lsData[cekIndex]
      this.id = id
      this.kelas_id = dtEdit.kelas_id==null ? 0 : dtEdit.kelas_id;
      this.judul = dtEdit.judul
      this.tanggal = dtEdit.tanggal
      this.isi = dtEdit.isi
      this.semester_id = dtEdit.semester_id
      this.kelasLokal_id = dtEdit.lokalx
      this.dialog=true
    },
    clsForm () {
      this.judul = ''
      this.isi = ''
     // this.kelas_id = this.lsKelas[0]['kelas_id']
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
      this.searchTextsLoading = false
    }, 1000)
    this.fnKelas()
    this.fnData()
    this.fnMapel()
    this.fnGuru()
    this.fnTA()
    this.fnJPerangkat()
    this.fnKelasLokal()
  }
}
</script>

<style lang="scss" scoped></style>
