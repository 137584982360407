<template>
  <div id="history" class="fill-height">
    <Navbar />
    <v-container fluid class="pt-0 pb-0 fill-height">
      <v-row class="fill-height">
        <v-col
          cols="12"
          sm="12"
          order="2"
          order-sm="2"
          order-md="1"
          order-lg="1"
          class="pt-8 pl-2"
        >
          <h1 class="title font-weight-medium pb-5">E-learning</h1>
          <template>
            <v-snackbar
              v-model="snackbar"
              :timeout="timeout"
            >
              {{ text }}

              <template v-slot:action="{ attrs }">
                <v-btn
                  color="blue"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  Close
                </v-btn>
              </template>
            </v-snackbar>

        <template>
          
        </template>
      <v-dialog v-model="dialog" max-width="700px">
        <v-card>
          <v-card-title>
            <span>E-Learning</span>
          </v-card-title>
          <v-card-text>
            <v-container>
            <v-row>
              <v-col  cols="12" sm="6" md="6">
                <v-select item-text="nm_kelas" item-value="kelas_id" :items="lsKelas" label="Kelas" v-model="kelas_id" required></v-select>
              </v-col>
              <v-col  cols="12" sm="6" md="6">
                <v-select item-text="label" item-value="id" :items="ddSemester" label="Semester" v-model="semester_id" required></v-select>
              </v-col>
              <v-col  cols="12" sm="6" md="6">
                <v-select item-text="label" item-value="id" :items="ddMapel" label="Mata Pelajaran" v-model="mapel" required></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field label="Judul*" v-model="judul" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field label="Kompetensi" v-model="kompetensi"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field label="Youtube-ID" v-model="yt_id"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>*harus diisi</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="cyan" @click="dialog = false">Cancel</v-btn>
            <v-btn color="cyan" @click="saveData">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <v-dialog v-model="menuKat" max-width="500px">
      <v-card>
        <v-card-title>
        <span class="headline">Kategori Produk</span>
        </v-card-title>
        <v-card-text>
        <v-container>
        <v-row>
            <v-col
            cols="12"
            sm="12"
            md="12"
            >
            <v-text-field
                label="kategori Produk*"
                v-model="namaKat"
                required
            ></v-text-field>
            </v-col>
           </v-row>  
        </v-container>
        <small>*indicates required field</small>
        </v-card-text>

        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" @click="menuKat = false">Cancel</v-btn>
        <v-btn color="blue darken-1" @click="saveKat">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="konfirmHapus" max-width="400px">
      <v-card>
        <v-card-title>
        <span>Konfirmasi</span>
        </v-card-title>
        <v-card-text>
        <v-container>
          <span>Anda yakin menghapus data ini?</span>
        </v-container>
        </v-card-text>

        <v-card-actions>
        <v-spacer></v-spacer>
          <v-btn color="blue darken-1" @click="konfirmHapus = false">Batal</v-btn>
          <v-btn color="blue darken-1" @click="delData">Ya</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
          <v-row align="center" class="list px-0 mx-auto">
          <v-col cols="12" md="6">
            <v-text-field v-model="title" label="cari..."></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-btn small>
              Search
            </v-btn>
              <v-btn color="cyan" class="mb-0"  @click="fnBaru" small>+ Data E-learning</v-btn>
          </v-col>

          <v-col cols="12" sm="12">
            <v-card class="mx-auto" tile>
              <v-data-table
                :headers="headers"
                :items="tutorials"
                :disable-pagination="false"
                :hide-default-footer="false"
              >
           <template v-slot:[`item.gbrYT`]="{ item }">
              <v-img :src="item.gbrYT" :alt="item.name" height="100px" width="200px"></v-img>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="fnEdit(item.e_learn_id)">mdi-pencil </v-icon>
                  <v-icon small @click="fnKonfirmasi(item.e_learn_id)">mdi-delete</v-icon>
            </template>

              </v-data-table>

              <v-card-actions v-if="tutorials.length > 0">
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-col>
        
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import { api } from '@/components/Apiku'
import axios from 'axios'
export default {
  data: () => ({
    snackbar: false,
    text: 'OK',
    timeout: 2000,
    title:'',
    loading: true,
    lsData : [],
    ftData : [],
    dialog : false,
    menuKat : false,
    konfirmHapus:false,
    searchTextsLoading: true,
    tutorials: [],
    judul: '',
    kompetensi: '',
    yt_id: '',
    id: 0,
    hpp:0,
    harga:0,
    stokMin: 0,
    gbr: '',
    fileGbr: '',
    fileGbr2: '',
    namaKat: '',
    katID: 0,
    ddMapel : [],
    lsKelas:[],
    kelas_id:0,
    files: null,
    mapel:'',
    semester_id:1,
    e_learn_id:0,
    ddSemester:[{"id":"1","label":"Semester 1"},{"id":"2","label":"Semester 2"}],
    headers: [
      { text: "Media", value: "gbrYT", sortable: false },
      { text: "Kelas", align: "start", sortable: false, value: "nm_kelas" },
      { text: "Semester", value: "nmSemester", sortable: false },
      { text: "Judul", value: "judul", sortable: false },
      { text: "Aksi", value: "actions", sortable: false },
    ]
  }),
  components:{
    Navbar
  },
  methods: {
    clickItem(item) {
      console.log(item)
      this.historyType = item
    },
    fnTes () {
      console.log('cekkkkkkkkkkkkkkk')
    },
    pilihGbr () {
      if(this.files){
        console.log('cek ', this.files)  
        const formData = new FormData()
        formData.append("uploadfile", this.files)
        axios
          .post(api + 'uploadGbr', formData)
          .then(response => {
            console.log('cek ', response.data)
            this.fileGbr = response.data.data
            this.fileGbr2 = response.data.fgbr
            if (response.data.cek === 1) {
              console.log(' sukses ')
            } else {
              console.log(' tidak sukses ')
            }
          })
        // console.log(' gbr ', file)
      }
    },
    saveData () {
      if (this.judul === '') {
        this.snackbar =true
      } else {
        console.log('cek mapel ', this.mapel)
        const dataku = {
          user_id: this.$store.state.user.user_id,
          judul: this.judul,
          kompetensi: this.kompetensi,
          kelas_id: this.kelas_id,
          yt_id: this.yt_id,
          semester_id: this.semester_id,
          gbr: this.fileGbr,
          id:this.id,
          mapel:this.mapel
        }
        axios
          .post(api + 'saveElearning', dataku)
          .then(response => {
            console.log('cek ', response.data)
            if (response.data.cek === 1) {
              this.text = 'Data tersimpan'
              this.snackbar=true
              this.dialog = false
              this.fnData()
              this.clsForm()
              
            } else {
              console.log(' tidak sukses ')
            }
          })
      }
    },
    fnKonfirmasi(id){
      console.log(id)
      this.e_learn_id=id
      this.konfirmHapus=true
    },
    delData () {
      const dataku = {
        //userID: 1,
        e_learn_id: this.e_learn_id
       // katID: this.katID,
        //merID: 2
      }
      axios
      .post(api + 'del_e_learn', dataku)
      .then(response => {
        console.log('cek ', response.data)
        if (response.data.cek === 1) {
          this.text = 'Data terhapus'
          this.snackbar=true
          this.konfirmHapus=false
          this.clsForm()
          this.fnData()
        } else {
          console.log(' tidak sukses ')
        }
      })
    },
    saveKat () {
      if (this.namakat === '') {
        this.text = 'Data tidak lengkap'
        this.snackbar=true
      } else {
        console.log('cek kat ', this.katID)
        const dataku = {
          userID: 1,
          namaKat: this.namaKat,
          katID: this.katID,
          merID: 2
        }
        axios
          .post(api + 'saveKat', dataku)
          .then(response => {
            console.log('cek ', response.data)
            if (response.data.cek === 1) {
              this.text = 'Data tersimpan'
              this.snackbar=true
              this.clsForm()
              this.fnData()
            } else {
              console.log(' tidak sukses ')
            }
          })
      }
    },
    async fnMapel () {
      axios
        .get(api + 'ddMapel&merID=2')
        .then(response => {
          console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.ddMapel = response.data.data
          }
        })
    },
    async fnData () {
      axios
        .get(api + 'lsElearn&merID=2')
        .then(response => {
          console.log('cek data halooooo ', response.data)
          if (response.data.cek === 1) {
            this.lsData = response.data.data
            this.ftData = response.data.data
            this.tutorials = response.data.data
          }
        })
    },
    async fnBaruKat() {
      this.menuKat = true
    },
    async fnBaru() {
      this.clsForm()
      this.id=0
      this.dialog=true
    },
    async fnKelas() {
      axios
        .get(api + 'lsKelas&sk_id=' + this.$store.state.user.sk_id + '&jenjang_id=' +this.$store.state.user.jenjang_id)
        .then(response => {
          console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.lsKelas = response.data.data
          }
        })
    },
    async fnEdit (id) {
      const cekIndex = this.lsData.findIndex(cek => cek.e_learn_id === id)
      console.log(' cek data ', cekIndex, 'data : ', this.lsData[cekIndex])
      const dtEdit = this.lsData[cekIndex]
      this.id = dtEdit.e_learn_id
      this.judul = dtEdit.judul
      this.yt_id = dtEdit.yt_id
      this.kompetensi = dtEdit.kompetensi
      this.kelas_id = dtEdit.kelas_id
      this.semester_id = dtEdit.semester_id
      this.mapel = dtEdit.mapel_id
      this.dialog = true
      //console.log(' cek show dtEdit.katID ', dtEdit.katID)
      // this.itemsPaginated[cekIndex].qty = Number(this.itemsPaginated[cekIndex].qty) + 1
    },
    clsForm () {
      this.nama = ''
      this.harga = ''
      this.fileGbr = ''
      this.kode = ''
      this.hpp = 0
      this.id = 0
      this.namaKat = ''
      this.katID = 0
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
      this.searchTextsLoading = false
    }, 2000)
     // this.fnKat()
    this.fnData()
    this.fnMapel()
    this.fnKelas()
  }
}
</script>

<style lang="scss" scoped></style>
