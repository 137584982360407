<template>
  <div id="history" class="fill-height">
    <Navbar />
    <v-container>
      <template>
        <v-snackbar v-model="snackbar" :timeout="timeout">
          {{ text }}
          <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </template>
      <v-dialog v-model="DBayar" max-width="600px">
        <v-card>
          <v-card-text>
            <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <h3>
                Total Rp. {{ formatPrice(totalOrder) }}
                </h3>
              </v-col>
               <v-col cols="12" sm="6" md="6">
                <v-text-field label="Bayar" v-model="bayar" required color="green" @keyup="cek_bayar" 
                @keypress="fnBayarEnter" autofocus >

                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <h3>
                Kembali Rp. {{ formatPrice(rp_kembali) }}
                </h3>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-btn outlined color="green" @click="DBayar = false">Tutup</v-btn>
                <v-btn color="green" dark @click="fnSaveBayar" v-if="stTblBayar">Bayar</v-btn>
              </v-col>
              
            </v-row>
          </v-container>
          </v-card-text>
        </v-card>
      </v-dialog> 

      <v-dialog v-model="DLsOrder" max-width="600px">
        <v-card>
          <v-card-title>
            <span>Data Pesanan</span>
          </v-card-title>
          <v-card-text>
            <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field label="No Meja" v-model="cari_order" required color="green" @keyup="fncari_order"></v-text-field>
              </v-col>
                <v-btn color="red" fab dark small top right @click="DLsOrder = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              <v-col cols="12" sm="12" md="12">
                <v-data-table :headers="headOrder"
                  :items="ftOrder"
                  disable-pagination
                  :hide-default-footer="true"
                >
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="pilihOrder(item.orderID)">mdi-pencil </v-icon>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
          </v-card-text>
        </v-card>
      </v-dialog> 

      <v-dialog v-model="DPelanggan" max-width="600px">
        <v-card>
          <v-card-title>
            <span>Data Pelanggan</span>
          </v-card-title>
          <v-card-text>
            <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field label="Nama *" v-model="cr_pelanggan" required color="green" @keyup="fncr_pelanggan"></v-text-field>
              </v-col>
                  <v-btn color="pink" fab dark small top right>
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
                <v-btn color="red" fab dark small top right @click="DPelanggan = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              <v-col cols="12" sm="12" md="12">
                <v-data-table
                  :headers="headPelanggan"
                  :items="ftKons"
                  disable-pagination
                  :hide-default-footer="true"
                >
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="pilihKons(item.pID)">mdi-pencil </v-icon>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>    
      <v-dialog v-model="DMeja" max-width="600px">
        <v-card>
          <v-card-title>
            <span>Data Meja</span>
          </v-card-title>
          <v-card-text>
            <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field label="No Meja" v-model="cari_meja" required color="green" @keyup="fncari_meja"></v-text-field>
              </v-col>
              <v-btn color="red" fab dark small top right @click="DMeja = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
             <v-col cols="12" sm="12" md="12">
              <v-data-table
                :headers="headMeja"
                :items="ftMeja"
                disable-pagination
                :hide-default-footer="true"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small class="mr-2" @click="pilihMeja(item.mejaID)">mdi-pencil </v-icon>
                </template>
              </v-data-table>
              </v-col>
            </v-row>
          </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>   
      <v-toolbar flat class="">
        <v-row>
          <v-col cols="12" sm="4">
            <v-text-field prepend-inner-icon="mdi-magnify" v-model="cariNama" label="Search" @keyup="cekKey"></v-text-field>
          </v-col>
          <v-col cols="12" sm="8">
            <v-chip color="green" text-color="white" dense>
              <v-avatar left>
                <v-icon>fas fa-users</v-icon>
              </v-avatar>
                {{strUser.nama}}
            </v-chip>
            <v-chip color="blue" text-color="white" dense @click="DPelanggan=true">
              <v-avatar left>
                <v-icon>fas fa-users</v-icon>
              </v-avatar>
                Customer
            </v-chip>
            <v-chip color="red" text-color="white" dense @click="DMeja=true">
              <v-avatar left>
                <v-icon>fas fa-archive</v-icon>
              </v-avatar>
                Meja
            </v-chip>
            <v-chip color="lime" text-color="white" dense @click="fnLsOrder">
              <v-avatar left>
                <v-icon>fas fa-archive</v-icon>
              </v-avatar>
                List Order
            </v-chip>
            
              <v-btn color="pink" fab dark small bottom left @click="fnOrder_baru">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
             
          </v-col>
        </v-row>
      </v-toolbar>
      <v-toolbar class="mb-20" flat>
        <v-sheet class="mx-auto" max-width="800">
        <v-slide-group mandatory show-arrows>
        <v-slide-item v-slot="{ active, toggle }">
          <v-btn class="mx-2" :input-value="active" active-class="green white--text" depressed
            rounded @click="pilihKat(0), toggle"> ...
          </v-btn>
        </v-slide-item>
          <v-slide-item
            v-for="n in ddKat"
            :key="n.katID"
            v-slot="{ active, toggle }"
          >
            <v-btn
              class="mx-2"
              :input-value="active"
              active-class="green white--text"
              depressed
              rounded
              @click="pilihKat(n.katID), toggle"
            >
              {{ n.nama }} ({{n.jmlProd}})
            </v-btn>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>
    </v-toolbar>
    <br>
    <v-row class="mt-n6">
      <v-col cols="12" sm="3" v-for="client in ftData" :key="client.prodID" >
        <v-hover v-slot="{ hover }" open-delay="200" >
          <v-card color="red lighten-5" :elevation="hover ? 16 : 2" @click="fnPesan(client.prodID)">
            <v-app-bar flat color="rgba(0,0,0,0)">
              <v-chip class="ma-2" color="black" text-color="white" dense>
               Rp.{{ formatPrice(client.harga)}}
              </v-chip>
              <v-spacer></v-spacer>
              <v-btn class="" fab x-small color="white">
                <v-icon >
                  far fa-heart
                </v-icon>
              </v-btn>
            </v-app-bar>
            <div class="d-flex flex-column justify-space-between align-center">
              <v-img :src=client.fgbr max-width="180"></v-img>
            </div>
              <v-app-bar flat color="rgba(0,0,0,0)">
                <v-chip class="ma-2" color="grey lighten-3" text-color="red" dense>
                  {{ client.nama}}
                </v-chip>       
              </v-app-bar>
          </v-card>
        </v-hover>
      </v-col>    
    </v-row>
    </v-container>
    
    <v-navigation-drawer app color="grey lighten-3" right width="340">
      <div class="text-center">
        <v-card elevation="2" class="mt-2 mx-2 pa-3">
          <v-container>
            <v-row>
              No : - {{ no_order }}
            </v-row>
          </v-container>      
        </v-card>
      </div>
    
      <v-app-bar flat color="rgba(0,0,0,0)" class="mt-2">
        <v-btn fab x-small color="green lighten-4" elevation="0">
          <v-icon color="green">fas fa-clock</v-icon>
        </v-btn>
         Meja - {{ namaMeja}}
        <br>
        - {{ namaKons}}
      </v-app-bar>
      <v-divider></v-divider>
        <v-container >
          <v-row v-for="client in lsPesan" :key="client.prodID" >
            <v-col cols="12" sm="8">
              <h5 class="ml-1 grey--text text--darken-2">{{ client.nmProduk }}<br>
              <span class=" red--text text--lighten-1"> {{ formatPrice(client.harga)}}</span></h5>
            </v-col>
            <v-col cols="12" sm="4">
              <v-chip class="ml-0" color="green" outlined text-color="black" small>
                <v-icon left x-small> fas fa-minus</v-icon>
                <v-divider vertical inset class="mr-1"></v-divider>
                  {{ client.qty}}
                  <v-divider vertical inset class="ml-1" ></v-divider>
                <v-icon right x-small>fas fa-plus</v-icon>
              </v-chip>
            </v-col>
          </v-row>           
        </v-container>
        <v-toolbar class="pl-0" flat color="grey lighten-3">
          <h4>Total {{ orderID }}</h4>
          <v-spacer></v-spacer>
          <h4>{{ formatPrice(totalOrder) }}</h4>
        </v-toolbar>
        <div class="text-center">
          <v-btn outlined color="green" @click="saveOrder" v-if="stTblOrder">Order</v-btn>
          <v-btn color="green" dark @click="fnBayar" v-if="stTblBayar1">Bayar</v-btn>
        </div>  
    </v-navigation-drawer>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import { api } from '@/components/Apiku'
import axios from 'axios'
export default {
  data: () => ({
    snackbar: false, text: 'OK', timeout: 2000,
    title:'', cr_pelanggan:'',  cari_meja:'', cari_order:'',
    loading: true, DPelanggan: false, DLsOrder:false,
    DBayar:false, cariNama:'', no_order:'', bayar: 0,
    rp_kembali:0, lsData : [],  ftData : [],
    lsMeja : [],  ftMeja : [], lsKons : [],
    ftKons : [], lsPesan : [], lsOrder:[],
    lsOrder2:[], ftOrder:[],  strUser:[],
    totalOrder:0,  nmUser : 'kaka',
    ddKat:[],
    DMeja :false,
    searchTextsLoading: true,
    nama: '',
    cek_ni:'',
    kode: '',
    id: 0,
    idMeja:0,
    namaMeja:'', idKons:0,
    namaKons:'',
    orderID:0,
    bayarID:0, stTblBayar : false, stTblOrder : false, stTblBayar1 : false,
    headOrder:[
      { text: "No Meja", align: "start", sortable: false, value: "nmMeja" },
      { text: "No Order", align: "start", sortable: false, value: "nomor"},
      { text: "Actions", value: "actions", sortable: false },
    ],
    headPelanggan: [
      { text: "Nama", align: "start", sortable: false, value: "nama" },
      { text: "HP", align: "start", sortable: false, value: "hp" },
      { text: "Alamat", align: "start", sortable: false, value: "alamat" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    headMeja: [
      { text: "No Meja", align: "start", sortable: false, value: "nama" },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),
  components:{
    Navbar
  },
  methods: {
    clickItem(item) {
      console.log(item)
      this.historyType = item
    },
    fncr_pelanggan(){
      if(this.cr_pelanggan.length > 1) {
        this.ftKons =  this.lsKons.filter(post => post.nama.toLowerCase().includes(this.cr_pelanggan))
      }else{
        this.ftKons =  this.lsKons
      }
    },
    cekKey(){

      if(this.cariNama.length > 1) {
        this.ftData =  this.lsData.filter(post => post.nama.toLowerCase().includes(this.cariNama))
      }else{
        this.ftData =  this.lsData
      }
    },
    fnBayarEnter(e){
      if(e.keyCode === 13) {
        console.log('haloo ', e.keyCode)
        this.fnSaveBayar()
      }
    },
    fnBayar(){
      this.DBayar=true
    },
    async fnOrder_baru(){
      this.clsForm()
      //console.log('cek')
    },
    cek_bayar (){
      if(this.bayar.length > 1) {
        let kembalix = 0
        kembalix = Number(this.bayar) -  Number(this.totalOrder)
        this.rp_kembali = kembalix
      }else{
        this.rp_kembali =  0
      }
    }, 
    fnCari () {
      this.ftData =  this.lsData.filter(post => post.nama.toLowerCase().includes(this.cariNama))
    },
    fncari_meja () {
      if(this.cari_meja.length > 0) {
        this.ftMeja =  this.lsMeja.filter(post => post.nama.toLowerCase().includes(this.cari_meja))
      }else{
        this.ftMeja =  this.lsMeja
      }
    },
    async fncari_order () {
      if(this.cari_order.length > 0) {
        this.ftOrder =  this.lsOrder.filter(post => post.nmMeja.toLowerCase().includes(this.cari_order))
      }else{
        this.ftOrder =  this.lsOrder
      }
    },
    
    fnLsOrder(){
      this.DLsOrder = true
      axios
        .get(api + 'getOrder&merID=' + this.$store.state.user.merID)
        .then(response => {
          if (response.data.cek === 1) {
            this.lsOrder = response.data.data
            this.ftOrder = response.data.data
          }
        })
    
    },
    pilihMeja(id) {
      const cekIndex = this.lsMeja.findIndex(cek => cek.mejaID === id)
      const dtEdit = this.lsMeja[cekIndex]
      this.namaMeja = dtEdit.nama
      this.idMeja = id
      this.DMeja = false
    },
    pilihKons(id) {
      const cekIndex = this.lsKons.findIndex(cek => cek.pID === id)
      const dtEdit = this.lsKons[cekIndex]
      this.namaKons = dtEdit.nama
      this.idKons = id
      this.DPelanggan = false
    },
    pilihOrder(id) {
      const cekIndex = this.lsOrder.findIndex(cek => cek.orderID === id)
      const dtEdit = this.lsOrder[cekIndex]
      this.totalOrder = dtEdit.tot_tagihan
      this.orderID = dtEdit.orderID
      this.no_order = dtEdit.nomor
      this.namaMeja = dtEdit.nmMeja
      this.idMeja = dtEdit.mejaID
      axios
        .get(api + 'getOrder2&orderID=' + id)
        .then(response => {
          if (response.data.cek === 1) {
            this.lsPesan = response.data.data
            this.totalOrder=response.data.tot
          }
        })
      this.DLsOrder = false
      this.stTblOrder = false
      this.stTblBayar1 = true
      this.stTblBayar = true
      //console.log('cek ', id)
      //this.fnTotalOrder()
    },
    fnSaveBayar () {
      //console.log('cek bayar ', this.lsPesan.length , ' sdsd ', this.orderID, 'meje ', this.idMeja )
      if (this.lsPesan.length === 0 || this.orderID === 0 || this.idMeja===0) {
        this.text = ' Data tidak lengkap, Pilih meja dan lakukan order terlebih dulu'
        this.snackbar =true
      } else {
        if(this.bayar < this.totalOrder) {
          this.text = 'Pembayaran masih kurang'
          this.snackbar =true
        }else{
          const dataku = {
            userID: this.strUser.userID,
            orderID: this.orderID,
            bayar: this.bayar,
            bayarID: this.bayarID,
            merID: this.strUser.merID,
            totalOrder: this.totalOrder
          }
          axios
            .post(api + 'SaveBayar', dataku)
            .then(response => {
              console.log('cek order ', response.data)
              if (response.data.cek === 1) {
                this.text = 'Data tersimpan'
                this.snackbar=true
                this.stTblBayar = false
                //this.fnData()
                //this.clsForm()
                
              } else {
                this.text = 'Error'
                this.snackbar=true
                console.log(' tidak sukses ')
              }
            })
        }
        
      }
    },
    async fnTotalOrder () {
      let tot = 0
      this.lsPesan.forEach((value) => {
        tot = Number(tot) + (value.harga * value.qty)
         console.log('cek harga', value.harga )
      })
      this.totalOrder=tot
    },
    fnTes () {

    },
    async pilihKat (val) {
      console.log('cek kat ', val)
      if (val === 0) {
        this.ftData = this.lsData
      } else {
        const dtProduk = this.lsData
        const dtx2 = dtProduk.filter(cek => cek.katID === val)
        this.ftData = dtx2
      }
    },
    async saveOrder () {
      if (this.idMeja === 0 || this.lsPesan.length === 0) {
        this.text = ' Data tidak lengkap, pilih menu kemudian pilih meja '
        this.snackbar =true
      } else {
        const dataku = {
          userID: this.strUser.userID,
          idMeja: this.idMeja,
          idKons: this.idKons,
          pesan: this.lsPesan,
          id: this.id,
          merID: this.strUser.merID
        }
        axios
          .post(api + 'saveOrder', dataku)
          .then(response => {
            console.log('cek order ', response.data)
            if (response.data.cek === 1) {
              
              this.orderID=response.data.orderID
              this.no_order = response.data.no_order
              this.text = 'Data tersimpan'
              this.snackbar=true
              this.stTblOrder = false
              this.stTblBayar1 = true
              //this.fnData()
              //this.clsForm()
              
            } else {
              this.text = 'Error'
              this.snackbar=true
              console.log(' tidak sukses ')
            }
          })
      }
    },
    async fnKat () {
      axios
        .get(api + 'kat_produk&merID=' + this.$store.state.user.merID)
        .then(response => {
          if (response.data.cek === 1) {
            this.ddKat = response.data.data
          }
        })
    },
    async fnData () {
      axios
        .get(api + 'produk&merID=' + this.$store.state.user.merID)
        .then(response => {
          if (response.data.cek === 1) {
            this.lsData = response.data.data
            this.ftData = response.data.data
          }
        })
      axios
      .get(api + 'getMeja&merID=' + this.$store.state.user.merID)
      .then(response => {
        if (response.data.cek === 1) {
          this.lsMeja = response.data.data
          this.ftMeja = response.data.data
        }
      })
      axios
      .get(api + 'getPelanggan&merID=' + this.$store.state.user.merID)
      .then(response => {
        if (response.data.cek === 1) {
          this.lsKons = response.data.data
          this.ftKons = response.data.data
        }
      })
    },
    formatPrice (value) {
      const val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    async fnBaruKat() {
      this.menuKat = true
    },
    async fnBaru() {
      this.clsForm()
      this.dialog=true
    },
    async fnPesan (id) {
      const cekAda = this.lsPesan.find(cek => cek.prodID === id)
      const cekIndex = this.lsData.findIndex(cek => cek.prodID === id)
      if (cekAda) {
        const cekIndex2 = this.lsPesan.findIndex(cek => cek.prodID === id)
        this.lsPesan[cekIndex2].qty = Number(this.lsPesan[cekIndex2].qty)+1
      } else {
        const nmProduk = this.lsData[cekIndex].nama
        const harga = this.lsData[cekIndex].harga
        const datax = {'prodID':id,'qty':1, 'nmProduk':nmProduk, 'harga':harga}
        this.lsPesan.push(datax)
      }
      this.fnTotalOrder()
    },
    clsForm () {
      this.nama = ''
      this.harga = ''
      this.fileGbr = ''
      this.kode = ''
      this.hpp = 0
      this.id = 0
      this.namaKat = ''
      this.katID = 0
      this.lsOrder=[]
      this.ftOrder=[]
      this.lsPesan=[]
      this.mejaID=0
      this.idKons=0
      this.namaMeja=''
      this.totalOrder= 0
      this.orderID = 0
      this.stTblBayar = true
      this.stTblOrder =  true
      this.stTblBayar1 = false
      this.no_order=''
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
      this.searchTextsLoading = false
    }, 2000)
    this.strUser=this.$store.state.user
    this.nmUser=this.$store.state.user
    this.fnData()
    this.fnKat()
    this.clsForm()
  }
}
</script>

<style lang="scss" scoped></style>
