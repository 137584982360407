<template>
  <div id="history" class="fill-height">
    <Navbar />
    <v-container fluid class="pt-0 pb-0 fill-height">
      <v-row class="fill-height">
        <v-col cols="12" sm="12" class="pt-2 pl-2">
          <h4 class="title">Data Mata pelajaran</h4>
          <template>
            <v-snackbar
              v-model="snackbar"
              :timeout="timeout"
            >
              {{ text }}

              <template v-slot:action="{ attrs }">
                <v-btn
                  color="blue"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  Close
                </v-btn>
              </template>
            </v-snackbar>
            
   
             <v-row class="list px-0 mx-auto">
              <v-col cols="12" md="6">
                <v-text-field v-model="text_cari" label="cari..." v-on:keyup="inputKeydown">></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                 <v-btn color="cyan" class="mb-0"  @click="fnBaru" small>+ Data Mata Pelajaran</v-btn>
              </v-col>

              <v-col cols="12" sm="12">
                <v-card class="mx-auto" tile>
                  <v-data-table
                    :headers="headers"
                    :items="ftData"
                    :disable-pagination="false"
                    :hide-default-footer="false"
                    :footer-props="{
                    'items-per-page-options': [30, 40, 50]
                    }"
                    :items-per-page="30"
                    :loading="loading"
                    loading-text="Loading... Please wait"
                  >
                    <template v-slot:[`item.status`]="{ item }">
                    {{ item.is_active=="no" ? "Tidak Aktif" : "Aktif" }}
                  </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-icon small class="mr-2" @click="fnEdit(item.mapel_id)">mdi-pencil </v-icon>
                      <v-icon small @click="fnKonfirmasi(item.mapel_id)">mdi-delete</v-icon>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-col>

       <v-dialog v-model="dialog" max-width="700px">
        <v-card>
          <v-card-title>
            <span>Data Mata Pelajaran</span>
          </v-card-title>
          <v-card-text>
            <v-container>
               <v-form ref="form" v-model="valid" lazy-validation >
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field label="Nama Mapel*" v-model="nm_mapel" :counter="10"
      :rules="nameRules" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field label="Kode" :rules="nameRules" v-model="kode"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select item-text="jenjang" item-value="jenjang_id" :items="ddJenjang" label="Jenjang" v-model="jenjang_id" required></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select item-text="is_active_lbl" item-value="is_active" :items="ddIsActive" label="Status" v-model="is_active" required></v-select>
              </v-col>
              
            </v-row>
            </v-form>
          </v-container>
          <small>*harus diisi</small>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="cyan" @click="dialog = false">Cancel</v-btn>
            <v-btn color="cyan" @click="saveData">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> 
      <v-dialog v-model="konfirmHapus" max-width="400px">
      <v-card>
        <v-card-title>
        <span>Konfirmasi</span>
        </v-card-title>
        <v-card-text>
        <v-container>
          <span>Anda yakin menghapus data ini?</span>
        </v-container>
        </v-card-text>

        <v-card-actions>
        <v-spacer></v-spacer>
          <v-btn color="blue darken-1" @click="konfirmHapus = false">Batal</v-btn>
          <v-btn color="blue darken-1" @click="delData">Ya</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import { api } from '@/components/Apiku'
import axios from 'axios'
import readXlsxFile from 'read-excel-file';

export default {
  data: () => ({
    valid: false,
      name: '',
      nameRules: [
        v => !!v || 'Wajib diisi',
        v => (v && v.length >= 2) || 'Data salah',
      ],
      emailRules: [
        v => !!v || 'E-mail wajib diisi',
        v => /.+@.+\..+/.test(v) || 'E-mail harus valid',
      ],
    snackbar: false, konfirmHapus:false,
    text: 'OK',
    timeout: 2000, text_cari:'',
    kode:'',nm_mapel:'',jenjang_id:4,is_active:'no',
    loading: true,
    lsData : [],
    ftData : [],
    dialog : false,
    menuKat : false,
    dialogImpor:false,
    searchTextsLoading: true,
    tutorials: [],
    mapel_id: 0,
    fileGbr: '',
    fileGbr2: '',
    dtImpor:[],
    files: null,
    semester_id:1,
    ddJenjang:[{"jenjang_id":1,"jenjang":"TK"},{"jenjang_id":2,"jenjang":"SD"},{"jenjang_id":3,"jenjang":"SMP"},{"jenjang_id":4,"jenjang":"SMA"},{"jenjang_id":5,"jenjang":"SMK"}],
    ddIsActive:[{"is_active":'no', "is_active_lbl":"Tidak Aktif"},{"is_active":"yes", "is_active_lbl":"Aktif"}],
    headers: [
      { text: "Nama Mata Pelajaran", align: "start", sortable: false, value: "nm_mapel" },
      { text: "Kode", value: "kode", sortable: false },
      { text: "Jenjang", value: "nm_jenjang", sortable: false },
      { text: "Status", value: "status", sortable: false },
      { text: "Aksi", value: "actions", sortable: false },
    ],
  }),
  components:{
    Navbar
  },
  methods: {
    onFileChange(event) {
      let xlsxfile = event.target.files ? event.target.files[0] : null;
      readXlsxFile(xlsxfile).then((rows) => {
        this.dtImpor=rows
      })
    },
    inputKeydown: function () {
      if(this.text_cari.length>0){
        this.ftData = this.lsData.filter(m => m.nm_mapel.toUpperCase().includes(this.text_cari.toUpperCase()))
      }else{
        this.ftData = this.lsData
      }
    },
    validate () {
        this.$refs.form.validate()
      },
    pilihGbr () {
      if(this.files){
        const formData = new FormData()
        formData.append("uploadfile", this.files)
        axios
          .post(api + 'uploadGFoto', formData)
          .then(response => {
            console.log('cek ', response.data)
            this.fileGbr = response.data.data
            this.fileGbr2 = response.data.fgbr
            if (response.data.cek === 1) {
              console.log(' sukses ')
            } else {
              alert(response.data.error_msg);
              console.log(' tidak sukses ')
            }
          })
        // console.log(' gbr ', file)
      }
    },
    fnKonfirmasi(id){
      this.mapel_id=id
      this.konfirmHapus=true
    },
    delData () {
      const dataku = {
        user_id: this.$store.state.user.user_id,
        mapel_id: this.mapel_id
      }
       console.log('cek hapsu', dataku)
      axios
        .post(api + 'delMMapel', dataku)
        .then(response => {
          console.log('cek hapsu', response.data)
          if (response.data.cek === 1) {
            this.text = 'Data tersimpan'
            this.snackbar=true
            this.konfirmHapus=false
            this.fnData()
            //this.clsForm()
          } else {
            console.log(' tidak sukses ')
          }
        })
    },
    saveData () {
      this.validate()
      console.log('cek valid', this.valid)
      if (this.valid===false) {
        this.text = 'Data tidak lengkap.'
        this.snackbar =true
      } else {
        
        const dataku = {
          nm_mapel: this.nm_mapel,
          kode: this.kode,
          jenjang_id: this.jenjang_id,
          is_active: this.is_active,
          mapel_id:this.mapel_id,
          user_id: this.$store.state.user.user_id
        }
        console.log('cek dataku ', dataku)
        axios
          .post(api + 'saveMMapel', dataku)
          .then(response => {
            console.log('cek ', response.data)
            if (response.data.cek === 1) {
              this.text = 'Data tersimpan'
              this.snackbar=true
              this.dialog = false
              this.fnData()
              this.clsForm()
              
            } else {
              this.text = response.data.error_msg
              this.snackbar=true
              //console.log(' tidak sukses ')
            }
          })
      }
    },
    async fnMapel () {
      axios
        .get(api + 'ddMapel&merID=2')
        .then(response => {
          console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.ddMapel = response.data.data
          }
        })
    },
    async fnData () {
      axios
        .get(api + 'lsMapel&sk_id=' + this.$store.state.user.sk_id)
        .then(response => {
          console.log('cek data halooooo ', response.data)
          if (response.data.cek === 1) {
            this.lsData = response.data.data
            this.ftData = response.data.data
            //this.tutorials = response.data.data
          }
        })
    },
    async fnBaruKat() {
      this.menuKat = true
    },
    async fnBaru() {
      this.clsForm()
      this.dialog=true
    },
    async fnImpor() {
      this.clsForm()
      this.dialogImpor=true
    },
    async fnEdit (id) {
      const cekIndex = this.lsData.findIndex(cek => cek.mapel_id === id)
      const dtEdit = this.lsData[cekIndex]
      this.mapel_id = id
      this.nm_mapel = dtEdit.nm_mapel
      this.kode = dtEdit.kode
      this.jenjang_id = parseInt(dtEdit.jenjang_id)
      this.is_active = dtEdit.is_active
      this.dialog = true
    },
    clsForm () {
      this.nm_mapel = ''
      this.kode = ''
      this.jenjang_id = 4
      this.is_active = 'no'
      this.mapel_id = 0
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
      this.searchTextsLoading = false
    }, 1000)
     // this.fnKat()
    this.fnData()
    this.fnMapel()
  }
}
</script>

<style lang="scss" scoped></style>
