<template>
  <div id="history" class="fill-height">
    <Navbar />
    <v-container fluid class="pt-0 pb-0 fill-height">
      <v-row class="fill-height">
        <v-col
          cols="12"
          sm="12"
          order="2"
          order-sm="2"
          order-md="1"
          order-lg="1"
          md="7"
          lg="9"
          class="pt-8 pl-8"
        >
          <h2 class="title font-weight-medium pb-5">Master Data Meja</h2>
          <template>
            <v-snackbar
              v-model="snackbar"
              :timeout="timeout"
            >
              {{ text }}

              <template v-slot:action="{ attrs }">
                <v-btn
                  color="blue"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  Close
                </v-btn>
              </template>
            </v-snackbar>
            <v-dialog v-model="dialog" max-width="600px">

              <v-card>
                <v-card-title>
                  <span class="headline">Data Meja</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-text-field
                        label="Nama / Kode Meja*"
                        v-model="nama"
                        required
                        color="green"
                      ></v-text-field>
                    </v-col>

                  </v-row>
                </v-container>
                <small>*indicates required field</small>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn class="px-12" outlined color="green" @click="dialog = false">Cancel</v-btn>
                  <v-btn color="green" @click="saveData">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
       
             <v-row align="center" class="list px-0 mx-auto">
              <v-col cols="12" md="6">
                <v-text-field prepend-inner-icon="mdi-magnify" v-model="cariNama" label="Search"></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-btn small @click="fnCari">Search</v-btn>
                <v-btn class="px-12" outlined color="green" @click="fnBaru">+ Meja</v-btn>
              </v-col>

              <v-col cols="12" sm="12">
                <v-card class="mx-auto" tile>
                  <v-data-table
                    :headers="headers"
                    :items="ftData"
                    disable-pagination
                    :hide-default-footer="true"
                  >
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-icon small class="mr-2" @click="fnEdit(item.mejaID)">mdi-pencil </v-icon>
                      <v-icon small @click="fnDel(item.mejaID)">mdi-delete</v-icon>
                    </template>
                  </v-data-table>

                  <v-card-actions v-if="tutorials.length > 0">
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          order="1"
          order-sm="1"
          order-md="2"
          order-lg="2"
          md="3"
          lg="3"
          :class="[
            'pa-0',
            { 'fill-height': $vuetify.breakpoint.name.smAndDown ? true : false }
          ]"
        >
          <v-card
            id="card-radiobox"
            class="fill-height pa-10"
          >
          <v-row>
            <v-col cols="8">
              <v-text-field
                hide-details
                prepend-inner-icon="mdi-magnify"
                placeholder="Search"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
            <v-radio-group v-model="historyType">
              <p class="title font-weight-regular pl-5 mb-2">History Type</p>
              <v-list>
                <v-list-item-group>
                  <template v-for="(item, i) in items">
                    <v-divider :key="i"></v-divider>
                    <v-list-item
                      active-class="grey lighten-4"
                      :key="`item-${i}`"
                      class="py-2"
                      @click="clickItem(item)"
                    >
                      <v-list-item-content>
                        <v-list-item-title v-text="item"></v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-radio :key="item" :value="item"></v-radio>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
              </v-radio-group>
                <v-list>
                <v-list-item-group>
                  <v-list-item
                    v-for="(link, i) in links"
                    :key="i"
                  >
                    <v-list-item-icon>
                      <v-icon v-text="link.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="text-uppercase">{{link.text}} {{ historyType }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import { api } from '@/components/Apiku'
import axios from 'axios'
export default {
  data: () => ({
    snackbar: false,
    text: 'OK',
    cariNama : '',
    timeout: 2000,
    title:'',
    loading: true,
    lsData : [],
    ftData : [],
    dialog : false,
    menuKat : false,
    searchTextsLoading: true,
    tutorials: [],
    nama: '',
    kode: '',
    id: 0,
    katID: 0,
    ddKat : [],
    files: null,
    kat:'',

    headers: [
        { text: "Meja", align: "start", sortable: false, value: "nama" },
        { text: "Actions", value: "actions", sortable: false },
      ],
  }),
  components:{
    Navbar
  },
  methods: {
    clickItem(item) {
      console.log(item)
      this.historyType = item
    },
    fnCari () {
      this.ftData =  this.lsData.filter(post => post.nama.toLowerCase().includes(this.cariNama))
      console.log('cek data awal ', this.lsData)
      console.log('cek data ', this.ftData)
    },
    saveData () {
      if (this.nama === '') {
        this.snackbar =true
      } else {
        console.log('cek kat ', this.kat)
        const dataku = {
          userID: this.$store.state.user.userID,
          nama: this.nama,
          id: this.id,
          merID: this.$store.state.user.merID
        }
        axios
          .post(api + 'saveMeja', dataku)
          .then(response => {
            console.log('cek ', response.data)
            if (response.data.cek === 1) {
              this.text = 'Data tersimpan'
              this.snackbar=true
              this.dialog = false
              this.fnData()
              this.clsForm()
              
            } else {
              console.log(' tidak sukses ')
            }
          })
      }
    },
    async fnData () {
      axios
        .get(api + 'getMeja&merID=' + this.$store.state.user.merID)
        .then(response => {
          console.log('cek data halooooo ', response.data)
          if (response.data.cek === 1) {
            this.lsData = response.data.data
            this.ftData = response.data.data
            
          }
        })
    },
    formatPrice (value) {
      const val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    async fnBaru() {
      this.clsForm()
      this.dialog=true
    },
    async fnEdit (id) {
      console.log(' cek show dtEdit.katID ', id)
      const cekIndex = this.lsData.findIndex(cek => cek.mejaID === id)
      const dtEdit = this.lsData[cekIndex]
      this.id = dtEdit.mejaID
      this.nama = dtEdit.nama
      this.dialog = true
    },
    async fnDel (id) {
      axios
        .get(api + 'delMeja&id=' + id)
        .then(response => {
          console.log('cek data halooooo ', response.data.data)
          if (response.data.cek === 1) {
            //this.lsData = response.data.data
            this.text = 'Data terhapus'
            this.snackbar=true
            this.fnData()
          }
        })
    },
    clsForm () {
      this.nama = ''
      this.kode = ''
      this.hpp = 0
      this.id = 0
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
      this.searchTextsLoading = false
    }, 2000)
     // this.fnKat()
    this.fnData()
    this.fnKat()
  }
}
</script>

<style lang="scss" scoped></style>
