<style>
td {
  border:1px solid rgba(11, 10, 10, 0.19);
}
th {
  border:1px solid rgba(11, 10, 10, 0.19);
}
</style><template>
  <div id="history" class="fill-height">
    <Navbar />
    <v-container fluid class="pt-0 pb-0 fill-height">
      <v-row class="fill-height">
        <v-col
          cols="12"
          sm="12"
          order="2"
          order-sm="2"
          order-md="1"
          order-lg="1"
          md="12"
          lg="12"
          class="pt-8 pl-8"
        >
          <h4 class="title font-weight-medium pb-5">Rekap Absensi Siswa</h4>
          <template>
            <v-snackbar
              v-model="snackbar"
              :timeout="timeout"
            >
              {{ text }}

              <template v-slot:action="{ attrs }">
                <v-btn
                  color="blue"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  Close
                </v-btn>
              </template>
            </v-snackbar>
        <template>
        </template>
        <v-row align="center" class="list px-0 mx-auto">
          <v-col  cols="3" sm="1" md="1">
            <v-select item-text="nm_kelas" item-value="kelas_id" :items="lsKelas" label="Kelas" v-model="kelas_id" 
            required v-on:change="gtKelas()"></v-select>
          </v-col>
          <v-col  cols="6" sm="3" md="3">
            <v-select item-text="nm_lokal_kelas" item-value="kelas_lok_id" :items="ftKelasLokal" label="Lokal"
              v-model="kelas_lok_id" required></v-select>
          </v-col>
            <v-col  cols="4" sm="3" md="3">
              <v-select item-text="nmBulan" item-value="id" :items="lsBulan" label="Bulan"
                v-model="bulan_id" required></v-select>
            </v-col>
            <v-col  cols="2" sm="2" md="2">
              <v-select item-text="th" item-value="th" :items="lsth" label="Tahun"
                v-model="th" required></v-select>
            </v-col>
          <v-col  cols="12" sm="2" md="2">
            <v-btn small @click="fnData">Cari..</v-btn>
            <v-btn small @click="fnExcel()">Excel</v-btn>
          </v-col>
          <v-col cols="12" sm="12">
            <v-card class="mx-auto" tile id="print">
              <v-data-table
                :items="ftData"
                disable-pagination
                :hide-default-footer="true"
              >
                <!--<template v-slot:[`item.actions`]="{ item }">
                  <v-icon small class="mr-2" @click="fnEdit(item.prodID)">mdi-pencil </v-icon>
                  <v-icon small @click="deleteTutorial(item.prodID)">mdi-delete</v-icon>
                </template>-->
                <template #header="{ }">
                <thead>
                  <tr>
                    <th :rowspan="2">
                      Nama
                    </th>
                    <th v-for="(h, i) in headers" :key="i" class="text-center parent-header td-border-style" :colspan="3" :style="h.hari=='MINGGU' ? 'background-color: #df8b8b;' : ''">
                      TANGGAL {{ h.tanggal }}<br>{{ h.hari }}
                    </th>
                  </tr>
                  <tr>
                    <template v-for="(h, i) in headers">
                    <th :key="i+'kehadiran'" :style="h.hari=='MINGGU' ? 'background-color: #df8b8b;' : ''">
                      Kehadiran
                    </th>
                    <th :key="i+'jammasuk'" :style="h.hari=='MINGGU' ? 'background-color: #df8b8b;' : ''">
                      Jam Masuk
                    </th>
                    <th :key="i+'jampulang'" :style="h.hari=='MINGGU' ? 'background-color: #df8b8b;' : ''">
                      Jam Pulang
                    </th>
                    </template>
                  </tr>
                </thead>
              </template>
              <template #item="items">
                <tr>
                  <td>
                    {{items.item.nama}}
                  </td>
                  <template v-for="vv in headerNumber">
                    <td :key="vv+'valkehadiran'" :style="items.item['hari'+vv]==7 ? 'background-color: #df8b8b;' : ''">
                      <span :style="items.item['kode'+vv]=='H' ? 'color: green;' : ''">{{items.item['kode'+vv]}}</span>
                    </td>
                    <td :key="vv+'valjammasuk'" :style="items.item['hari'+vv]==7 ? 'background-color: #df8b8b;' : ''">
                      {{items.item['jammasuk'+vv]}}
                    </td>
                    <td :key="vv+'valjampulang'" :style="items.item['hari'+vv]==7 ? 'background-color: #df8b8b;' : ''">
                      {{items.item['jampulang'+vv]}}
                    </td>
                  </template>
                </tr>
              </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import { api } from '@/components/Apiku'
import XLSX from 'xlsx';
import axios from 'axios'
export default {
  data: () => ({
    snackbar: false,
    date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    tg1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    tg2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    menu1: false,
    menu2: false, kelas_lok_id:null,
    text: 'OK', lsth: null,
    timeout: 2000, bulan_id:1,th:null,
    title:'',
    loading: true, kelas_id:null, kelasLokal_id:null,
    lsData : [], lsKelas:[], ftKelasLokal:[],
    ftData : [], lsKelasLokal:[],
    dialog : false,
    menuKat : false,
    searchTextsLoading: true,
    tutorials: [],
    headerNumber: 0,
    headers: [
     // { text: "Nama", align: "start", sortable: false, value: "nama",width:"300px" },
    ],
    lsHari: ["SENIN","SELASA", "RABU", "KAMIS", "JUMAT", "SABTU", "MINGGU"],
    lsBulan: [
      { id: 1, nmBulan: "Januari"},
      { id: 2, nmBulan: "Februari"},
      { id: 3, nmBulan: "Maret"},
      { id: 4, nmBulan: "April"},
      { id: 5, nmBulan: "Mei"}, { id: 6, nmBulan: "Juni"},{ id: 7, nmBulan: "Juli"},
      { id: 8, nmBulan: "Agustus"},{ id: 9, nmBulan: "September"},{ id: 10, nmBulan: "Oktober"},
      { id: 11, nmBulan: "NoVember"},{ id: 12, nmBulan: "Desember"},
    ],
  }),
  components:{
    Navbar
  },
  methods: {
    TgClick (date) {
       console.log('cek', date)
       this.tg1 = date
    },
    async fnKelas() {
      axios
        .get(api + 'lsKelas&sk_id=' + this.$store.state.user.sk_id+'&jenjang_id='+this.$store.state.user.jenjang_id)
        .then(response => {
          if (response.data.cek === 1) {
            this.lsKelas = response.data.data
          }
        })
    }, 
    async fnTH() {
      axios
        .get(api + 'lsth')
        .then(response => {
          if (response.data.cek === 1) {
            this.lsth = response.data.data
          }
        })
    },
    fnExcel() {
      const dataExcel = []

      let judul = [
        'Laporan Rekap Absensi Siswa'
      ]
      dataExcel.push(judul)
      judul = [
        this.$store.state.user.nmSekolah
      ]
      dataExcel.push(judul)

      judul = [
        'Bulan : '  + this.bulan_id + ' Tahun : ' + this.th 
      ]
      dataExcel.push(judul)
      let dataku = ['NO','NAMA SISWA'];
      for(let i=0; i<this.headers.length; i++){
        dataku.push("TANGGAL "+this.headers[i].tanggal+" ("+this.headers[i].hari+")");
        dataku.push("");
        dataku.push("");
      }
      dataExcel.push(dataku)

      dataku = ['',''];
      let headermerged = [{ s: { r: 4, c: 0 }, e: { r: 5, c: 0 } }, { s: { r: 4, c: 1 }, e: { r: 5, c: 1 } }];
      let startcol = 2;
      for(let i=0; i<this.headers.length; i++){
        dataku.push("Kehadiran");
        dataku.push("Jam Masuk");
        dataku.push("Jam Pulang");
        headermerged.push({ s: { r: 4, c: startcol }, e: { r: 4, c: startcol+2 } });
        startcol += 3;
      }
      dataExcel.push(dataku);

      let i=1
      this.ftData.forEach((value ) => {
        const dataku = [
          i,value['nama']];
        for(let i=1; i<=this.headers.length; i++){
          dataku.push(value['kode'+i]);
          dataku.push(value['jammasuk'+i]);
          dataku.push(value['jampulang'+i]);
        }
        dataExcel.push(dataku)
        i++
      })
      var wscols = [
        {wch:10},
        {wch:30},
        //{wch:15},
        //{wch:20},
        //{wch:20},
        //{wch:20}
      ];
      const data = XLSX.utils.json_to_sheet(dataExcel)
      const wb = XLSX.utils.book_new()
      data["!cols"] = wscols;
      data["!merges"] = headermerged;

      const huruf = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']
      for(let i of huruf){
        //const datax ={ text: ""+i+"", value: ""+i+"", sortable: false };
        let cek = i+'1'
        data[cek]=''
        //dataku.push(i);
      }
      XLSX.utils.book_append_sheet(wb, data, 'data')
      XLSX.writeFile(wb,'rekap_absensi_siswa.xlsx')
    }, 
    gtKelas(){
      this.kelasLokal_id=[]
      this.ftKelasLokal = this.lsKelasLokal.filter(m => m.kelas_id.includes(this.kelas_id))
    },
    TgClick2 (date) {
       this.tg2 = date
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      console.log(' cek', day, month)
      return `${month}/${day}/${year}`

    },
    parseDate (date) {
      console.log('cek', date)
      if (!date) return null
      const [month, day, year] = date.split('/')
      console.log(' cek', day, month)
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    getDaysInMonth(year, month) {
        return new Date(year, month, 0).getDate();
      },
    //getDays (year, month) => new Date(year, month, 0).getDate(),
    async fnCek2 () {
      this.fnData()
      const x= this.getDaysInMonth(2021, 8)
      console.log('cek ', x)
    },
    async fnData () {
      axios
        .get(api + 'lapAbsenSiswa&kelas_lok_id=' + this.kelas_lok_id  + '&bulan_id=' + this.bulan_id +'&th='+this.th)
        .then(response => {
          console.log(' tgl lok_id=' + this.kelas_lok_id  + '&bulan_id=' + this.bulan_id)
          console.log('cek data absen ', response.data)
          if (response.data.cek === 1) {
            this.lsData = response.data.data;
            this.ftData = response.data.data;
            this.headerNumber = response.data.jumlahtgl;
            this.fnHeader(response.data.jumlahtgl, response.data.haripertama);
          }else{
            this.lsData = []
            this.ftData = []
            this.headerNumber = 0;
            this.fnHeader(0, 1);
          }
        })
    },
    formatPrice (value) {
      const val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    clsForm () {
      this.nama = ''
    },
    fnHeader(headerNumber, firstDay){
      console.log('cek')
      //const numbers = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31]
      /**/
      this.headers =  [
        //{ text: "Nama", align: "start", sortable: false, value: "nama",width:"300px" },
      ];
      for(let i=1; i<=headerNumber; i++){
        const datax ={ tanggal: ""+i, hari:this.lsHari[firstDay-1], align: "center", value: ""+i+"", sortable: false };
        this.headers.push(datax);
        firstDay++;
        if (firstDay>7) firstDay = 1;
      }

      
    },
    async fnKelasLokal() {
      axios
        .get(api + 'lsKelasLokal&sk_id=' + this.$store.state.user.sk_id+'&jenjang_id='+this.$store.state.user.jenjang_id)
        .then(response => {
          if (response.data.cek === 1) {
            this.lsKelasLokal = response.data.data
          }
        })
    },
    print () {
        var prtContent = document.getElementById("print");
         var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
         WinPrint.document.write(prtContent.innerHTML);
         WinPrint.document.close();
         WinPrint.focus();
         WinPrint.print();
         WinPrint.close();
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
      this.searchTextsLoading = false
    }, 2000)
    this.fnKelas()
    this.fnKelasLokal()
    this.fnHeader()
    this.fnTH()
    //this.fnData()
  }
}
</script>

<style lang="scss" scoped></style>
