<template>
  <div id="history" class="fill-height">
    <Navbar />
    <v-container fluid class="pt-0 pb-0 fill-height">
      <v-row class="fill-height">
        <v-col
          cols="12"
          sm="12"
          order="2"
          order-sm="2"
          order-md="1"
          order-lg="1"
          md="7"
          lg="7"
          class="pt-8 pl-8"
        >
          <h1 class="title font-weight-medium pb-5">{{ historyType }}</h1>
          <template>
            <div v-for="item in ftData" :key="item.prodID"  class="mb-5">
              <v-skeleton-loader
                class="mx-auto"
                type="list-item-avatar-three-line"
                :loading="loading"
                tile
                large
              >
                <v-card class="card" tile flat>
                  <v-row no-gutters>
                    <v-col class="mx-auto" cols="3" sm="3" md="5" lg="3">
                      <v-list-item-avatar size="80">
                        <v-img :src="item.fgbr" v-if="item.image"></v-img>
                        <div class="avatar rounded-circle mr-10" v-else>
                        {{ item.nama.substring(0,2) }}
                      </div>
                      </v-list-item-avatar>
                    </v-col>
                    <v-col>
                      <div class="ml-2">
                        <v-card-title
                          class="pl-2 pt-0 subtitle-1 font-weight-bold d-flex justify-space-between"
                          style="line-height: 1"
                        >
                          {{ item.nama}}

                          <v-btn text>
                            <v-icon>mdi-close</v-icon>
                          </v-btn>
                        </v-card-title>

                        <v-card-subtitle
                          class="pl-2 pt-2 pb-0"
                          style="line-height: 1"
                        >
                          Bouhriz Abir<v-icon>mdi-circle-small</v-icon>9.6k views
                        </v-card-subtitle>
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </v-skeleton-loader>
            </div>
          </template>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          order="1"
          order-sm="1"
          order-md="2"
          order-lg="2"
          md="5"
          lg="5"
          :class="[
            'pa-0',
            { 'fill-height': $vuetify.breakpoint.name.smAndDown ? true : false }
          ]"
        >
          <v-card
            flat
            id="card-radiobox"
            class="fill-height pa-10"
          >
          <v-row>
            <v-col cols="8">
              <v-text-field
                hide-details
                prepend-inner-icon="mdi-magnify"
                placeholder="Search"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
            <v-radio-group v-model="historyType">
              <p class="title font-weight-regular pl-5 mb-2">History Type</p>
                <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal" @click="clsForm">
                  + Produk <frProduk/>
                </button>
                <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#ModalKat" @click="clsForm">
                  + Kategori
                </button>

              <div class="modal fade" id="exampleModal" size="1200px" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog modal-lg" role="document" size="lg" >
                <div class="modal-content" size="lg" >
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Produk</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label class="form-control-label" for="input-username">Foto Produk</label>
                        </div>
                      </div>
                      <div class="col-lg-6">

                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <label class="form-control-label" for="input-username">Nama Produk</label>
                          <input type="text" v-model="nama" class="form-control" placeholder="Nama Produk">
                        </div>
                      </div>
                    </div>
                    
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary" @click="saveData" data-dismiss="modal">Save changes</button>
                  </div>
                </div>
              </div>
            </div>
              <v-list>
                <v-list-item-group>
                  <template v-for="(item, i) in items">
                    <v-divider :key="i"></v-divider>
                    <v-list-item
                      active-class="grey lighten-4"
                      :key="`item-${i}`"
                      class="py-2"
                      @click="clickItem(item)"
                    >
                      <v-list-item-content>
                        <v-list-item-title v-text="item"></v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-action>
                        <v-radio :key="item" :value="item"></v-radio>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
              </v-radio-group>
                <v-list>
                <v-list-item-group>
                  <v-list-item
                    v-for="(link, i) in links"
                    :key="i"
                  >
                    <v-list-item-icon>
                      <v-icon v-text="link.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="text-uppercase">{{link.text}} {{ historyType }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import { api } from '@/components/Apiku'
import frProduk from '@/components/frProduk'
import axios from 'axios'
export default {
  data: () => ({
    loading: true,
    lsData : [],
    ftData : [],
    nama: '',
    kode: '',
    id: 0,
    stokMin: 0,
    gbr: '',
    fileGbr: '',
    fileGbr2: '',
    namaKat: '',
    katID: 0,
    searchTextsLoading: true,
    items: [
      'Watch History',
      'Community'
    ],
    links: [
        { text: 'Clear all', icon: 'mdi-delete' },
        { text: 'Pause', icon: 'mdi-pause-circle-outline' },
        { text: 'Manage all activity', icon: 'mdi-cog' },
      ],
    historyType: 'Watch History'
  }),
  components:{
    Navbar, frProduk
  },
  methods: {
    clickItem(item) {
      console.log(item)
      this.historyType = item
    },
    async fnData () {
      axios
        .get(api + 'produk&merID=2')
        .then(response => {
          console.log('cek data halooooo ', response.data)
          if (response.data.cek === 1) {
            this.lsData = response.data.data
            this.ftData = response.data.data
          }
        })
    },
    formatPrice (value) {
      const val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    async fnEdit (id) {
      const cekIndex = this.lsData.findIndex(cek => cek.prodID === id)
      console.log(' cek data ', cekIndex, 'data : ', this.lsData[cekIndex])
      const dtEdit = this.lsData[cekIndex]
      this.form.nama = dtEdit.nama
      this.form.id = dtEdit.prodID
      this.form.nama = dtEdit.nama
      this.form.hpp = dtEdit.hpp
      this.form.harga = dtEdit.harga
      this.form.kode = dtEdit.kode
      this.form.kat = dtEdit.katID
      this.form.fileGbr = dtEdit.image
      this.katID = dtEdit.katID
      this.form.fileGbr2 = dtEdit.fgbr
      this.showModal = true
      console.log(' cek show dtEdit.katID ', dtEdit.katID)
      // this.itemsPaginated[cekIndex].qty = Number(this.itemsPaginated[cekIndex].qty) + 1
    },
    saveData () {
      if (this.form.nama === '') {
        console.log(' tidak sukses ')
      } else {
        console.log('cek kat ', this.form.kat)
        const dataku = {
          data: this.itemsPaginated,
          userID: this.$store.state.user.userID,
          nama: this.form.nama,
          kode: this.form.kode,
          katID: this.form.kat,
          id: this.form.id,
          stok: 0,
          harga: this.form.harga,
          hpp: this.form.hpp,
          stokMin: this.form.stokMin,
          merID: this.$store.state.user.merID,
          gbr: this.form.fileGbr
        }
        axios
          .post(api + 'saveProduk', dataku)
          .then(response => {
            console.log('cek ', response.data)
            if (response.data.cek === 1) {
              this.clsForm()
              this.fnData()
            } else {
              console.log(' tidak sukses ')
            }
          })
      }
    },
    saveData2 () {
      if (this.form.namakat === '') {
        console.log(' tidak sukses ')
      } else {
        console.log('cek kat ', this.form.katID)
        const dataku = {
          userID: this.$store.state.user.userID,
          namaKat: this.form.namaKat,
          katID: this.form.katID,
          merID: this.$store.state.user.merID
        }
        axios
          .post(api + 'saveKat', dataku)
          .then(response => {
            console.log('cek ', response.data)
            if (response.data.cek === 1) {
              this.clsForm()
              this.fnData()
            } else {
              console.log(' tidak sukses ')
            }
          })
      }
    },
    pilihGbr (event) {
      const file = event.target.files[0]
      this.form.gbr = event.target.files[0]
      const formData = new FormData()
      formData.append('uploadfile', this.form.gbr)
      axios
        .post(api + 'uploadGbr', formData)
        .then(response => {
          console.log('cek ', response.data)
          this.form.fileGbr = response.data.data
          this.form.fileGbr2 = response.data.fgbr
          if (response.data.cek === 1) {
            console.log(' tidak sukses ')
          } else {
            console.log(' tidak sukses ')
          }
        })
      console.log(' gbr ', file)
    },
    clsForm () {
      this.nama = ''
      console.log(' ceeaaf')
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
      this.searchTextsLoading = false
    }, 2000)
    this.fnData()
  }
}
</script>

<style lang="scss" scoped></style>
