<template>
  <div id="history" class="fill-height">
    <Navbar />
    <v-container fluid class="pt-0 pb-0 fill-height">
      <v-row class="fill-height">
        <v-col
          cols="12"
          sm="12"
          order="2"
          order-sm="2"
          order-md="1"
          order-lg="1"
          md="10"
          lg="10"
          class="pt-8 pl-8"
        >
          <h4 class="title">Data Siswa Per Kelas</h4>
          <template>
            <v-snackbar
              v-model="snackbar"
              :timeout="timeout"
            >
              {{ text }}

              <template v-slot:action="{ attrs }">
                <v-btn
                  color="blue"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  Close
                </v-btn>
              </template>
            </v-snackbar>
            <v-dialog v-model="konfirmHapus" max-width="400px">
              <v-card>
                <v-card-title>
                <span>Konfirmasi</span>
                </v-card-title>
                <v-card-text>
                <v-container>
                  <span>Anda yakin menghapus data ini?</span>
                </v-container>
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" @click="konfirmHapus = false">Batal</v-btn>
                  <v-btn color="blue darken-1" @click="delData">Ya</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialog" max-width="700px">
              <v-card>
                <v-card-title>
                  <span>Data Siswa Per Kelas</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                  <v-row>
                    <v-col  cols="12" sm="6" md="6">
                      <v-select item-text="nm_ta" item-value="ta_id" :items="lsTA" label="Tahun Ajaran"
                       v-model="ta_id" required  v-on:change="gtTA()"></v-select>
                    </v-col>
                    <v-col  cols="12" sm="6" md="6">
                      <v-select item-text="nm_lokal_kelas" item-value="kelas_lok_id" :items="ftKelasLokal" label="Lokal Kelas"
                      v-model="kelas_lokal_id" required></v-select>
                    </v-col>
                    <v-col  cols="12" sm="12" md="12">
                      <v-select item-text="nama" item-value="sis_id" :items="lsSiswaPilKls" label="Siswa" v-model="siswa_id" required></v-select>
                    </v-col>
                  </v-row>
                </v-container>
                <small>*harus diisi</small>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="cyan" @click="dialog = false">Cancel</v-btn>
                  <v-btn color="cyan" @click="saveData">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogPindah" max-width="700px">
              <v-card>
                <v-card-title>
                  <span>Form Siswa Pindah Kelas</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                  <v-row>
                    <v-col  cols="12" sm="12" md="12">
                      <v-select item-text="nm_lokal_kelas" item-value="kelas_lok_id" :items="lsKelasLokal" 
                      label="Lokal Kelas" v-model="kelas_lokal_id" required>
                      </v-select>
                    </v-col>
                  </v-row>
                </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="cyan" @click="dialogPindah = false">Cancel</v-btn>
                  <v-btn color="cyan" @click="savePindah">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogImpor" max-width="900px">
              <v-card>
                <v-card-title>
                  <span>Impor Data</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                  <v-row>
                    <v-col cols="12" sm="6"  md="6">
                    <v-select item-text="nm_ta" item-value="ta_id" :items="lsTA" label="Angkatan" v-model="ta_id" required></v-select>
                    </v-col>
                    <v-col cols="12" sm="6"  md="6">
                      <v-select item-text="nm_lokal_kelas" item-value="kelas_lok_id" :items="lsKelasLokal" label="Lokal Kelas"
                        v-model="kelas_lokal_id" required></v-select>
                    </v-col>
                    <v-col cols="12" sm="6"  md="6">
                      <input type="file" @change="onFileChange" />
                    </v-col>
                    <v-col cols="12" sm="6"  md="6">
                      <a href="/data_siswa.xlsx">Download template Excel Data Siswa</a>
                    </v-col>
                  </v-row>
                </v-container>
                <v-col cols="12" sm="12">
                  <v-card class="mx-auto" tile>
                    <v-data-table
                      :headers="hdImpor"
                      :items="dtImporx"
                      :disable-pagination="false"
                      :hide-default-footer="false"
                      :footer-props="{
                        'items-per-page-options': [30, 40, 50]
                      }"
                      :items-per-page="30"
                    >
                      <template v-slot:[`item.actions`]="{ item }">
                        <v-icon small @click="deleteTutorial(item.prodID)">mdi-delete</v-icon>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-col>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="cyan" @click="dialogImpor = false">Cancel</v-btn>
                  <v-btn color="cyan" @click="saveImpor">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
             <v-row class="list px-0 mx-auto">
              <v-col cols="12" md="6">
                <v-text-field v-model="text_cari" label="cari..." v-on:keyup="inputKeydown">></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                  <v-btn color="cyan" class="mb-0"  @click="fnBaru" small>+ Data</v-btn>
                  <v-btn color="cyan" class="mb-0"  @click="fnImpor" small>Import Data</v-btn>
              </v-col>
              <v-col cols="12" md="6" v-if="selected.length>0">
                 <v-btn color="green" class="mb-0"  @click="dialogPindah = true" small>
                    <v-icon>mdi-account-switch</v-icon>Pindah
                  </v-btn>
                  <v-btn color="red" class="mb-0"  @click="konfirmHapus= true" small>
                    <v-icon>mdi-delete</v-icon>Hapus
                  </v-btn>
              </v-col>

              <v-col cols="12" sm="12">
                <v-card class="mx-auto" tile>
                  <v-data-table
                    :headers="headers"
                    :items="ftData"
                    :disable-pagination="false"
                    :hide-default-footer="false"
                    :single-select="false"
                    :footer-props="{
                      'items-per-page-options': [40, 50, 60]
                    }"
                    :items-per-page="40"
                    show-select
                    item-key="sista_id"
                    v-model="selected"
                    :loading="loading"
                    loading-text="Loading... Please wait"
                  > 
                  <template v-slot:[`item.fotox`]="{ item }">
                    <v-img :src="item.f_foto_siswa" height="100px" width="100px"></v-img>
                  </template>
                  
                  <!--
                    <template v-slot:[`item.actions`]="{ item }">
                     
                      <v-icon small class="mr-2" @click="fnEdit(item.prodID)">mdi-pencil </v-icon>
                      
                      <v-icon small @click="deleteTutorial(item.prodID)">mdi-delete</v-icon>
                      
                    </template>
                    -->
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="2"
          lg="2"
          :class="[
            'pa-0',
            { 'fill-height': $vuetify.breakpoint.name.smAndDown ? true : false }
          ]"
        >
          <v-card
            id="card-radiobox"
            class="fill-height pa-10"
          >
          <v-row>
            <v-col cols="8">
              Filter
            </v-col>
          </v-row>
          <v-select item-text="nm_ta" item-value="ta_id" :items="lsTA" label="Tahun Ajaran" 
          v-model="ta_id" required v-on:change="gtTA()"></v-select>
          <v-select item-text="nm_lokal_kelas" item-value="kelas_lok_id" :items="ftKelasLokal" label="Lokal Kelas"
          v-model="kelas_lokal_id2" required v-on:change="gtLokal()"></v-select>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import { api } from '@/components/Apiku'
import axios from 'axios'
import readXlsxFile from 'read-excel-file';

export default {
  data: () => ({
    snackbar: false, dialogPindah:false,
    selected: [],text_cari:"",
    text: 'OK',
    timeout: 2000, konfirmHapus:false,
    title:'',
    loading: true,
    lsData : [], kelas_lokal_id2:0,
    ftData : [],
    dialog : false,
    menuKat : false,
    dialogImpor:false,
    searchTextsLoading: true,
    judul: '',
    kompetensi: '',
    yt_id: '',
    id: 0,
    lokal:'',
    ddMapel : [],
    dtImpor:[],
    files: null,
    mapel:'',
    kelas_id:1,
    kelas_lokal_id:0,
    guru_id:0,
    ta_id:0,
    lsSiswaPilKls:[], siswa_id:0,
    lsGuru:[],
    lsKelas:[],
    lsTA:[],
    lsKelasLokal:[], ftKelasLokal:[], dtImporx:[],
    ddSemester:[{"id":"1","label":"Semester 1"},{"id":"2","label":"Semester 2"}], 
    hdImpor: [
      { text: "Nama Siswa", align: "start", sortable: false, value: "nama" },
      { text: "NISN ", value: "nis", sortable: false },
      { text: "Email", value: "email", sortable: false },
      { text: "Alamat", value: "alamat", sortable: false },
      { text: "HP", value: "hp", sortable: false },
      { text: "Password", value: "pwd", sortable: false },
      { text: "Aksi", value: "actions", sortable: false },
    ],
    headers: [
      { text: "Foto", align: "start", width:50,sortable: false, value: "fotox" },
      { text: "NISN ", value: "nis", sortable: false },
      { text: "Nama Siswa", value: "nama", sortable: false },
      { text: "Alamat", value: "alamat", sortable: false },
      { text: "Kelas", align: "start", sortable: false, value: "nm_lokal" },
      //{ text: "Aksi", value: "actions", sortable: false },
    ]
  }),
  components:{
    Navbar
  },
  methods: {
    inputKeydown: function () {
      if(this.text_cari.length>0){
        this.ftData = this.lsData.filter(m => m.nama.toUpperCase().includes(this.text_cari.toUpperCase()))
      }else{
        this.ftData = this.lsData
      }
    },
    delData () {
      const dataku = {
        user_id: this.$store.state.user.user_id,
        data: this.selected
      }
      console.log('cek hapus ', dataku)
      axios
        .post(api + 'delMSisTA', dataku)
        .then(response => {
          if (response.data.cek === 1) {
            this.text = 'Data tersimpan'
            this.snackbar=true
            this.konfirmHapus=false
            this.fnData()          
          } else {
            console.log(' tidak sukses ')
          }
        })
    },
    savePindah () {
      const dataku = {
        user_id: this.$store.state.user.user_id,
        kelas_lokal_id: this.kelas_lokal_id,
        data: this.selected
      }
      axios
        .post(api + 'pindahMSisTA', dataku)
        .then(response => {
          console.log('cek data ', response.data.data)
          if (response.data.cek === 1) {
            this.text = 'Data tersimpan'
            this.snackbar=true
            this.dialogPindah = false
            this.fnData()          
          } else {
            console.log(' tidak sukses ')
          }
        })
    },
    cek (){
      console.log('cek  ', this.selected)
    },
    onFileChange(event) {
      let xlsxfile = event.target.files ? event.target.files[0] : null;
      this.dtImporx=[]
      readXlsxFile(xlsxfile).then((rows) => {
        rows.forEach((value,index ) => {
        if(index > 1) {
          const dataku = {
            nama: value[1],
            nis: value[2],
            email: value[3],
            hp: value[4],
            alamat: value[5],
            pwd: value[6],
          }
          this.dtImporx.push(dataku)
        }
      });
        this.dtImpor=rows
      })
    },
    gtLokal (){
      this.fnData()
    },
    gtTA(){
      this.ftKelasLokal = this.lsKelasLokal.filter(m => m.ta_id.toUpperCase().includes(this.ta_id.toUpperCase()))
    },
    async saveData () {
      if (this.siswa_id === 0) {
        this.snackbar =true
      } else {
        const dataku = {
          kelas_lokal_id: this.kelas_lokal_id,
          siswa_id: this.siswa_id,
          ta_id: this.ta_id,
          sk_id:this.$store.state.user.sk_id,
          id:this.id
        }
        console.log('cek dataku ', dataku)
        axios
          .post(api + 'saveSisTA', dataku)
          .then(response => {
            console.log('cek ', response.data)
            if (response.data.cek === 1) {
              this.text = 'Data tersimpan'
              this.snackbar=true
              this.dialog = false
              this.fnData()
              this.clsForm()
              
            } else {
              console.log(' tidak sukses ')
            }
          })
      }
    },
    saveImpor () {
      const cekImpor = Number(this.dtImporx.length);
      if (cekImpor < 1) {
        this.snackbar =true
      } else {
        const dataku = {
          kelas_lokal_id: this.kelas_lokal_id,
          ta_id : this.ta_id, sk_id:this.$store.state.user.sk_id,
          data:this.dtImporx
        }
        axios
          .post(api + 'imporSiswaKls', dataku)
          .then(response => {
            console.log('cek ', response.data)
            if (response.data.cek === 1) {
              this.text = 'Data tersimpan'
              this.snackbar=true
              this.dialog = false
              this.fnData()             
            } else {
              console.log(' tidak sukses ')
            }
          })
      }
    },
    async fnKelas() {
      axios
        .get(api + 'lsKelas&sk_id=' + this.$store.state.user.sk_id+'&jenjang_id='+this.$store.state.user.jenjang_id)
        .then(response => {
          if (response.data.cek === 1) {
            this.lsKelas = response.data.data
          }
        })
    }, 
    fnSiswaPilKls() {
      axios
        .get(api + 'lsSiswaPilKls&sk_id=' + this.$store.state.user.sk_id)
        .then(response => {
          if (response.data.cek === 1) {
            this.lsSiswaPilKls = response.data.data
          }
        })
    },
    async fnGuru() {
      axios
        .get(api + 'lsGuru&merID=2')
        .then(response => {
          if (response.data.cek === 1) {
            this.lsGuru = response.data.data
          }
        })
    },
    async fnMapel () {
      axios
        .get(api + 'ddMapel&merID=2')
        .then(response => {
          //console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.ddMapel = response.data.data
          }
        })
    },
    async fnTA () {
      axios
        .get(api + 'lsTA&merID=2')
        .then(response => {
          if (response.data.cek === 1) {
            this.lsTA = response.data.data
          }
        })
    },
    async fnKelasLokal () {
      axios
        .get(api + 'lsKelasLokal&sk_id=' + this.$store.state.user.sk_id)
        .then(response => {
          //console.log('cek lokal ' ,response.data.data )
          if (response.data.cek === 1) {
            this.lsKelasLokal = response.data.data
          }
        })
    },
    async fnData () {
      this.lsData = []
      this.ftData = []
      axios
        .get(api + 'lsSiswaTA&sk_id=' + this.$store.state.user.sk_id + '&klok_id=' + this.kelas_lokal_id2)
        .then(response => {
          if (response.data.cek === 1) {
            //console.log('cek data siswa ', response.data.data)
            this.lsData = response.data.data
            this.ftData = response.data.data
          }
        })
    },
    async fnBaru() {
      this.fnSiswaPilKls()
      this.clsForm()
      this.dialog=true
    },
    async fnImpor() {
      this.clsForm()
      this.dialogImpor=true
    },
    async fnEdit (id) {
      const cekIndex = this.lsData.findIndex(cek => cek.prodID === id)
      const dtEdit = this.lsData[cekIndex]
      this.id = dtEdit.prodID
      this.nama = dtEdit.nama
      this.fileGbr = dtEdit.image
      this.fileGbr2 = dtEdit.fgbr
      this.dialog = true
    },
    clsForm () {
      this.nama = ''
      this.harga = ''
      this.fileGbr = ''
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
      this.searchTextsLoading = false
    }, 1000)
     // this.fnKat()
    this.fnSiswaPilKls()
    this.fnKelas()
    this.fnData()
    this.fnMapel()
    this.fnGuru()
    this.fnTA()
    this.fnKelasLokal()
  }
}
</script>

<style lang="scss" scoped></style>
