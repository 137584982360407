<template>
  <div>
    <Navbar />
    <v-container >
      <v-row>
        <v-col cols="12" class="mx-auto">
          <v-row>
            <v-col cols="12" sm="12" md="8" lg="8">
              <v-skeleton-loader
              type="card-avatar, article, actions"
              :loading="videoLoading"
              tile
              large
            >
  <iframe width="100%" height="500" :src="this.activeVideo.youtubeURL"  frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
              <v-card flat class="transparent">
                  <v-card-title class="pl-0 pb-0">
                      {{ this.activeVideo.title }}</v-card-title>
                  <div class="d-flex flex-wrap justify-space-between" id="btns">
                    <v-card-subtitle
                      class="pl-0 pt-0 pb-0 subtitle-1"
                      style="line-height: 2.4em;"
                    >
                      {{ this.activeVideo.views }} views<v-icon>mdi-circle-small</v-icon
                      >{{ this.activeVideo.createdAt }}
                    </v-card-subtitle>
                    <v-card-actions class="pt-0 pl-0 grey--text">
                      <v-btn text @click="addLike"
                        ><v-icon class="pr-2">mdi-thumb-up</v-icon> 
                        {{this.activeVideo.likes}}</v-btn
                      >
                      <v-btn text @click="disLike">
                          <v-icon class="pr-2">mdi-thumb-down</v-icon>
                       {{this.activeVideo.dislikes}}</v-btn>
                      <v-btn text>
                          <v-icon>mdi-share</v-icon> 
                        Share</v-btn>
                      <v-btn text>
                          <v-icon>mdi-playlist-plus</v-icon> 
                        Save</v-btn
                      >
                      <v-menu offsetY>
                        <template v-slot:activator="{ on: menu }">
                            <v-btn text v-on="menu" > ... </v-btn>
                        </template>
                        <v-list>
                          <v-list-item>
                            <v-list-item-icon class="mr-3"
                              ><v-icon>mdi-flag</v-icon></v-list-item-icon
                            >
                            <v-list-item-title>Report</v-list-item-title>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-icon class="mr-3"
                              ><v-icon>mdi-message-text</v-icon></v-list-item-icon
                            >
                            <v-list-item-title>Open transcript</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-card-actions>
                  </div>
                </v-card>
                
                </v-skeleton-loader>
            </v-col>
            <v-col cols="12" sm="12" md="4" lg="4">
              <v-row class="mb-2">
                <v-chip :ripple="false" v-for="(chip, i) in chips" :key="i" class="ma-2">
                {{chip}}
                </v-chip>
            </v-row>
              <div @click="chooseVideo(video)" :key="video.id" v-for="video in lsData" class="thumbnail mb-5" >
                <v-skeleton-loader
                  class="mx-auto"
                  type="list-item-avatar-three-line"
                  :loading="loading"
                  tile
                  large
                >
                <v-hover v-slot:default="{ hover }">
                  <v-card class="card transparent" flat :elevation="hover ? 1 : 0" style="cursor:pointer;">
                    <v-row no-gutters>
                      <v-col class="mx-auto" cols="3" sm="3" md="5" lg="5">
                        <!-- <v-responsive max-height="100%"> -->
                        <v-img
                          class="align-center" 
                          :src="video.gbrYT"
                        >
                        </v-img>
                        <!-- </v-responsive> -->
                      </v-col>
                      <v-col>
                        <div class="ml-0">
                          <v-card-title
                            class="pl-2 pt-0 subtitle-1 font-weight-bold"
                            style="line-height: 1"
                          >
                            {{video.judul}}
                          </v-card-title>

                          <v-card-subtitle
                            class="pl-2 pt-2 pb-0"
                            style="line-height: 1"
                          >
                            {{video.nm_mapel}}<br />
                            {{video.views}} views<v-icon>mdi-circle-small</v-icon>{{video.createdAt}}
                            lalu
                          </v-card-subtitle>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-hover>
                </v-skeleton-loader>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
</div>
</template>

<script>
import Navbar from '@/components/Navbar'
import { api } from '@/components/Apiku'
import axios from 'axios'
let videos = [
  {
    id: 1,
    title: "18-core iMac Pro Review: Not a Trap!",
    thumbnail:
      "https://i.ytimg.com/vi/jn9mHzXJIV0/hqdefault.jpg?sqp=-oaymwEZCNACELwBSFXyq4qpAwsIARUAAIhCGAFwAQ==&rs=AOn4CLAvJvk4k_UNB9nst4pFP-txM1TLZA",
    youtubeURL: "https://www.youtube.com/embed/jn9mHzXJIV0",
    creator: "Marques Brownlee",
    likes: 0,
    dislikes: 0,
    createdAt: '2 years ago',
    subscribers: '100k',
    views: 4526,
    description:
          'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Culpa vel inventore voluptatum reiciendis delectus quibusdam incidunt consequuntur, nostrum aperiam, natus quidem qui corrupti reprehenderit quaerat neque voluptatibus? Ullam, maiores temporibus! Lorem, ipsum dolor sit amet consectetur adipisicing elit. Culpa vel inventore voluptatum reiciendis delectus, Lorem, ipsum dolor sit amet consectetur adipisicing elit. Culpa vel inventore voluptatum reiciendis delectus'
  },
  {
    id: 2,
    title: "Dope Tech: Camera Robots!",
    thumbnail:
      "https://i.ytimg.com/vi/UIwdCN4dV6w/hqdefault.jpg?sqp=-oaymwEZCNACELwBSFXyq4qpAwsIARUAAIhCGAFwAQ==&rs=AOn4CLDhlan32jHSvicGZezDFPjAOdXGUA",
    youtubeURL: "https://www.youtube.com/embed/UIwdCN4dV6w",
    creator: "Marques Brownlee",
    likes: 0,
    dislikes: 0,
    createdAt: '8 months ago',
    subscribers: '100k',
    views: 1598,
    description:
          'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Culpa vel inventore voluptatum reiciendis delectus quibusdam incidunt consequuntur, nostrum aperiam, natus quidem qui corrupti reprehenderit quaerat neque voluptatibus? Ullam, maiores temporibus! Lorem, ipsum dolor sit amet consectetur adipisicing elit. Culpa vel inventore voluptatum reiciendis delectus, Lorem, ipsum dolor sit amet consectetur adipisicing elit. Culpa vel inventore voluptatum reiciendis delectus'
  },
  {
    id: 3,
    title: "Let's Talk About Tesla Roadster 2020!",
    thumbnail:
      "https://i.ytimg.com/vi/ctx4YBEdOxo/hqdefault.jpg?sqp=-oaymwEZCNACELwBSFXyq4qpAwsIARUAAIhCGAFwAQ==&rs=AOn4CLBDnlrC2rVwXamNkicEEbc3Mf4T0w",
    youtubeURL: "https://www.youtube.com/embed/ctx4YBEdOxo",
    creator: "Marques Brownlee",
    likes: 0,
    dislikes: 0,
    createdAt: '6 hours ago',
    subscribers: '100k',
    views: 78,
    description:
          'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Culpa vel inventore voluptatum reiciendis delectus quibusdam incidunt consequuntur, nostrum aperiam, natus quidem qui corrupti reprehenderit quaerat neque voluptatibus? Ullam, maiores temporibus! Lorem, ipsum dolor sit amet consectetur adipisicing elit. Culpa vel inventore voluptatum reiciendis delectus, Lorem, ipsum dolor sit amet consectetur adipisicing elit. Culpa vel inventore voluptatum reiciendis delectus'
  },
  {
    id: 4,
    title: "Talking Tech with Neil deGrasse Tyson!",
    thumbnail:
      "https://i.ytimg.com/vi/pqQrL1K0Z5g/hqdefault.jpg?sqp=-oaymwEZCNACELwBSFXyq4qpAwsIARUAAIhCGAFwAQ==&rs=AOn4CLA5hTiwkz4Tr1w1hSMhPlwtmQeyYw",
    youtubeURL: "https://www.youtube.com/embed/pqQrL1K0Z5g",
    creator: "Marques Brownlee",
    likes: 0,
    dislikes: 0,
    createdAt: '10 hours ago',
    subscribers: '100k',
    views: 50,
    description:
          'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Culpa vel inventore voluptatum reiciendis delectus quibusdam incidunt consequuntur, nostrum aperiam, natus quidem qui corrupti reprehenderit quaerat neque voluptatibus? Ullam, maiores temporibus! Lorem, ipsum dolor sit amet consectetur adipisicing elit. Culpa vel inventore voluptatum reiciendis delectus, Lorem, ipsum dolor sit amet consectetur adipisicing elit. Culpa vel inventore voluptatum reiciendis delectus'
  },
  {
    id: 5,
    title: "The Apple Ecosystem: Explained!",
    thumbnail:
      "https://i.ytimg.com/vi/KB4_WIPE7vo/hqdefault.jpg?sqp=-oaymwEZCNACELwBSFXyq4qpAwsIARUAAIhCGAFwAQ==&rs=AOn4CLCCxXm7aoPShOwON74nhMlGYMUkHw",
    youtubeURL: "https://www.youtube.com/embed/KB4_WIPE7vo",
    creator: "Marques Brownlee",
    likes: 0,
    dislikes: 0,
    createdAt: '2 months ago',
    subscribers: '100k',
    views: 325,
    description:
          'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Culpa vel inventore voluptatum reiciendis delectus quibusdam incidunt consequuntur, nostrum aperiam, natus quidem qui corrupti reprehenderit quaerat neque voluptatibus? Ullam, maiores temporibus! Lorem, ipsum dolor sit amet consectetur adipisicing elit. Culpa vel inventore voluptatum reiciendis delectus, Lorem, ipsum dolor sit amet consectetur adipisicing elit. Culpa vel inventore voluptatum reiciendis delectus'
  }
];

export default {
  name: 'VideoPlayer',
  components:{
    Navbar
  },
  data () {
    
    return {
        videos,
        activeVideo: videos[0],
        loading: true,
        videoLoading: true,
        truncate: true,
        comment: '',
        showCommentBtns: false,
        repliesInput: {},
        chips: ['All', 'Matematika', 'IPA', 'IPS', 'Coding', 'VueJs'],
        lsData:[]
    }
  },
  methods:{
    async fnData () {
      axios
      .get(api + 'lsElearnUsr&merID=2')
      .then(response => {
        console.log('cek data halooooo ', response.data.data[0])
        if (response.data.cek === 1) {
          this.lsData = response.data.data
          const cekIndex = this.lsData.findIndex(cek => cek.yt_id === this.$route.params.id)
          this.activeVideo =  response.data.data[cekIndex]
        }
      })
    },
    chooseVideo(video){
      //SET VIDEO AS ACTIVE VIDEO
      this.activeVideo = video;
      //INCREASE THE VIDEOS VIEWS BY 1
      video.views += 1;
    },
    addLike(){
    this.activeVideo.likes += 1;
    },
    disLike(){
    this.activeVideo.dislikes += 1;
    },
    addLikeComment(){
    this.activeVideo.likes += 1;
    },
    disLikeComment(){
    this.activeVideo.dislikes += 1;
    },
    show(event) {
      if (event.target.innerText === 'SHOW MORE') {
        this.truncate = false
        event.target.innerText = 'SHOW LESS'
      } else {
        this.truncate = true
        event.target.innerText = 'SHOW MORE'
      }
    },
    truncateText(string = '', num) {
      if (string.length <= num) {
        return string
      }
      return string.slice(0, num)
    },
    showReply(id) {
      this.$refs[id][0].classList.toggle('d-none')
    },
    hideReply(id) {
      this.$refs[`form${id}`][0].reset()
      this.$refs['reply' + id][0].classList.toggle('d-none')
    },
    addReply(id) {
      this.$refs[`form${id}`][0].reset()
      console.log(this.$refs[`input${id}`][0].$refs.input.value)
    },
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
      this.videoLoading = false
      this.activeVideo()
    }, 400)
    this.fnData()
    console.log('cek parem ', this.$route.params.id)
  }
}
</script>

<style scoped>

</style>