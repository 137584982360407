<template>
  <div id="history" class="fill-height">
    <Navbar />
    <v-container fluid class="pt-0 pb-0 fill-height">
      <v-row class="fill-height">
        <v-col cols="12" sm="12" class="pt-5 pl-2">
          <h4 class="title">Jadwal Guru Kelas</h4>
          <template>
            <v-snackbar v-model="snackbar" :timeout="timeout">
              {{ text }}
              <template v-slot:action="{ attrs }">
                <v-btn
                  color="blue"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  Close
                </v-btn>
              </template>
            </v-snackbar>
    <v-dialog v-model="konfirmHapus" max-width="400px">
      <v-card>
        <v-card-title>
        <span>Konfirmasi</span>
        </v-card-title>
        <v-card-text>
        <v-container>
          <span>Anda yakin menghapus data ini?</span>
        </v-container>
        </v-card-text>
        <v-card-actions>
        <v-spacer></v-spacer>
          <v-btn color="blue darken-1" @click="konfirmHapus = false">Batal</v-btn>
          <v-btn color="blue darken-1" @click="delData">Ya</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
        <v-row align="center" class="px-5 mx-auto mt-20" v-if="lsStatus">
          <v-col cols="6" md="6">
            <v-select item-text="nm_lokal_kelas" item-value="kelas_lok_id" :items="lsKelasLokal" 
            label="Lokal Kelas" v-model="kelas_lok_id" required  v-on:change="gtLokal()"></v-select>
          </v-col>
          <v-col cols="6" md="6">
            <v-select item-text="nama" item-value="hr_id" :items="lsHari" label="Hari"
                 v-model="fhr_id" required v-on:change="gtHari()"></v-select>
          </v-col>
          <v-col cols="12" sm="12">
            <v-card class="mx-auto" tile>
              <v-data-table
                :headers="headers"
                :items="ftData"
                :disable-pagination="false"
                :hide-default-footer="false"
                :loading="loading"
                loading-text="Loading... Please wait"
                :footer-props="{
                  'items-per-page-options': [20, 30, 40, 50]
                }"
                :items-per-page="30"
              >
      
                <template v-slot:[`item.nm_jam`]="{ item }">
                  {{ item.mulai}} - {{ item.akhir}}
                </template>
                <template v-slot:[`item.jdw_mapel`]="{ item }">
                  {{ item.jdw_mapel}}
                  <v-icon small class="mr-2" @click="fnEdit(item.jam_id)">mdi-pencil </v-icon>
                </template>
                
       
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
        <v-dialog v-model="dialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span>Jadwal Jam Mengajar</span>
          </v-card-title>
          <v-card-text>
            <v-container>
               <v-form ref="form" v-model="valid" lazy-validation >
            <v-row>
            <v-col  cols="12">
              <v-select item-text="nm_guru_mapel" item-value="gmap_id"  :items="lsGMapKelas" label="Guru Mata Pelajaran" v-model="gmap_id" required></v-select>
            </v-col>
             
            </v-row>
            </v-form>
          </v-container>
          <small>*harus diisi</small>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="cyan" @click="dialog = false">Cancel</v-btn>
            <v-btn color="cyan" @click="saveData">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> 


          </template>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import { api } from '@/components/Apiku'
import axios from 'axios'
//import XLSX from 'xlsx';
//import readXlsxFile from 'read-excel-file';
import "vue-wysiwyg/dist/vueWysiwyg.css";

export default {
  data: () => ({
    snackbar: false,
    lsStatus:true, valid :false,
    ar_mapel:[], jam_id:0,hr_id:0,
    text: 'OK',
    timeout: 2000, gmap_id:0,
    kelas_lok_id:0,
    fhr_id:"1",
    loading: true,
    lsData : [],
    ftData : [],lsKelasLokal :[], kelasLokal_id : [], lsHari: [],
    dialog : false,
    searchTextsLoading: true,
    id: '0',
    lokal:'', lsGMapKelas :[],
    guru_id:0, ta_id:0,
    semester_id:0, lsGuru:[],
    lsKelas:[],
    lsTA:[],
    id_hapus:0,konfirmHapus:false,
  // limit content height if you wish. If not set, editor size will grow with content.
  maxHeight: "500px",
  ddSemester:[{"id":"1","label":"Semester 1"},{"id":"2","label":"Semester 2"}],
  headers: [
    { text: "Jam", align: "start", sortable: false, value: "nm_jam" },
    { text: "Mata pelajaran / Guru", align: "start", sortable: false, value: "jdw_mapel" }
  ]
  }),
  components:{
    Navbar
  },
  methods: {
    
    saveData () {
      //if (this.kelas_id === 0) {
        //this.snackbar =true
     // } else {
        //console.log('cek mapel ', this.j_perangkat_id)
        console.log('cek id : ',this.id)
        const dataku = {
          user_id: this.$store.state.user.user_id,
          gmap_id: this.gmap_id,
          hr_id: this.hr_id,
          jam_id: this.jam_id,
          id: this.id, kelas_lok_id :this.kelas_lok_id,
          sk_id:this.$store.state.user.sk_id,
        }
         console.log('cek  awal', dataku)
        axios
          .post(api + 'savegMapelKlsJadwal', dataku)
          .then(response => {
            console.log('cek ', response.data)
            if (response.data.cek === 1) {
              this.text = 'Data tersimpan'
              this.snackbar=true
              
              this.fnData()
              this.dialog = false
              //this.clsForm()
              this.lsStatus = true
            } else {
              console.log(' tidak sukses ')
            }
          })
      //}
    },
    fnKonfirmasi(id){
      this.id_hapus=id
      this.konfirmHapus=true
    },
    delData () {
      const dataku = {
        user_id: this.$store.state.user.user_id,
        id: this.id_hapus
      }
      axios
        .post(api + 'delGPustakas', dataku)
        .then(response => {
          console.log('cek ', response.data)
          if (response.data.cek === 1) {
            this.text = 'Data tersimpan'
            this.snackbar=true
            this.konfirmHapus=false
            this.fnData()
            //this.clsForm()
            
          } else {
            console.log(' tidak sukses ')
          }
        })
    },
    
    async fnKelasLokal() {
      axios
        .get(api + 'lsKelasLokal&sk_id=' + this.$store.state.user.sk_id+'&jenjang_id='+this.$store.state.user.jenjang_id)
        .then(response => {
          console.log('lsKelasLokal', response.data)
          if (response.data.cek === 1) {
            this.lsKelasLokal = response.data.data
          }
        })
    },
    async fnKelas() {
      axios
        .get(api + 'lsKelas&sk_id=' + this.$store.state.user.sk_id+'&jenjang_id='+this.$store.state.user.jenjang_id)
        .then(response => {
          console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.lsKelas = response.data.data
          }
        })
    },
    async fnHari () {
      axios
        .get(api + 'lsHari')
        .then(response => {
          console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.lsHari = response.data.data
          }
        })
    },
     async fnGMapKelas() {
      axios
        .get(api + 'lsGMapKls_per_Kls&klok_id=' + this.kelas_lok_id)
        .then(response => {
          console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.lsGMapKelas = response.data.data
          }
        })
    },
    gtLokal (){
      this.fnData()
      this.fnGMapKelas()
      //const ftDatax = this.lsData.filter(post => post.kelas_lok_id===this.kelas_lok_id)
    },
    gtHari (){
      this.fnData()
      this.fnGMapKelas()
      //const ftDatax = this.lsData.filter(post => post.kelas_lok_id===this.kelas_lok_id)
    },
    async fnMapel () {
      axios
        .get(api + 'ddMapel&merID=2')
        .then(response => {
          if (response.data.cek === 1) {
            this.ddMapel = response.data.data
          }
        })
    },
    async fnTA () {
      axios
        .get(api + 'lsTA&merID=2')
        .then(response => {
          console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.lsTA = response.data.data
          }
        })
    },
    async fnData () {
      axios
        .get(api + 'lsMJadwal&sk_id=' + this.$store.state.user.sk_id + '&kelas_lok_id=' + this.kelas_lok_id + '&hr_id=' + this.fhr_id)
        .then(response => {
          console.log('cek data halooooo ', response.data)
          if (response.data.cek === 1) {
            this.lsData = response.data.data
            this.ftData = response.data.data
            this.loading = false
            //this.tutorials = response.data.data
          }
        })
    },
    async fnBaru() {
      this.id=0
      this.clsForm()
      //this.dialog=true
    },

    async fnEdit (jam) {
      console.log(' cek hari ', this.fhr_id , ' jam ',jam)
      this.hr_id = this.fhr_id
      this.jam_id = jam
      this.id=0
      this.dialog = true
    },
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
      this.searchTextsLoading = false
    }, 2000)
    this.fnKelas()
    this.fnHari()
    this.fnMapel()
    this.fnTA()
    this.fnKelasLokal()
  }
}
</script>

<style lang="scss" scoped></style>
