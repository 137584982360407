<template>
  <div id="history" class="fill-height">
    <Navbar />
    <v-container fluid class="pt-0 pb-0 fill-height">
      <v-row class="fill-height">
        <v-col
          cols="12"
          sm="12"
          order="2"
          order-sm="2"
          order-md="1"
          order-lg="1"
          md="7"
          lg="9"
          class="pt-8 pl-8"
        >
          <h4 class="title">Data Lokal Kelas</h4>
          <template>
            <v-snackbar
              v-model="snackbar"
              :timeout="timeout"
            >
              {{ text }}

              <template v-slot:action="{ attrs }">
                <v-btn
                  color="blue"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  Close
                </v-btn>
              </template>
            </v-snackbar>
            <v-dialog v-model="dialog" max-width="700px">
              <v-card>
                <v-card-title>
                  <span>Data Lokal Kelas</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                  <v-form ref="form" v-model="valid" lazy-validation>
                  <v-row>
                    <v-col  cols="12" sm="6" md="6">
                      <v-select item-text="nm_kelas" item-value="kelas_id" :items="lsKelas" label="Kelas" v-model="kelas_id" required></v-select>
                    </v-col>
                    <v-col  cols="12" sm="6" md="6">
                      <v-select item-text="nama" item-value="guru_id" :items="lsGuru" label="Wali Kelas" v-model="guru_id" required></v-select>
                    </v-col>
                    <v-col  cols="12" sm="6" md="6">
                      <v-select item-text="nm_ta" item-value="ta_id" :items="lsTA" label="Tahun Ajaran" v-model="ta_id" required></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-text-field label="Lokal Kelas *" v-model="lokal" :rules="nameRules"></v-text-field>
                    </v-col>
                  </v-row>
                  </v-form>
                </v-container>
                <small>*harus diisi</small>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="cyan" @click="dialog = false">Cancel</v-btn>
                  <v-btn color="cyan" @click="saveData">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="konfirmHapus" max-width="400px">
            <v-card>
              <v-card-title>
              <span>Konfirmasi</span>
              </v-card-title>
              <v-card-text>
              <v-container>
                <span>Anda yakin menghapus data ini?</span>
              </v-container>
              </v-card-text>

              <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn color="blue darken-1" @click="konfirmHapus = false">Batal</v-btn>
                <v-btn color="blue darken-1" @click="delData">Ya</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

             <v-row class="list px-0 mx-auto">
                <v-col cols="12" md="6">
                <v-text-field v-model="text_cari" label="cari wali kelas..." v-on:keyup="inputKeydown">></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                 <v-btn color="cyan" class="mb-0"  @click="fnBaru" small>+ Data</v-btn>
              </v-col>

              <v-col cols="12" sm="12">
                <v-card class="mx-auto" tile>
                  <v-data-table
                    :headers="headers"
                    :items="ftData"
                    :disable-pagination="false"
                    :hide-default-footer="false"
                    :loading="loading"
                    loading-text="Loading... Please wait"
                    :footer-props="{
                      'items-per-page-options': [30, 40, 50]
                    }"
                    :items-per-page="30"
                  >
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-icon small class="mr-2" @click="fnEdit(item.kelas_lok_id)">mdi-pencil </v-icon>
                      <v-icon small @click="fnKonfirmasi(item.kelas_lok_id)">mdi-delete</v-icon>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          order="1"
          order-sm="1"
          order-md="2"
          order-lg="2"   md="5"
          lg="3" class="pt-8 pl-8"
        >
          <v-row>
            <v-col  cols="12">
              <v-select item-text="nm_ta" item-value="ta_id" :items="lsTA" label="Tahun Ajaran" 
              v-model="ta_id2" required v-on:change="gtLokal()"></v-select>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import { api } from '@/components/Apiku'
import axios from 'axios'

export default {
  data: () => ({
    snackbar: false,
    valid: false,
    nameRules: [
      v => !!v || 'Wajib diisi',
      v => (v && v.length >= 2) || 'Data salah',
    ],
    text: 'OK',
    timeout: 2000, text_cari:'',
    title:'', ta_id2:0,
    loading: true, konfirmHapus:false,
    lsData : [],
    ftData : [],
    dialog : false,
    menuKat : false,
    dialogImpor:false,
    searchTextsLoading: true,
    judul: '',
    kompetensi: '',
    yt_id: '',
    id: 0,
    lokal:'',
    ddMapel : [],
    dtImpor:[],
    files: null,
    mapel:'',
    kelas_id:1,
    guru_id:0,
    ta_id:0,
    lsGuru:[],
    lsKelas:[],
    lsTA:[],
    ddSemester:[{"id":"1","label":"Semester 1"},{"id":"2","label":"Semester 2"}],
    headers: [
      { text: "Kelas", align: "start", sortable: false, value: "nm_kelas" },
      { text: "Lokal", value: "lokal", sortable: false },
      { text: "Wali Kelas", value: "nm_guru", sortable: false },
      { text: "hp", value: "hp_guru", sortable: false },
      { text: "Aksi", value: "actions", sortable: false },
    ]
  }),
  components:{
    Navbar
  },
  methods: {
    validate () {
      this.$refs.form.validate()
    },
    inputKeydown: function () {
      if(this.text_cari.length>0){
        this.ftData = this.lsData.filter(m => m.nm_guru.toUpperCase().includes(this.text_cari.toUpperCase()))
      }else{
        this.ftData = this.lsData
      }
    },
    fnKonfirmasi(id){
      this.id=id
      this.konfirmHapus=true
    },
    delData () {
      const dataku = {
        user_id: this.$store.state.user.user_id,
        id: this.id
      }
      axios
        .post(api + 'delLokalKelas', dataku)
        .then(response => {
          console.log('cek ', response.data)
          if (response.data.cek === 1) {
            this.text = 'Data tersimpan'
            this.snackbar=true
            this.konfirmHapus=false
            this.fnData()         
          } else {
            //console.log(' tidak sukses ')
          }
        })
    },
    saveData () {
      this.validate()
      //console.log('cek valid', this.valid, ' cek ', this.ugID)
      if (this.valid===false || this.lokal === '') {
        this.text = 'Data salah atau tidak lengkap'
        this.snackbar =true
      } else {
        const dataku = {
          userID: 1,
          kelas_id: this.kelas_id,
          lokal: this.lokal,
          guru_id: this.guru_id,
          ta_id: this.ta_id,
          sk_id:this.$store.state.user.sk_id,
          id:this.id
        }
        //console.log('cek mapel ', dataku)
        axios
          .post(api + 'saveLokalKelas', dataku)
          .then(response => {
            console.log('cek ', response.data)
            if (response.data.cek === 1) {
              this.text = 'Data tersimpan'
              this.snackbar=true
              this.dialog = false
              this.fnData()
              this.clsForm()
            } else {
              //console.log(' tidak sukses ')
            }
          })
      }
    },
    async fnKelas() {
      axios
        .get(api + 'lsKelas&jenjang_id=' + this.$store.state.user.jenjang_id)
        .then(response => {
          //console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.lsKelas = response.data.data
          }
        })
    },
    async fnGuru() {
      axios
        .get(api + 'lsGuru&sk_id=' + this.$store.state.user.sk_id)
        .then(response => {
          //console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.lsGuru = response.data.data
          }
        })
    },
    async fnTA () {
      axios
        .get(api + 'lsTA&merID=2')
        .then(response => {
          //console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.lsTA = response.data.data
          }
        })
    },
    gtLokal (){
      this.fnData()
    },
    async fnData () {
      this.lsData = []
      this.ftData = []
      axios
        .get(api + 'lsKelasLokalAdm&sk_id=' + this.$store.state.user.sk_id  + '&ta_id=' + this.ta_id2 )
        .then(response => {
          console.log('cek data halooooo ', 'lsKelasLokalAdm&sk_id=' + this.$store.state.user.sk_id  + '&ta_id=' + this.ta_id2)
          if (response.data.cek === 1) {
            this.lsData = response.data.data
            this.ftData = response.data.data
          }
        })
    },
    async fnBaru() {
      this.clsForm()
      this.dialog=true
    },
    async fnEdit (id) {
      const cekIndex = this.lsData.findIndex(cek => cek.kelas_lok_id === id)
      const dtEdit = this.lsData[cekIndex]
      this.id = id
      this.lokal = dtEdit.lokal
      this.kelas_id = dtEdit.kelas_id
      this.guru_id = dtEdit.guru_id 
      this.ta_id = dtEdit.ta_id
      this.dialog = true
    },
    clsForm () {
      this.lokal = ''
      this.id = 0
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
      this.searchTextsLoading = false
    }, 1000)
    this.fnKelas()
    this.fnGuru()
    this.fnTA()
    this.ta_id = this.$store.state.user.ta_id
    this.ta_id2 = this.$store.state.user.ta_id
    this.fnData()
  }
}
</script>

<style lang="scss" scoped></style>
