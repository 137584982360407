<template>
  <div id="history" class="fill-height">
    <Navbar />
    <v-container fluid class="pt-0 pb-0 fill-height">
      <v-row class="fill-height">
        <v-col
          cols="12"
          sm="12"
          order="2"
          order-sm="2"
          order-md="1"
          order-lg="1"
          md="12"
          lg="12"
          class="pt-8 pl-8"
        >
          <h4 class="title font-weight-medium pb-5">Laporan Absensi Siswa</h4>
          <template>
            <v-snackbar
              v-model="snackbar"
              :timeout="timeout"
            >
              {{ text }}

              <template v-slot:action="{ attrs }">
                <v-btn
                  color="blue"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  Close
                </v-btn>
              </template>
            </v-snackbar>
        <template>
        </template>
        <v-row align="center" class="list px-0 mx-auto">
          <v-col cols="12">
            <v-radio-group v-model="jenis_absensi">
              <v-radio
                label="Absensi Masuk Sekolah" value="1"
              >
              </v-radio>
              <v-radio
                label="Absensi Kelas / Mata Pelajaran" value="2"
              ></v-radio>
              </v-radio-group>
          </v-col>    
          <v-col  cols="3" sm="1" md="1">
            <v-select item-text="nm_kelas" item-value="kelas_id" :items="lsKelas" label="Kelas" v-model="kelas_id" 
            required v-on:change="gtKelas()"></v-select>
          </v-col>
          <v-col  cols="6" sm="3" md="3">
            <v-select item-text="nm_lokal_kelas" item-value="kelas_lok_id" :items="ftKelasLokal" label="Lokal"
              v-model="kelas_lok_id" v-on:change="fnJadwalMapel()" required></v-select>
          </v-col>
            <v-col  cols="4" sm="3" md="3">
              <input type="date" v-model="tgl1" v-on:change="fnJadwalMapel()" format="DD/MM/YYYY"  placeholder="Tanggal">
            </v-col>
          <v-col  cols="6" sm="6" md="6" v-if="jenis_absensi=='2'">
            <v-select item-value="jdw_id" :items="lsJadwalMapel" label="Mata Pelajaran"
              v-model="jdw_id" required> 
              <template v-slot:[`selection`]="{ item }">
                {{ item.nm_mapel }} -
                {{ item.nm_hari }} {{ item.ket_jam }} 
              </template>
              <template v-slot:[`item`]="{ item }">
                {{ item.nm_mapel }} -
                {{ item.nm_hari }} {{ item.ket_jam }} 
              </template>  
            </v-select>
          </v-col>
          <v-col  cols="12" sm="2" md="2">
            <v-btn small @click="fnData">Cari..</v-btn>
            <v-btn small @click="fnExcel()">Excel</v-btn>
          </v-col>
          <v-col cols="12" sm="12">
            <v-card class="mx-auto" tile id="print">
              <v-data-table
                :headers="headers"
                :items="ftData"
                disable-pagination
                :hide-default-footer="true"
              >
                
                <template v-slot:[`item.jenis_absen`]="{ item }">
                  <span :style="'color:'+(item.kode=='H' ? 'green' : (item.kode=='A' ? 'red' : 'yellow'))">
                    {{item.jenis_absen}}
                  </span>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small class="mr-2" @click="fnEdit(item.prodID)">mdi-pencil </v-icon>
                  <v-icon small @click="deleteTutorial(item.prodID)">mdi-delete</v-icon>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import { api } from '@/components/Apiku'
import axios from 'axios'
import XLSX from 'xlsx';
export default {
  data: () => ({
    snackbar: false,
    tgl1:new Date(),
    menu1: false,
    jenis_absensi: "1",
    menu2: false, kelas_lok_id:null, jdw_id:null,
    text: 'OK', lsth: null,
    timeout: 2000, bulan_id:1,th:null,
    title:'',
    loading: true, kelas_id:null, kelasLokal_id:null,
    lsData : [], lsKelas:[], ftKelasLokal:[],lsJadwalMapel:[],
    ftData : [], lsKelasLokal:[],
    dialog : false,
    menuKat : false,
    searchTextsLoading: true,
    tutorials: [],
    headers: [
      { text: "Tanggal", align: "start", sortable: false, value: "tglvw",width:"100px" },
      { text: "Nama", align: "start", sortable: false, value: "nama",width:"200px" },
      { text: "Jenis Absen", align: "start", width:50,sortable: false, value: "jenis_absen" },
      { text: "Jam Absen", align: "start", width:50,sortable: false, value: "jam_absen" },
      { text: "Lokasi", align: "start", sortable: false, value: "lok", width:"200px" },
      { text: "Keterangan", align: "start", sortable: false, value: "ket" },
    ],
    lsBulan: [
      { id: 1, nmBulan: "Januari"},
      { id: 2, nmBulan: "Februari"},
      { id: 3, nmBulan: "Maret"},
      { id: 4, nmBulan: "April"},
      { id: 5, nmBulan: "Mei"}, { id: 6, nmBulan: "Juni"},{ id: 7, nmBulan: "Juli"},
      { id: 8, nmBulan: "Agustus"},{ id: 9, nmBulan: "September"},{ id: 10, nmBulan: "Oktober"},
      { id: 11, nmBulan: "NoVember"},{ id: 12, nmBulan: "Desember"},
    ],
  }),
  components:{
    Navbar
  },
  methods: {
    async fnKelas() {
      axios
      .get(api + 'lsKelas&sk_id=' + this.$store.state.user.sk_id+'&jenjang_id='+this.$store.state.user.jenjang_id)
      .then(response => {
        if (response.data.cek === 1) {
          this.lsKelas = response.data.data
        }
      })
    }, 
    async fnTH() {
      axios
      .get(api + 'lsth')
      .then(response => {
        if (response.data.cek === 1) {
          this.lsth = response.data.data
        }
      })
    },
    async fnJadwalMapel () {
      this.lsJadwalMapel = [];
      axios
        .get(api + 'lsGJadwal&kelas_lok_id=' + this.kelas_lok_id + '&tanggal=' + this.tgl1)
        .then(response => {
          if (response.data.cek === 1) {
            this.lsJadwalMapel = response.data.data
          }
        })
    },
    gtKelas(){
      this.kelasLokal_id=[]
      this.ftKelasLokal = this.lsKelasLokal.filter(m => m.kelas_id.includes(this.kelas_id))
    },
    fnExcel() {
      const dataExcel = []
      let judul = [
        'Laporan Data Absensi Siswa'
      ]
      dataExcel.push(judul)
      judul = [
        this.$store.state.user.nmSekolah
      ]
      dataExcel.push(judul)

      judul = [
        'Tanggal : '  + this.tgl1
      ]
      dataExcel.push(judul)

      let dataku = [
        'No',
        'Tanggal',
        'Nama Guru',
        'Jenis Absen',
        'Jam Absen',
        'Lokasi',
        'Keterangan'
      ]
      dataExcel.push(dataku)

      let i=1
      this.ftData.forEach((value ) => {
        const dataku = [
          i,value['tglvw'],value['nama'],
          value['jenis_absen'],value['jam_absen'],
          value['lok'],value['keterangan']
        ]
        dataExcel.push(dataku)
        i++
      })
      var wscols = [
        {wch:10},
        {wch:30},
        //{wch:15},
        //{wch:20},
        //{wch:20},
        //{wch:20}
      ];
      const data = XLSX.utils.json_to_sheet(dataExcel)
      const wb = XLSX.utils.book_new()
      data["!cols"] = wscols
      const huruf = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']
      for(let i of huruf){
        //const datax ={ text: ""+i+"", value: ""+i+"", sortable: false };
        let cek = i+'1'
        data[cek]=''
        //dataku.push(i);
      }
      XLSX.utils.book_append_sheet(wb, data, 'data')
      XLSX.writeFile(wb,'absensi_siswa.xlsx')
    }, 
    async fnData () {
      let functionname = this.jenis_absensi=='1' ? 'lapAbsenSiswaLok' : 'lapAbsenSiswaLokMapel&jdw_id='+this.jdw_id;
      axios
      .get(api + functionname + '&kelas_lok_id=' + this.kelas_lok_id  + '&tgl=' + this.tgl1)
      .then(response => {
        console.log('cek tgl ',  this.tgl1)
        console.log('cek data absen ', response.data)
        if (response.data.cek === 1) {
          this.lsData = response.data.data
          this.ftData = response.data.data
        }else{
          this.lsData = []
          this.ftData = []
        }
      })
    },
    clsForm () {
      this.nama = ''
    },
    async fnKelasLokal() {
      axios
      .get(api + 'lsKelasLokal&sk_id=' + this.$store.state.user.sk_id+'&jenjang_id='+this.$store.state.user.jenjang_id)
      .then(response => {
        if (response.data.cek === 1) {
          this.lsKelasLokal = response.data.data
        }
      })
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
      this.searchTextsLoading = false
    }, 2000)
    this.fnKelas()
    this.fnKelasLokal()
  }
}
</script>

<style lang="scss" scoped></style>
