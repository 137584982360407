import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Channel from '../components/Channel'
import VideoPlay from '../components/VideoPlay'
import History from '../components/History'
import Produk from '../components/Produk'
import Produk2 from '../components/Produk2'
import Lapjual from '../components/Lapjual'
import Menu from '../components/Menu'
import Meja from '../components/Meja'
import Pelanggan from '../components/Pelanggan'
import Login from '../components/Login'
import Login_sup from '../components/Login_sup'
import User from '../components/User'
import Profil from '../components/Profil'
import Daftar from '../components/Daftar'
import Elearn from '../components/E-learn'
import Profil_sekolah from '../components/Profil_sekolah'
import Ujian from '../components/Ujian'
import Excel from '../components/Excel'
import Excel2 from '../components/Excel2'
import M_guru from '../components/M_guru'
import M_mod from '../components/M_mox'
import Lokal_kelas from '../components/Lokal_kelas'
import Guru_mapel from '../components/Guru_mapel'
import Siswa_kelas from '../components/Siswa_kelas'
import Guru_materi from '../components/Guru_materi'
import Guru_perangkat from '../components/Guru_perangkat'
import Guru_ujian from '../components/Guru_ujian'
import Guru_tugas from '../components/Guru_tugas'
import Guru_pustaka from '../components/Guru_pustaka'
import Guru_info from '../components/Guru_info'
import Guru_jadwal from '../components/Guru_jadwal'
import Guru_kol from '../components/Guru_kol'
import G_mapel_kls from '../components/G_mapel_kls'
import M_ujian from '../components/M_ujian'
import A_guru_jadwal from '../components/A_guru_jadwal'
import M_jam from '../components/M_jam'
import M_siswa from '../components/M_siswa'
import M_mapel from '../components/M_mapel'
import View from '../components/View'
import G_gsheet from '../components/Guru_gsheet'
import A_sekolah from '../components/A_sekolah'
import Lap_absen_siswa from '../components/Lap_absen_siswa'
import Lap_absen_siswa2 from '../components/Lap_absen_siswa2'
import Lap_absen_guru from '../components/Lap_absen_guru'
import Lap_absen_guru2 from '../components/Lap_absen_guru2'
import A_Guru_perangkat from '../components/A_Guru_perangkat'
Vue.use(VueRouter)
const routes = [
  { path: '/', name: 'Home', component: Home, meta: {requiresAuth : true}, }, 
  {path: '/a/rapor', name: 'a_rapor', component: () => import('../components/lap/Rapor.vue')},
  { path: '/s/akses', name: 'sakes', component: () => import('../components/sa/akses')  },
  { path: '/s_absen', name: 's_absen', component: () => import('../components/sis_absen')  },
  { path: '/s_kelas_absen', name: 's_kelas_absen', component: () => import('../components/sis_kelas_absen')  },
  { path: '/s/mod', name: 'sa_mod', component: () => import('../components/sa/mod')  },

  { path: '/g/ujian', name: 'ujian2', component: () => import('../components/guru/ujian2')  },
  { path: '/g/ujian2', name: 'ujian2b', component: () => import('../components/Guru_ujian2')  },
  { path: '/g/lat', name: 'lat23', component: () => import('../components/guru/lat')  },
  { path: '/g/lat2', name: 'lat234', component: () => import('../components/guru/lat2')  },
  
  { path: '/a/gperangkat', name: 'a_perangkatsekolah', component: A_Guru_perangkat  },
  { path: '/a/absen_siswa', name: 'Lap_absen_siswa2', component: Lap_absen_siswa2  },
  { path: '/a/rek_absen_siswa', name: 'Lap_absen_siswa', component: Lap_absen_siswa  },
  { path: '/a/absen_guru', name: 'Lap_absen_guru', component: Lap_absen_guru2  },
  { path: '/a/rek_absen_guru', name: 'Lap_absen_guru', component: Lap_absen_guru  },
  { path: '/a/set_jam', name: 'a_set_jam', component: () => import('../components/admin/set_jam.vue')},
  { path: '/a/sekolah', name: 'A_sekolah', component: A_sekolah  },
  { path: '/g_gsheet', name: 'G_gsheet', component: G_gsheet  },
  
  { path: '/m_siswa', name: 'm_siswa', component: M_siswa  },
  { path: '/m_jam', name: 'm_jam', component: M_jam  },
  { path: '/agr_jadwal', name: 'agr_jadwal', component: A_guru_jadwal  },
  { path: '/profil', name: 'profil', component: Profil  },
  { path: '/profilsekolah', name: 'profilsekolah', component: Profil_sekolah },
  {
    path: '/play',
    name: 'play',
    component: VideoPlay
  },
  {
    path: '/excel',
    name: 'excel',
    component: Excel
  },
  { path: '/g_mapel_kls', name: 'g_mapel_kls', component: G_mapel_kls},
  { path: '/g_kol', name: 'g_kol', component: Guru_kol},
  { path: '/g_jadwal', name: 'g_jadwal', component: Guru_jadwal},
  { path: '/g_info', name: 'g_info', component: Guru_info},
  { path: '/g_pustaka', name: 'g_pustaka', component: Guru_pustaka},
  { path: '/g_tugas', name: 'g_tugas', component: Guru_tugas},
  { path: '/g_perangkat', name: 'g_perangkat', component: Guru_perangkat},
  { path: '/v/:id', name: 'vie_id', component: View},
  { path: '/excel2', name: 'excel2', component: Excel2},
  { path: '/mod', name: 'mod', component: M_mod},
  { path: '/lokal_kelas', name: 'lokal_kelas', component: Lokal_kelas},
  { path: '/guru_mapel', name: 'guru_mapel', component: Guru_mapel},
  { path: '/siswa_kelas', name: 'siswa_kelas', component: Siswa_kelas},
  { path: '/g_materi', name: 'g_materi', component: Guru_materi},
  { path: '/m_ujian', name: 'm_ujian', component: M_ujian},
  { path: '/g_ujian', name: 'g_ujian', component: Guru_ujian},
  { path: '/m_guru', name: 'm_guru',  component: M_guru},
  { path: '/m_mapel', name: 'm_mapel',  component: M_mapel},
  {
    path: '/daftar',
    name: 'daftar',
    component: Daftar
  },
  {path: '/login', name: 'login', component: Login},
  {path: '/loginsup', name: 'loginsup', component: Login_sup},
  {
    path: '/produk',
    name: 'produk',
    component: Produk
  },
  {
    path: '/kasir',
    name: 'kasir',
    component: Menu
  },
  {
    path: '/ujian',
    name: 'ujian',
    component: Ujian
  },
  {
    path: '/pelanggan',
    name: 'pelanggan',
    component: Pelanggan
  },
  {
    path: '/meja',
    name: 'meja',
    component: Meja
  },
  {
    path: '/user',
    name: 'user',
    component: User
  },
  {
    path: '/lapjual',
    name: 'lapjual',
    component: Lapjual
  },
  {
    path: '/produk2',
    name: 'produk2',
    component: Produk2
  },
  {
    path: '/channel',
    name: 'Channel',
    component: Channel
  },
  {
    path: '/history',
    name: 'History',
    component: History
  },
  {
    path: '/e-learn',
    name: 'E-learning',
    component: Elearn
  },
  {path: '/about', name: 'About', component: () => import('../views/About.vue')},
  {path: '*', name: 'Homex', component: Home, meta: {requiresAuth : true}, }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const isAuth= JSON.parse(localStorage.getItem('Usr-jidismart'))
router.beforeEach((to, from, next) => {
  const publicPages = ['/login','/loginsup']
  const authRequired = !publicPages.includes(to.path)
  if (authRequired && !isAuth) {
    return next('/login')
  }
  next()
})

// const cek2 = false
//router.beforeEach((to,from,next) => {
  //console.log('cek data ', cek2)
  //if(cek2===0){
    //next()
  //}else{
    //next('/login')
 // }
  //if(to.matched.some(route => route.meta.requiresAuth)){
    //if(cek2) return next('/login')

    //next('login');
  //}else{
    //next()
  //}

  //next();
//});

//router.beforeEach((to, from, next) => {
  //to('/login')
  //next("http://localhost:8080/login")
  //console.log('cek to ', to)
  //if (to.name !== 'Login') next({ name: 'Login' })
  //else next()
  //if (localStorage.getItem('Usr-kasirr') === null) {
 // if (isOK !== 0) {
   // console.log('cel')
    //return next({ name: 'login' })
    //return next('login')
   // next()
      //return
    
    //next({ name: 'produk' })

    //console.log(' ka belum logn')
    //next({ name: 'login' })
    //next()
 //   //next({ name: 'login' })
  //} else {
    //to('/login');
  //  return '/loginsds'
    //next('/login')
    //next()
    //next({ name: 'login' })
    //console.log(' sudh slogn')
   // this.$router.push({ name: 'login', query: { redirect: '/login' } });
    //console.log('cek ', this.$router)
    //next({
       // path: '/login'
        // Redirect to original path if specified
   // })
    //next()

  //}
  //next()
//})


export default router
