<template>
  <div id="history" class="fill-height">
    <Navbar />
    <v-container fluid class="pt-0 pb-0 fill-height">
      <v-row class="fill-height">
        <v-col cols="12" sm="12" class="pt-2 pl-2">
          <h4 class="title">Data Jam Sekolah</h4>
          <template>
            <v-snackbar
              v-model="snackbar"
              :timeout="timeout"
            > {{ text }}
              <template v-slot:action="{ attrs }">
                <v-btn
                  color="blue"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  Close
                </v-btn>
              </template>
            </v-snackbar>
             <v-row align="center" class="list px-0 mx-auto">
              <v-col cols="12" md="6">
                 <v-btn color="cyan" class="mb-0"  @click="fnBaru" small>+ Data</v-btn>
              </v-col>
              <v-col cols="12" sm="12">
                <v-card class="mx-auto" tile>
                  <v-data-table
                    :headers="headers"
                    :items="lsData"
                    :disable-pagination="false"
                    :hide-default-footer="false"
                    :loading="loading"
                    loading-text="Loading... Please wait"
                  >
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-icon small class="mr-2" @click="fnEdit(item.jam_id)">mdi-pencil </v-icon>
                      <v-icon small @click="fnKonfirmasi(item.jam_id)">mdi-delete</v-icon>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-col>
      <v-dialog v-model="dialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span>Data Jam</span>
          </v-card-title>
          <v-card-text>
            <v-container>
               <v-form ref="form" v-model="valid" lazy-validation >
            <v-row>
            <v-col cols="12">
              <v-select item-text="nama" item-value="hr_id" :items="lsHari" label="Hari"
                 v-model="hr_id" required></v-select>
            </v-col>
            <v-col cols="12">
              <v-text-field label="Jam *" v-model="nm_jam" :rules="nameRules" required></v-text-field>
            </v-col>
            <v-col cols="12">
              Mulai
              <input type="time" v-model="mulai" format="hh:mm"  placeholder="Mulai">
            </v-col>
           <v-col cols="12">
            S / d
            <input type="time" v-model="akhir" format="hh:mm"  placeholder="Akhir">
          </v-col>
              
            </v-row>
            </v-form>
          </v-container>
          <small>*harus diisi</small>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="cyan" @click="dialog = false">Cancel</v-btn>
            <v-btn color="cyan" @click="saveData">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> 

      <v-dialog v-model="konfirmHapus" max-width="400px">
      <v-card>
        <v-card-title>
        <span>Konfirmasi</span>
        </v-card-title>
        <v-card-text>
        <v-container>
          <span>Anda yakin menghapus data ini?</span>
        </v-container>
        </v-card-text>

        <v-card-actions>
        <v-spacer></v-spacer>
          <v-btn color="blue darken-1" @click="konfirmHapus = false">Batal</v-btn>
          <v-btn color="blue darken-1" @click="delData">Ya</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import { api } from '@/components/Apiku'
import axios from 'axios'

export default {
  data: () => ({
    valid: false,
      name: '',
      nameRules: [
        v => !!v || 'Wajib diisi',
        v => (v && v.length >= 0) || 'Data salah',
      ],
      emailRules: [
        v => !!v || 'E-mail wajib diisi',
        v => /.+@.+\..+/.test(v) || 'E-mail harus valid',
      ],
    snackbar: false,
    text: 'OK', hr_id: 1, nm_jam :'',mulai:'',akhir:'',
    timeout: 2000,
    title:'',nama:'',nip:'',email:'',alamat:'',pwd:'',hp:'',
    loading: true, konfirmHapus:false,
    lsData : [], lsHari : [],
    ftData : [],
    dialog : false,
    searchTextsLoading: true,
    tutorials: [],
    id: 0,
    headers: [
        { text: "Hari", align: "start", sortable: false, value: "nama_hari" },
        { text: "Jam", align: "start", sortable: false, value: "nm_jam" },
        { text: "Mulai", value: "mulai", sortable: false },
        { text: "Akhir", value: "akhir", sortable: false },
        { text: "Aksi", value: "actions", sortable: false },
      ],
  }),
  components:{
    Navbar
  },
  methods: {
    validate () {
      this.$refs.form.validate()
    },
    fnKonfirmasi(id){
      this.id=id
      this.konfirmHapus=true
    },
    delData () {
      const dataku = {
        user_id: this.$store.state.user.user_id,
        id: this.id
      }
      console.log('cek id ', dataku)
      axios
        .post(api + 'delMJam', dataku)
        .then(response => {
          console.log('cek ', response.data)
          if (response.data.cek === 1) {
            this.text = 'Data tersimpan'
            this.snackbar=true
            this.konfirmHapus=false
            this.fnData()
            //this.clsForm()
            
          } else {
            console.log(' tidak sukses ')
          }
        })
    },
    saveData () {
      this.validate()
      console.log('cek valid', this.valid)
      if (this.valid===false) {
        this.text = 'Data tidak lengkap.'
        this.snackbar =true
      } else {
        console.log('cek mapel ', this.mapel)
        const dataku = {
          hr_id: this.hr_id,
          nm_jam: this.nm_jam,
          mulai: this.mulai,
          akhir: this.akhir,
          email: this.email,
          id:this.id,
          user_id: this.$store.state.user.user_id,
          sk_id:this.$store.state.user.sk_id,
        }
        axios
          .post(api + 'saveMJam', dataku)
          .then(response => {
            console.log('cek ', response.data)
            if (response.data.cek === 1) {
              this.text = 'Data tersimpan'
              this.snackbar=true
              this.dialog = false
              this.fnData()
              this.clsForm()
              
            } else {
              this.text = 'Data gagal! email sudah dipakai.'
              this.snackbar=true
              //console.log(' tidak sukses ')
            }
          })
      }
    },
    async fnHari () {
      axios
        .get(api + 'lsHari')
        .then(response => {
          console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.lsHari = response.data.data
          }
        })
    },
    
    async fnData () {
      axios
        .get(api + 'lsJam&sk_id=' + this.$store.state.user.sk_id,)
        .then(response => {
          console.log('cek data halooooo ', response.data)
          if (response.data.cek === 1) {
            this.lsData = response.data.data
            this.ftData = response.data.data
            //this.tutorials = response.data.data
          }
        })
    },
    async fnBaru() {
      this.clsForm()
      this.dialog=true
    },
    async fnEdit (id) {
      const cekIndex = this.lsData.findIndex(cek => cek.jam_id === id)
      console.log(' cek data ', cekIndex, 'data : ', this.lsData[cekIndex])
      const dtEdit = this.lsData[cekIndex]
      this.id = id
      this.hr_id = dtEdit.hr_id
      this.nm_jam = dtEdit.nm_jam
      this.mulai = dtEdit.mulai
      this.akhir = dtEdit.akhir
      this.dialog = true
    },
    clsForm () {
      this.hr_id = 1
      this.nm_jam = ''
      this.mulai = ''
      this.akhir = ''
      this.id = 0
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
      this.searchTextsLoading = false
    }, 1000)
    this.fnHari()
    this.fnData()
    //this.fnMapel()
  }
}
</script>

<style lang="scss" scoped></style>
