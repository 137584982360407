<template>
  <div id="history" class="fill-height">
    <Navbar />
    <v-container fluid class="pt-0 pb-0 fill-height">
      <v-row class="fill-height">
        <v-col cols="12" sm="12" class="pt-2 pl-2">
          <h4 class="title">Data Siswa</h4>
          <template>
            <v-snackbar
              v-model="snackbar"
              :timeout="timeout"
            >
              {{ text }}
              <template v-slot:action="{ attrs }">
                <v-btn
                  color="blue"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  Close
                </v-btn>
              </template>
            </v-snackbar>
      <v-dialog v-model="dialogImpor" max-width="700px">
        <v-card>
          <v-card-title>
            <span>Impor Data</span>
          </v-card-title>
          <v-card-text>
            <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                 <input type="file" @change="onFileChange" />
            <input type="file" class="my_input" @change="importExcel()" id="upload" />Import
              </v-col>
            </v-row>
          </v-container>
          <small>*harus diisi</small>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="cyan" @click="dialogImpor = false">Cancel</v-btn>
            <v-btn color="cyan" @click="saveImpor">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
   
             <v-row class="list px-0 mx-auto">
              <v-col cols="12" md="6">
                <v-text-field v-model="text_cari" label="cari..." v-on:keyup="inputKeydown">></v-text-field>
              </v-col>
              <v-col  cols="12" sm="6" md="6">
                <v-select item-text="nm_ta" item-value="ta_id" :items="lsTA" label="Tahun Ajaran"
                 v-model="Fta_id" required v-on:change="gtTA()"></v-select>
              </v-col>
              <v-col  cols="12" sm="6" md="6">
                <v-select item-text="nm_lokal_kelas" item-value="kelas_lok_id" :items="ftKelasLokal" label="Lokal Kelas"
                  v-model="kelas_lokal_id2" required v-on:change="gtLokal()"></v-select>
              </v-col>
              <v-col cols="12" md="6">
                 <v-btn color="cyan" class="mb-0" @click="fnBaru" small>+ Data</v-btn>
                 <v-btn color="green" class="mb-0" @click="fnExcel" small><v-icon>mdi-excel</v-icon>Excel</v-btn>
                  <v-btn color="red" class="mb-0" @click="konfirmHapusAll= true" small v-if="selected.length>0">
                    <v-icon>mdi-delete</v-icon>Hapus
                  </v-btn>
              </v-col>

              <v-col cols="12" sm="12">
                <v-card class="mx-auto" tile>
                  <v-data-table
                    :headers="headers"
                    :items="ftData"
                    :disable-pagination="false"
                    :hide-default-footer="false"
                    :footer-props="{
                      'items-per-page-options': [40, 50, 60]
                    }"
                    :items-per-page="40"
                    show-select
                    item-key="sis_id"
                    v-model="selected"
                  >
                   <template v-slot:[`item.fotox`]="{ item }">
                    <v-img :src="item.f_foto_siswa" height="100px" width="100px"></v-img>
                  </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-icon small class="mr-2" @click="fnEdit(item.sis_id)">mdi-pencil </v-icon>
                      <v-icon small @click="fnKonfirmasi(item.sis_id)">mdi-delete</v-icon>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-col>

       <v-dialog v-model="dialog" max-width="700px">
        <v-card>
          <v-card-title>
            <span>Data Siswa</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" v-model="valid" lazy-validation >
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-file-input
                  label="File input"
                  filled
                  prepend-icon="mdi-camera"
                  v-model="files"
                  @change="pilihGbr"
                ></v-file-input>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field label="Nama*" v-model="nama" :counter="10" :rules="nameRules" required></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field label="NISN" :rules="nameRules" v-model="nip"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field label="Email" :rules="emailRules" v-model="email"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field label="HP" v-model="hp" :rules="nameRules"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field label="Alamat" v-model="alamat" :rules="nameRules"></v-text-field>
              </v-col>
             <v-col cols="12" sm="6" md="6">
                <v-text-field label="Password" v-model="pwd" :rules="nameRules"></v-text-field>
              </v-col>
              <v-col  cols="12" sm="6" md="6">
                <v-select item-text="nm_ta" item-value="ta_id" :items="lsTA" label="Tahun Ajaran" v-model="ta_id" required></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field label="Nama Wali" v-model="nama_wali" :rules="nameRules"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field label="Hp Wali" v-model="hp_wali" :rules="nameRules"></v-text-field>
                 <small>+62*****</small>
              </v-col>
            </v-row>
            </v-form>
          </v-container>
          <small>*harus diisi</small>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="cyan" @click="dialog = false">Cancel</v-btn>
            <v-btn color="cyan" @click="saveData">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog> 
      <v-dialog v-model="konfirmHapus" max-width="400px">
      <v-card>
        <v-card-title>
        <span>Konfirmasi</span>
        </v-card-title>
        <v-card-text>
        <v-container>
          <span>Anda yakin menghapus data ini?</span>
        </v-container>
        </v-card-text>

        <v-card-actions>
        <v-spacer></v-spacer>
          <v-btn color="blue darken-1" @click="konfirmHapus = false">Batal</v-btn>
          <v-btn color="blue darken-1" @click="delData">Ya</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      </v-row>

      <v-dialog v-model="konfirmHapusAll" max-width="400px">
        <v-card>
          <v-card-title>
          <span>Konfirmasi</span>
          </v-card-title>
          <v-card-text>
          <v-container>
            <span>Anda yakin menghapus data ini?</span>
          </v-container>
          </v-card-text>

          <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn color="blue darken-1" @click="konfirmHapusAll = false">Batal</v-btn>
            <v-btn color="blue darken-1" @click="delData2">Ya</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import { api } from '@/components/Apiku'
import axios from 'axios'
import XLSX from 'xlsx';
import readXlsxFile from 'read-excel-file';

export default {
  data: () => ({
    valid: false, konfirmHapusAll:false,
      name: '',
      nameRules: [
        v => !!v || 'Wajib diisi',
        v => (v && v.length >= 2) || 'Data salah',
      ],
      emailRules: [
        v => !!v || 'E-mail wajib diisi',
        v => /.+@.+\..+/.test(v) || 'E-mail harus valid',
      ],
    snackbar: false, konfirmHapus:false,
    text: 'OK',  selected: [], nama_wali:'',hp_wali:'',
    timeout: 2000, text_cari:'',
    title:'',nama:'',nip:'',email:'',alamat:'',pwd:'',hp:'',
    loading: true,
    lsData : [],
    ftData : [], Fta_id:0,kelas_lokal_id2:0,
    lsTA:[], lsKelasLokal:[], ta_id:0,
    dialog : false,
    menuKat : false,
    dialogImpor:false,
    searchTextsLoading: true,
    id: 0,
    fileGbr: '',
    fileGbr2: '',
    dtImpor:[],
    files: null,
    semester_id:1,
    ddSemester:[{"id":"1","label":"Semester 1"},{"id":"2","label":"Semester 2"}],
    headers: [
      { text: "Foto", align: "start", width:50,sortable: false, value: "fotox" },
      { text: "Nama", align: "start", sortable: false, value: "nama" },
      { text: "NISN", value: "nis", sortable: false },
      { text: "Kelas", value: "nm_lokal", sortable: false },
      { text: "email", value: "email", sortable: false },
      { text: "hp", value: "hp", sortable: false },
      { text: "Nama Wali", value: "nama_wali", sortable: false },
      { text: "HP Wali", value: "hp_wali", sortable: false },
      { text: "Aksi", value: "actions", sortable: false },
    ],
  }),
  components:{
    Navbar
  },
  methods: {
    onFileChange(event) {
      let xlsxfile = event.target.files ? event.target.files[0] : null;
      readXlsxFile(xlsxfile).then((rows) => {
        this.dtImpor=rows
        console.log("rows:", rows)
      })
    },
    fnExcel() {
      const dataExcel = []
      let judul = [
        'Daftar Siswa'
      ]
      dataExcel.push(judul)
      judul = [
        this.$store.state.user.nmSekolah
      ]
      dataExcel.push(judul)

      let dataku = [
        'No',
        'Nama',
        'NISN',
        'Kelas',
        'email',
        'password',
        'hp',
        'Nama Wali',
        'HP Wali'
      ]
      dataExcel.push(dataku)

      let i=1
      this.ftData.forEach((value ) => {
        const dataku = [
          i,value['nama'],value['nis'],
          value['nm_lokal'],value['email'],
          value['pwd'],value['hp'],value['nama_wali'],value['hp_wali']
        ]
        dataExcel.push(dataku)
        i++
      })
      var wscols = [
        {wch:10},
        {wch:30},
        //{wch:15},
        //{wch:20},
        //{wch:20},
        //{wch:20}
      ];
      const data = XLSX.utils.json_to_sheet(dataExcel)
      const wb = XLSX.utils.book_new()
      data["!cols"] = wscols
      const huruf = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z']
      for(let i of huruf){
        //const datax ={ text: ""+i+"", value: ""+i+"", sortable: false };
        let cek = i+'1'
        data[cek]=''
        //dataku.push(i);
      }
      XLSX.utils.book_append_sheet(wb, data, 'data')
      XLSX.writeFile(wb,'rekap_siswa.xlsx')
    }, 
    validate () {
      this.$refs.form.validate()
    },
    inputKeydown: function () {
      if(this.text_cari.length>0){
        this.ftData = this.lsData.filter(m => m.nama.toUpperCase().includes(this.text_cari.toUpperCase()))
      }else{
        this.ftData = this.lsData
      }
    },
    
    gtTA (){
      let mdata = this.lsKelasLokal.filter(m => m.ta_id.toUpperCase().includes(this.Fta_id.toUpperCase()))
      mdata.unshift({"kelas_lok_id":"0","kelas_id":"0","lokal":"","guru_id":"0","lokal_id":"0","ta_id":"0","sk_id":"0","na":"0","jur_id":"","nm_kelas":"0","nm_guru":"0","nm_lokal_kelas":"[SEMUA KELAS]","hp_guru":"0"});
      this.ftKelasLokal = mdata
      this.fnData()
      //this.fnGMapKelas()
    },
    gtLokal (){
      this.fnData()
    },
    pilihGbr () {
      if(this.files){
        console.log('cek ', this.files)  
        const formData = new FormData()
        formData.append("uploadfile", this.files)
        axios
          .post(api + 'uploadSFoto', formData)
          .then(response => {
            this.fileGbr = response.data.data
            this.fileGbr2 = response.data.fgbr
            if (response.data.cek === 1) {
              //console.log(' sukses ')
            } else {
              alert(response.data.error_msg);
              console.log(' tidak sukses ')
            }
          })
        // console.log(' gbr ', file)
      }
    },
    fnKonfirmasi(id){
      this.id=id
      this.konfirmHapus=true
    },
    delData2 () {
      const dataku = {
        user_id: this.$store.state.user.user_id,
        data: this.selected
      }
      console.log('cek hapus ', dataku)
      axios
        .post(api + 'delMSiswa', dataku)
        .then(response => {
          if (response.data.cek === 1) {
            this.text = 'Data tersimpan'
            this.snackbar=true
            this.konfirmHapusAll=false
            this.fnData()          
          } else {
            console.log(' tidak sukses ')
          }
        })
    },
    delData () {
      const dataku = {
        user_id: this.$store.state.user.user_id,
        id: this.id
      }
       console.log('cek hapsu', dataku)
      axios
        .post(api + 'delASiswa', dataku)
        .then(response => {
          console.log('cek hapsu', response.data)
          if (response.data.cek === 1) {
            this.text = 'Data tersimpan'
            this.snackbar=true
            this.konfirmHapus=false
            this.fnData()
          } else {
            console.log(' tidak sukses ')
          }
        })
    },
    async fnTA () {
      axios
        .get(api + 'lsTA&merID=2')
        .then(response => {
          console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.lsTA = response.data.data
          }
        })
    },
    async fnKelasLokal () {
      axios
        .get(api + 'lsKelasLokal&sk_id=' + this.$store.state.user.sk_id)
        .then(response => {
          //console.log('cek lokal ' ,response.data.data )
          if (response.data.cek === 1) {
            let mdata = response.data.data;
            mdata.unshift({"kelas_lok_id":"0","kelas_id":"0","lokal":"","guru_id":"0","lokal_id":"0","ta_id":"0","sk_id":"0","na":"0","jur_id":"","nm_kelas":"0","nm_guru":"0","nm_lokal_kelas":"[SEMUA KELAS]","hp_guru":"0"});
            this.lsKelasLokal = response.data.data
          }
        })
    },
    saveData () {
      this.validate()
      console.log('cek valid', this.valid)
      if (this.valid===false) {
        this.text = 'Data tidak lengkap.'
        this.snackbar =true
      } else {
        
        const dataku = {
          nama: this.nama,
          alamat: this.alamat,
          hp: this.hp,
          email: this.email,
          nip: this.nip,
          pwd: this.pwd, ta_id:this.ta_id,
          foto: this.fileGbr,
          id:this.id,
          user_id: this.$store.state.user.user_id,
          sk_id:this.$store.state.user.sk_id,
          nama_wali:this.nama_wali,
          hp_wali : this.hp_wali 
        }
        console.log('cek dataku ', dataku)
        axios
          .post(api + 'saveMSiswa', dataku)
          .then(response => {
            console.log('cek ', response.data)
            if (response.data.cek === 1) {
              this.text = 'Data tersimpan'
              this.snackbar=true
              this.dialog = false
              this.fnData()
              this.clsForm()
              
            } else {
              this.text = 'Data gagal! email sudah dipakai.'
              this.snackbar=true
              //console.log(' tidak sukses ')
            }
          })
      }
    },
    saveImpor () {
      const cekImpor = Number(this.dtImpor.length);
      console.log('cek im', cekImpor)
      if (cekImpor < 3) {
        this.snackbar =true
      } else {
        console.log('cek mapel ', this.dtImpor)
        const dataku = {
          userID: 1,
          data:this.dtImpor
        }
        axios
          .post(api + 'imporGuru', dataku)
          .then(response => {
            console.log('cek ', response.data)
            if (response.data.cek === 1) {
              this.text = 'Data tersimpan'
              this.snackbar=true
              this.dialog = false
              this.fnData()
              this.clsForm()
            } else {
              console.log(' tidak sukses ')
            }
          })
      }
    },
    async fnMapel () {
      axios
        .get(api + 'ddMapel&merID=2')
        .then(response => {
          console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.ddMapel = response.data.data
          }
        })
    },
    async fnData () {
      this.lsData = []
      this.ftData =[]
      axios
        .get(api + 'lsSiswa&sk_id=' + this.$store.state.user.sk_id+'&ta_id=' + this.Fta_id+'&kelas_lokal_id=' + this.kelas_lokal_id2,)
        .then(response => {
          //console.log('cek data halooooo ', response.data)
          if (response.data.cek === 1) {
            this.lsData = response.data.data
            this.ftData = response.data.data
          }
        })
    },
    async fnBaruKat() {
      this.menuKat = true
    },
    async fnBaru() {
      this.clsForm()
      this.dialog=true
    },
    async fnImpor() {
      this.clsForm()
      this.dialogImpor=true
    },
    async fnEdit (id) {
      const cekIndex = this.lsData.findIndex(cek => cek.sis_id === id)
      console.log(' cek data ', cekIndex, 'data : ', this.lsData[cekIndex])
      const dtEdit = this.lsData[cekIndex]
      this.id = id
      this.nama = dtEdit.nama
      this.nama_wali = dtEdit.nama_wali
      this.hp_wali = dtEdit.hp_wali
      this.alamat = dtEdit.alamat
      this.hp = dtEdit.hp
      this.email = dtEdit.email
      this.nip = dtEdit.nis
      this.ta_id = dtEdit.angkat_id
      this.pwd = dtEdit.pwd
      this.fileGbr = dtEdit.fileGbr
      this.fileGbr2 = dtEdit.fgbr
      this.dialog = true
    },
    clsForm () {
      this.nama = ''
      this.alamat = ''
      this.fileGbr = ''
      this.hp = ''
      this.email = ''
      this.id = 0
      this.nip = ''
      this.pwd = ''
      this.nama_wali = ''
      this.hp_wali = ''
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
      this.searchTextsLoading = false
    }, 2000)
     // this.fnKat()
    this.fnTA()
    this.fnKelasLokal()
    this.fnData()
    this.fnMapel()
  }
}
</script>

<style lang="scss" scoped></style>
