<template>
  <div id="history" class="fill-height">
    <Navbar />
    <v-container fluid class="pt-0 pb-0 fill-height">
      <v-row class="fill-height">
        <v-col cols="12" sm="12">
          <h2 class="title font-weight-medium pb-5">Master Data Pengguna xds</h2>
          <template>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>
      <v-dialog v-model="dialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">Data Pengguna</span>
          </v-card-title>
          <v-card-text>
            <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field label="Nama *" v-model="nama" required color="green"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field label="Email" v-model="email" required color="green"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field label="Password" v-model="pwd" required color="green"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field label="No HP" v-model="hp" required color="green"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-select item-text="label" item-value="id" :items="ddUG" label="User Group*" v-model="ugID" required></v-select>
              </v-col>
            </v-row>
          </v-container>
          <small>*wajib diisi!!!</small>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="px-12" outlined color="green" @click="dialog = false">Cancel</v-btn>
            <v-btn color="green" @click="saveData">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
            <v-row align="center" class="list px-0 mx-auto">
              <v-col cols="12" md="6">
                <v-text-field prepend-inner-icon="mdi-magnify" v-model="cariNama" label="Search"></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <v-btn small @click="fnCari">Search</v-btn>
                <v-btn small outlined color="green" @click="fnBaru">+ Pengguna</v-btn>
              </v-col>

              <v-col cols="12" sm="12">
                <v-card class="mx-auto" tile>
                  <v-data-table
                    :headers="headers"
                    :items="ftData"
                    :enabled-pagination="true"
                    :hide-default-footer="false"
                    :page.sync="page"
                    :items-per-page="itemsPerPage"
                    class="elevation-1"
                    @page-count="pageCount = $event"
                  >
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-icon small class="mr-2" @click="fnEdit(item.userID)">mdi-pencil </v-icon>
                      <v-icon small @click="fnDel(item.userID)">mdi-delete</v-icon>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import { api } from '@/components/Apiku'
import axios from 'axios'
export default {
  data: () => ({
    snackbar: false,
    text: 'OK',
    timeout: 2000,
    cariNama:'',
    loading: true,
    lsData : [],
    ftData : [],
    ddUG : [],
    ugID :1,
    dialog : false,
    menuKat : false,
    searchTextsLoading: true,
    tutorials: [],
    nama: '',
    hp: '',
    pwd: '',
    email: '',
    id: 0,
    files: null,
    page: 1,
        pageCount: 0,
        itemsPerPage: 10,
    headers: [
      { text: "Nama", align: "start", sortable: false, value: "nama" },
      { text: "Email", align: "start", sortable: false, value: "email" },
      { text: "HP", align: "start", sortable: false, value: "hp" },
      { text: "Group User", align: "start", sortable: false, value: "nmGroup" },
      { text: "Password", align: "start", sortable: false, value: "pwd" },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),
  components:{
    Navbar
  },
  methods: {
    fnCari () {
      this.ftData =  this.lsData.filter(post => post.nama.toLowerCase().includes(this.cariNama))
      console.log('cek data awal ', this.lsData)
      console.log('cek data ', this.ftData)
    },
    saveData () {
      if (this.nama === '') {
        this.snackbar =true
      } else {
        const dataku = {
          userID: this.$store.state.user.userID,
          nama: this.nama,
          hp: this.hp,
          email: this.email,
          ugID: this.ugID,
          pwd: this.pwd,
          id: this.id,
          merID: this.$store.state.user.merID
        }
        axios
          .post(api + 'saveUser', dataku)
          .then(response => {
            console.log('cek ', response.data)
            //console.log('cek data ', dataku)
            if (response.data.cek === 1) {
              this.text = 'Data tersimpan'
              this.snackbar=true
              this.dialog = false
              this.fnData()
              this.clsForm()
              
            } else {
              console.log(' tidak sukses ')
            }
          })
      }
    },
    async fnData () {
      axios
        .get(api + 'getUser&merID=' + this.$store.state.user.merID)
        .then(response => {
          console.log('cek data halooooo ', response.data)
          if (response.data.cek === 1) {
            this.lsData = response.data.data
            this.ftData = response.data.data
          }
        })
    },
    formatPrice (value) {
      const val = (value / 1).toFixed(0).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    async fnBaru() {
      this.clsForm()
      this.dialog=true
    },
    async fnEdit (id) {
      console.log(' cek show dtEdit.katID ', id)
      const cekIndex = this.lsData.findIndex(cek => cek.userID === id)
      const dtEdit = this.lsData[cekIndex]
      this.id = id
      this.nama = dtEdit.nama
      this.email = dtEdit.email
      this.hp = dtEdit.hp
      this.pwd = dtEdit.pwd
      this.ugID = dtEdit.ugID
      this.dialog = true
    },
    async fnDel (id) {
      axios
        .get(api + 'delPelanggan&id=' + id)
        .then(response => {
          console.log('cek data halooooo ', response.data.data)
          if (response.data.cek === 1) {
            this.text = 'Data terhapus'
            this.snackbar=true
            this.fnData()
          }
        })
    },
    clsForm () {
      this.nama = ''
      this.hp = ''
      this.alamat = ''
      this.hpp = 0
      this.id = 0
    },
    async fnddUG () {
      axios
        .get(api + 'userGroup')
        .then(response => {
          console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.ddUG = response.data.data
          }
        })
    },
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
      this.searchTextsLoading = false
    }, 2000)
     // this.fnKat()
    this.fnData()
    this.fnddUG()
  }
}
</script>

<style lang="scss" scoped></style>
