<template>
  <div id="history" class="fill-height">
    <Navbar />
    <v-container fluid class="pt-0 pb-0 fill-height">
      <v-row class="fill-height">
        <v-col cols="12" sm="12" class="pt-5 pl-2">
          <h4 class="title">JidiLibrary</h4>
          <template>
            <v-snackbar v-model="snackbar" :timeout="timeout">
              {{ text }}
              <template v-slot:action="{ attrs }">
                <v-btn
                  color="blue"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  Close
                </v-btn>
              </template>
            </v-snackbar>
      
          <v-dialog v-model="dialogImpor" max-width="700px">
            <v-card>
              <v-card-title>
                <span>Impor Data</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                <input type="file" @change="onFileChange" />
                <input type="file" class="my_input" @change="importExcel()" id="upload" />Import
                  </v-col>
                </v-row>
              </v-container>
              <small>*harus diisi</small>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="cyan" @click="dialogImpor = false">Cancel</v-btn>
                <v-btn color="cyan" @click="saveImpor">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="konfirmHapus" max-width="400px">
            <v-card>
              <v-card-title>
              <span>Konfirmasi</span>
              </v-card-title>
              <v-card-text>
              <v-container>
                <span>Anda yakin menghapus data ini?</span>
              </v-container>
              </v-card-text>

              <v-card-actions>
              <v-spacer></v-spacer>
                <v-btn color="blue darken-1" @click="konfirmHapus = false">Batal</v-btn>
                <v-btn color="blue darken-1" @click="delData">Ya</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        <v-row class="px-5 mx-auto mt-20" v-if="lsStatus">
          <v-col cols="12" md="6">
            <v-text-field v-model="text_cari" label="cari..." v-on:keyup="inputKeydown">></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-btn color="cyan" class="mb-0" @click="fnBaru()" small>+ Data</v-btn>
          </v-col>

          <v-col cols="12" sm="12">
            <v-card class="mx-auto" tile>
              <v-data-table
                :headers="headers"
                :items="ftData"
                :disable-pagination="false"
                :hide-default-footer="false"
                :loading="loading"
                loading-text="Loading... Please wait"
                :footer-props="{
                'items-per-page-options': [30, 40, 50]
                }"
                :items-per-page="30"
              >
               <template v-slot:[`item.fgbr`]="{ item }">
                    <v-img :src="item.fgbr" height="100px" width="100px"></v-img>
                  </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small class="mr-2" @click="fnEdit(item.gt_id)">mdi-pencil </v-icon>
                  <v-icon small class="mr-2" @click="fnView(item.file)" v-if="item.file">mdi-eye</v-icon>
                  <v-icon small @click="fnKonfirmasi(item.gt_id)">mdi-delete</v-icon>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-else class="pt-5 pl-2">
        <v-spacer></v-spacer>
            <br><br><br>
          <v-col cols="12" sm="6" md="6">
            <v-text-field label="Judul" v-model="judul"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field label="Penulis" v-model="penulis"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field label="Penerbit" v-model="penerbit"></v-text-field>
          </v-col>
        <v-col  cols="12" sm="4" md="4">
            <v-select item-text="label" item-value="id" outlined :items="ddMapel" label="Mata Pelajaran"
              v-model="mapel_id" required ></v-select>
          </v-col>
          <v-col  cols="12" sm="6" md="6">
            <v-select item-text="nm_kelas" item-value="kelas_id" :items="lsKelas" label="Kelas" v-model="kelas_id" required></v-select>
          </v-col>
          <v-col  cols="12" sm="6" md="6">
            <v-select item-text="label" item-value="id" :items="ddSemester" label="Semester" v-model="semester_id" required></v-select>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="4" md="4">
            <v-file-input hidden label="Cover" filled prepend-icon="mdi-image"
            v-model="gbr" @change="pilihGbr2">
            </v-file-input> {{ fileCover }}
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-img v-if="fileCoverUrl" :src="fileCoverUrl"
               style="max-width: 100%;width: 100px; object-fit: cover"
            ></v-img>
            <v-img v-else src="@/assets/gbr.png" style="max-width: 100%;width: 100px; object-fit: cover"></v-img>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-file-input hidden label="File PDF" filled prepend-icon="mdi-file-pdf-box"
            v-model="files" @change="pilihGbr">
            </v-file-input> {{fileGbr}}
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-img v-if="fileGbr" src="@/assets/pdf.png"
               style="max-width: 100%;width: 100px; object-fit: cover"
            ></v-img>
            <v-img v-else src="@/assets/gbr.png" style="max-width: 100%;width: 100px; object-fit: cover"></v-img>
          </v-col>
        <v-spacer></v-spacer>
        <v-btn color="cyan" @click="lsStatus = true">Cancel</v-btn>
        <v-btn color="cyan" @click="saveData">Save</v-btn>
            
            </v-row>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import { api } from '@/components/Apiku'
import axios from 'axios'
import readXlsxFile from 'read-excel-file';
import "vue-wysiwyg/dist/vueWysiwyg.css";

export default {
  data: () => ({
    snackbar: false,
    lsStatus:true,
    ar_mapel:[],
    text: 'OK',
    timeout: 2000,
    title:'',
    loading: true,
    lsData : [],
    ftData : [],lsKelasLokal :[], kelasLokal_id : [],
    dialog : false,
    menuKat : false,
    dialogImpor:false,
    searchTextsLoading: true,
    judul: '',
    penulis: '',
    penerbit:'', fileCover:'', fileCoverUrl:'',fileGbr:'', fileGbrUrl:'',
    yt_id: '',
    id: '0',
    lokal:'',
    ddMapel : [],
    dtImpor:[],
    files: null, gbr:null,
    mapel_id:'',
    kelas_id:1,
    guru_id:0, ta_id:0,
    semester_id:0, lsGuru:[],
    lsKelas:[],
    lsTA:[],
    lsJPerangkat:[],
    j_perangkat_id:'',id_hapus:0,konfirmHapus:false,
    image: {
    uploadURL: "/api/myEndpoint",
    dropzoneOptions: {}
  },

  // limit content height if you wish. If not set, editor size will grow with content.
    maxHeight: "500px",
    ddSemester:[{"id":"1","label":"Semester 1"},{"id":"2","label":"Semester 2"}],
    headers: [
      { text: "No", width:"20", value: "nomor", sortable: false },
      { text: "Cover", align: "start", width:50,sortable: false, value: "fgbr" },
      { text: "Judul", align: "start", sortable: false, value: "judul" },
      { text: "Penulis", align: "start", sortable: false, value: "penulis" },
      { text: "Penerbit", align: "start", sortable: false, value: "penerbit" },
      { text: "Mata Pelajaran", value: "nm_mapel", sortable: false },
      { text: "Kelas", value: "nm_kelas", sortable: false },
      { text: "Aksi", value: "actions", sortable: false },
    ]
  }),
  components:{
    Navbar
  },
  methods: {
    inputKeydown: function () {
      if(this.text_cari.length>0){
        this.ftData = this.lsData.filter(m => m.judul.toUpperCase().includes(this.text_cari.toUpperCase()))
      }else{
        this.ftData = this.lsData
      }
    },
    onFileChange(event) {
      let xlsxfile = event.target.files ? event.target.files[0] : null;
      readXlsxFile(xlsxfile).then((rows) => {
        this.dtImpor=rows
        console.log("rows:", rows)
      })
    },
    pilihGbr2() {
      if(this.gbr){
        const formData = new FormData()
        formData.append("uploadfile", this.gbr)
        axios
        .post(api + 'uploadCover', formData)
        .then(response => {
          this.fileCover = response.data.data
          this.fileCoverUrl = response.data.fgbr
          if (response.data.cek === 1) {
            console.log(' sukses ')
          } else {
            alert(response.data.error_msg);
            console.log(' tidak sukses ')
          }
        })
      }
    },
    pilihGbr () {
      if(this.files['type'] === 'application/pdf'){
        const formData = new FormData()
        formData.append("uploadfile", this.files)
        axios
        .post(api + 'uploadPdf', formData)
        .then(response => {
          this.fileGbr = response.data.data
          this.fileGbr2 = response.data.fgbr
          if (response.data.cek === 1) {
            console.log(' sukses ')
          } else {
            alert(response.data.error_msg);
            console.log(' tidak sukses ')
          }
        })
      }else{
        this.text = 'Hanya file pdf'
        this.snackbar=true
      }
    },
    saveData () {
      //if (this.kelas_id === 0) {
        //this.snackbar =true
     // } else {
        //console.log('cek mapel ', this.j_perangkat_id)
        console.log('cek id : ',this.id)
        const dataku = {
          user_id: this.$store.state.user.user_id,
          kelas_id: this.kelas_id,
          semester_id: this.semester_id,
          mapel_id: this.mapel_id,
          guru_id: this.$store.state.user.guru_id,
          judul: this.judul,
          id: this.id,
          file:this.fileGbr,
          gbr:this.fileCover,
          sk_id:this.$store.state.user.sk_id,
          penulis:this.penulis,
          penerbit: this.penerbit
         // id:0
        }
        axios
          .post(api + 'saveGPustaka', dataku)
          .then(response => {
            console.log('cek ', response.data)
            if (response.data.cek === 1) {
              this.text = 'Data tersimpan'
              this.snackbar=true
              this.fnData()
              this.clsForm()
              this.lsStatus = true
            } else {
              console.log(' tidak sukses ')
            }
          })
      //}
    },
    fnKonfirmasi(id){
      this.id_hapus=id
      this.konfirmHapus=true
    },
    delData () {
      const dataku = {
        user_id: this.$store.state.user.user_id,
        id: this.id_hapus
      }
      axios
        .post(api + 'delGPustakas', dataku)
        .then(response => {
          console.log('cek ', response.data)
          if (response.data.cek === 1) {
            this.text = 'Data tersimpan'
            this.snackbar=true
            this.konfirmHapus=false
            this.fnData()           
          } else {
            console.log(' tidak sukses ')
          }
        })
    },
    saveImpor () {
      const cekImpor = Number(this.dtImpor.length);
      if (cekImpor < 3) {
        this.snackbar =true
      } else {
        console.log('cek mapel ', this.dtImpor)
        const dataku = {
          userID: 1,
          data:this.dtImpor
        }
        axios
          .post(api + 'imporGuru', dataku)
          .then(response => {
            console.log('cek ', response.data)
            if (response.data.cek === 1) {
              this.text = 'Data tersimpan'
              this.snackbar=true
              this.dialog = false
              this.fnData()
              this.clsForm()
              
            } else {
              console.log(' tidak sukses ')
            }
          })
      }
    },
    async fnKelasLokal() {
      axios
        .get(api + 'lsKelasLokal&sk_id=' + this.$store.state.user.sk_id+'&jenjang_id='+this.$store.state.user.jenjang_id)
        .then(response => {
          console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.lsKelasLokal = response.data.data
          }
        })
    },
    async fnKelas() {
      axios
        .get(api + 'lsKelas&sk_id=' + this.$store.state.user.sk_id+'&jenjang_id='+this.$store.state.user.jenjang_id)
        .then(response => {
          console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.lsKelas = response.data.data
          }
        })
    },
    async fnGuru() {
      axios
        .get(api + 'lsGuru&merID=2')
        .then(response => {
          console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.lsGuru = response.data.data
          }
        })
    },
    async fnMapel () {
      axios
        .get(api + 'ddMapel&merID=2')
        .then(response => {
          if (response.data.cek === 1) {
            this.ddMapel = response.data.data
          }
        })
    },
    async fnJPerangkat () {
      axios
        .get(api + 'lsJ_perangkat&merID=2')
        .then(response => {
          if (response.data.cek === 1) {
            this.lsJPerangkat = response.data.data
            console.log('cek perang ', response.data.data)
          }
        })
    },
    async fnTA () {
      axios
        .get(api + 'lsTA&merID=2')
        .then(response => {
          console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.lsTA = response.data.data
          }
        })
    },
    async fnData () {
      axios
        .get(api + 'lsGPustaka&user_id=' + this.$store.state.user.user_id)
        .then(response => {
          console.log('cek data halooooo ', response.data)
          if (response.data.cek === 1) {
            this.lsData = response.data.data
            this.ftData = response.data.data
            //this.tutorials = response.data.data
          }
        })
    },
    async fnBaruKat() {
      this.menuKat = true
    },
    async fnBaru() {
      this.id=0
      this.clsForm()
    },
    fnView(id) {
      window.open(this.$store.state.url_file+"pdf/"+ id);
    },
    async fnImpor() {
      this.clsForm()
      this.dialogImpor=true
    },
    async fnEdit (id) {
      const cekIndex = this.lsData.findIndex(cek => cek.gt_id === id)
      const dtEdit = this.lsData[cekIndex]
      this.id = id
      this.kelas_id = dtEdit.kelas_id
      this.judul = dtEdit.judul
      this.mapel_id = dtEdit.mapel_id
      this.semester_id = dtEdit.semester_id
      this.kelasLokal_id = dtEdit.lokalx
      this.fileGbr = dtEdit.file
      this.fileCover = dtEdit.gbr
      this.fileCoverUrl = dtEdit.fgbr
      this.lsStatus=false
    },
    clsForm () {
      this.judul = ''
      this.fileGbr = ''
      this.fileCover = ''
      this.fileCoverUrl = ''
      this.mapel_id = this.ddMapel[0]['id']
      this.j_perangkat_id = this.lsJPerangkat[0]['j_perangkat_id']
      this.semester_id = this.ddSemester[0]['id']
      this.lsStatus=false
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
      this.searchTextsLoading = false
    }, 1000)
     // this.fnKat()
    this.fnKelas()
    this.fnData()
    this.fnMapel()
    this.fnGuru()
    this.fnTA()
    this.fnJPerangkat()
    this.fnKelasLokal()
  }
}
</script>

<style lang="scss" scoped></style>
