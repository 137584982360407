<template>
  <div id="history" class="fill-height">
    <Navbar />
    <v-container fluid class="pt-0 pb-0 fill-height">
      <v-row class="fill-height">
        <v-col cols="12" sm="12">
          <h2 class="title font-weight-medium pb-5">Profil</h2>
          <template>
            <v-snackbar v-model="snackbar" :timeout="timeout">
              {{ text }}
              <template v-slot:action="{ attrs }">
                <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
              </template>
            </v-snackbar>
            <v-card>
              <v-card-text>
                <v-container>
                    <v-col class="mx-auto" cols="3" sm="3" md="5" lg="3" @click="pilihGbr()">
                    <div @click="pilihGbr()"                   >
                    <v-list-item-avatar size="150" @click="pilihGbr">
                      <v-img :src="url_foto" v-if="url_foto"></v-img>
                      <div color="blue" class="avatar rounded-circle mr-10" v-else>
                        <h2>  {{ nama.substring(0,2) }} </h2>
                      </div>
                    </v-list-item-avatar>
                    </div>
                  </v-col>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <v-file-input hidden
                    label="Foto Profil"
                    filled
                    prepend-icon="mdi-camera"
                    v-model="files"
                    @change="pilihGbr"
                    >
                    </v-file-input>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field label="Nama *" v-model="nama" required color="green"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field label="NIP/NIK/NUPTK *" v-model="nip" required color="green"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field label="Password *" v-model="pwd" required color="green"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field label="alamat" v-model="alamat" required color="green"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field label="email" v-model="email" required color="green"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field label="No HP" v-model="hp" required color="green"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
              <small>*wajib diisi!!!</small>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green" @click="saveData" dark>Save</v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import { api } from '@/components/Apiku'
import axios from 'axios'
export default {
  data: () => ({
    snackbar: false,
    text: 'OK',
    timeout: 2000,
    cariNama:'',
    loading: true,
    lsData : [],
    ftData : [],
    ddKatMR : [],
    katmrID :1,
    dialog : false,
    searchTextsLoading: true,
    tutorials: [],guru_id:0,
    nama: '',
    hp: '',nip:'',pwd:'',
    alamat: '',ug_id:0,
    email: '',
    id: 0,
    files: null,
    fileGbr: '',
    fileGbr2: '',
    url_foto:'',
    headers: [
      { text: "Nama", align: "start", sortable: false, value: "nama" },
      { text: "Email", align: "start", sortable: false, value: "email" },
      { text: "HP", align: "start", sortable: false, value: "hp" },
      { text: "Group User", align: "start", sortable: false, value: "nmGroup" },
      { text: "Password", align: "start", sortable: false, value: "pwd" },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),
  components:{
    Navbar
  },
  methods: {
    fnCari () {
      this.ftData =  this.lsData.filter(post => post.nama.toLowerCase().includes(this.cariNama))
      console.log('cek data awal ', this.lsData)
      console.log('cek data ', this.ftData)
    },
    pilihGbr () {
      if(this.files){
        const formData = new FormData()
        formData.append("uploadfile", this.files)
        axios
          .post(api + 'uploadGFoto', formData)
          .then(response => {
            this.fileGbr = response.data.data
            this.url_foto = response.data.fgbr
            console.log('cek ', response.data)
            if (response.data.cek === 1) {
              console.log(' sukses ')
            } else {
              console.log(' tidak sukses ')
            }
          })
        // console.log(' gbr ', file)
      }
    },
    saveData () {
      if (this.nama === '') {
        this.snackbar =true
      } else {
        const dataku = {
          user_id: this.$store.state.user.user_id,
          nama: this.nama,
          hp: this.hp,
          nip: this.nip,
          email: this.email,
          alamat: this.alamat,
          pwd: this.pwd,
          ug_id: this.ug_id,
          guru_id:this.guru_id,
          //merID: this.$store.state.user.merID,
          foto: this.fileGbr
        }
        axios
          .post(api + 'saveGProfil', dataku)
          .then(response => {
            console.log('cek ', response.data)
            //console.log('cek data ', dataku)
            if (response.data.cek === 1) {
              this.text = 'Data tersimpan'
              this.snackbar=true
              this.dialog = false
              this.fnData()
              this.clsForm()
              
            } else {
              //console.log(' tidak sukses ')
              this.text = 'Error!!!'
              this.snackbar=true
            }
          })
      }
    },
    async fnData () {
      axios
        .get(api + 'lsGProfil&user_id=' + this.$store.state.user.user_id)
        .then(response => {
          console.log('cek data halooooo profil', response.data)
          if (response.data.cek === 1) {
            this.ftData = response.data.data
            let datax = response.data.data[0]
            console.log(' xxx ', datax)
            this.nama = datax.nama
            this.email = datax.email
            this.hp = datax.hp
            this.alamat = datax.alamat
            this.nip = datax.nip
            this.pwd = datax.pwd
            this.guru_id = datax.guru_id
            this.ug_id =datax.ug_id
            this.fileGbr = datax.foto
            this.url_foto = datax.f_foto
            this.files = datax.fgbr
          }
        })
    },

async fnBaru() {
      this.clsForm()
      this.dialog=true
    },
    async fnEdit (id) {
      //console.log(' cek show dtEdit.katID ', id)
      const cekIndex = this.lsData.findIndex(cek => cek.userID === id)
      const dtEdit = this.lsData[cekIndex]
      this.id = id
      this.nama = dtEdit.nama
      this.email = dtEdit.email
      this.hp = dtEdit.hp
      this.pwd = dtEdit.pwd
      this.ugID = dtEdit.ugID
      this.dialog = true
    },
    clsForm () {
      this.nama = ''
      this.hp = ''
      this.alamat = ''
      this.hpp = 0
      this.id = 0
    },
    async fnddKatMR () {
      axios
        .get(api + 'ddKatMR')
        .then(response => {
          //console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.ddKatMR = response.data.data
          }
        })
    },
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
      this.searchTextsLoading = false
    }, 2000)
     // this.fnKat()
    this.fnData()
    //this.fnddKatMR()
  }
}
</script>

<style lang="scss" scoped></style>
