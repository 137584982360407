<template>
  <div id="history" class="fill-height">
    <Navbar />
    <v-container fluid class="pt-0 pb-0 fill-height">
      <v-row class="fill-height">
        <v-col
          cols="12"
          sm="12"
          order="2"
          order-sm="2"
          order-md="1"
          order-lg="1"
          md="7"
          lg="9"
          class="pt-8 pl-8"
        >
          <h4 class="title">Guru Bidang Studi</h4>
          <template>
            <v-snackbar
              v-model="snackbar"
              :timeout="timeout"
            >
              {{ text }}

              <template v-slot:action="{ attrs }">
                <v-btn
                  color="blue"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  Close
                </v-btn>
              </template>
            </v-snackbar>
      <v-dialog v-model="dialog" max-width="700px">
        <v-card>
          <v-card-title>
            <span>Data Lokal Kelas</span>
          </v-card-title>
          <v-card-text>
            <v-container>
            <v-row>
              <v-col  cols="12" sm="6" md="6">
                <v-select item-text="nm_lokal_kelas" item-value="kelas_lok_id" :items="lsKelas" label="Kelas" v-model="kelas_id" required></v-select>
              </v-col>
              <v-col  cols="12" sm="6" md="6">
                <v-select item-text="nama" item-value="guru_id" :items="lsGuru" label="Guru Kelas" v-model="guru_id" required></v-select>
              </v-col>
              <v-col  cols="12" sm="6" md="6">
                <v-select item-text="nm_ta" item-value="ta_id" :items="lsTA" label="Tahun Ajaran" v-model="ta_id" required></v-select>
              </v-col>
                            <v-col  cols="12" sm="6" md="6">
                <v-select item-text="label" item-value="id"  :items="ddMapel" label="Mata Pelajaran" v-model="mapel" required></v-select>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field label="Keterangan" v-model="ket"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>*harus diisi</small>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="cyan" @click="dialog = false">Cancel</v-btn>
            <v-btn color="cyan" @click="saveData">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

             <v-row align="center" class="list px-0 mx-auto">
            <v-col cols="12" md="6">
            <v-select item-text="nm_lokal_kelas" item-value="kelas_lok_id" :items="lsKelasLokal" 
            label="Lokal Kelas" v-model="kelas_lok_id" required  v-on:change="gtLokal()"></v-select>
          </v-col>
              <v-col cols="12" md="6">
                <v-btn small>
                  Search
                </v-btn>
                 <v-btn color="cyan" class="mb-0"  @click="fnBaru" small>+ Data Guru</v-btn>
              </v-col>

              <v-col cols="12" sm="12">
                <v-card class="mx-auto" tile>
                  <v-data-table
                    :headers="headers"
                    :items="ftData"
                    :disable-pagination="false"
                    :hide-default-footer="false"
                  >
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-icon small class="mr-2" @click="fnEdit(item.prodID)">mdi-pencil </v-icon>
                      <v-icon small @click="deleteTutorial(item.prodID)">mdi-delete</v-icon>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          order="1"
          order-sm="1"
          order-md="2"
          order-lg="2"
          md="3"
          lg="3"
          :class="[
            'pa-0',
            { 'fill-height': $vuetify.breakpoint.name.smAndDown ? true : false }
          ]"
        >
          <v-card
            id="card-radiobox"
            class="fill-height pa-10"
          >
          <v-row>
            <v-col cols="8">
              Filter
            </v-col>
          </v-row>
          <v-select item-text="nm_ta" item-value="ta_id" :items="lsTA" label="Tahun Ajaran" v-model="ta_id" required></v-select>
            
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import { api } from '@/components/Apiku'
import axios from 'axios'
//import XLSX from 'xlsx';
import readXlsxFile from 'read-excel-file';

export default {
  data: () => ({
    snackbar: false,
    text: 'OK',
    timeout: 2000,
    title:'',
    loading: true,
    lsData : [], lsKelasLokal:[],
    ftData : [],
    dialog : false,
    menuKat : false,
    dialogImpor:false,
    searchTextsLoading: true,
    judul: '',
    kompetensi: '',
    yt_id: '',
    id: 0,
    lokal:'',
    ddMapel : [],
    dtImpor:[],
    files: null,
    mapel:'',
    kelas_id:1,
    guru_id:0,
    ta_id:0,
    lsGuru:[],
    lsKelas:[],
    lsTA:[],
    ddSemester:[{"id":"1","label":"Semester 1"},{"id":"2","label":"Semester 2"}],
    headers: [
        { text: "Mata Pelajaran", align: "start", sortable: false, value: "nm_mapel" },
        { text: "Guru", value: "nmGuru", sortable: false },
        { text: "Kelas", value: "nmLokal", sortable: false },
        { text: "hp", value: "hp", sortable: false },
        { text: "Aksi", value: "actions", sortable: false },
      ]
  }),
  components:{
    Navbar
  },
  methods: {
    async fnKelasLokal() {
      axios
        .get(api + 'lsKelasLokal&sk_id=' + this.$store.state.user.sk_id+'&jenjang_id='+this.$store.state.user.jenjang_id)
        .then(response => {
          console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.lsKelasLokal = response.data.data
          }
        })
    },
    onFileChange(event) {
      let xlsxfile = event.target.files ? event.target.files[0] : null;
      readXlsxFile(xlsxfile).then((rows) => {
        this.dtImpor=rows
        console.log("rows:", rows)
      })
    },
    pilihGbr () {
      if(this.files){
        console.log('cek ', this.files)  
        const formData = new FormData()
        formData.append("uploadfile", this.files)
        axios
          .post(api + 'uploadGbr', formData)
          .then(response => {
            console.log('cek ', response.data)
            this.fileGbr = response.data.data
            this.fileGbr2 = response.data.fgbr
            if (response.data.cek === 1) {
              console.log(' sukses ')
            } else {
              console.log(' tidak sukses ')
            }
          })
        // console.log(' gbr ', file)
      }
    },
    saveData () {
      if (this.kelas_id === 0) {
        this.snackbar =true
      } else {
        console.log('cek mapel ', this.mapel)
        const dataku = {
          user_id: this.$store.state.user.user_id,
          kelas_id: this.kelas_id,
          ket: this.ket,
          guru_id: this.guru_id,
          ta_id: this.ta_id,
          mapel_id: this.mapel,
          sk_id:this.$store.state.user.sk_id,
          id:0
        }
        axios
          .post(api + 'saveGuruMapel', dataku)
          .then(response => {
            console.log('cek ', response.data)
            if (response.data.cek === 1) {
              this.text = 'Data tersimpan'
              this.snackbar=true
              this.dialog = false
              this.fnData()
              this.clsForm()
              
            } else {
              console.log(' tidak sukses ')
            }
          })
      }
    },
    saveImpor () {
      const cekImpor = Number(this.dtImpor.length);
      console.log('cek im', cekImpor)
      if (cekImpor < 3) {
        this.snackbar =true
      } else {
        console.log('cek mapel ', this.dtImpor)
        const dataku = {
          userID: 1,
          //judul: this.judul,
          //kompetensi: this.kompetensi,
          //katID: this.kat,
          //yt_id: this.yt_id,
          //semester_id: this.semester_id,
          //gbr: this.fileGbr,
          //id:0,
          data:this.dtImpor
        }
        axios
          .post(api + 'imporGuru', dataku)
          .then(response => {
            console.log('cek ', response.data)
            if (response.data.cek === 1) {
              this.text = 'Data tersimpan'
              this.snackbar=true
              this.dialog = false
              this.fnData()
              this.clsForm()
              
            } else {
              console.log(' tidak sukses ')
            }
          })
      }
    },
    async fnKelas() {
      axios
        .get(api + 'lsKelasLokal&sk_id=' + this.$store.state.user.sk_id)
        .then(response => {
          console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.lsKelas = response.data.data
          }
        })
    },
    async fnGuru() {
      axios
        .get(api + 'lsGuru&merID=2')
        .then(response => {
          console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.lsGuru = response.data.data
          }
        })
    },
    async fnMapel () {
      axios
        .get(api + 'ddMapel&merID=2')
        .then(response => {
          if (response.data.cek === 1) {
            this.ddMapel = response.data.data
          }
        })
    },
    async fnTA () {
      axios
        .get(api + 'lsTA&merID=2')
        .then(response => {
          console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.lsTA = response.data.data
          }
        })
    },
    async fnData () {
      axios
        .get(api + 'lsGuruMapel&sk_id=' + this.$store.state.user.sk_id)
        .then(response => {
          console.log('cek data halooooo ', response.data)
          if (response.data.cek === 1) {
            this.lsData = response.data.data
            this.ftData = response.data.data
            //this.tutorials = response.data.data
          }
        })
    },
    async fnBaruKat() {
      this.menuKat = true
    },
    async fnBaru() {
      this.clsForm()
      this.dialog=true
    },
    async fnImpor() {
      this.clsForm()
      this.dialogImpor=true
    },
    async fnEdit (id) {
      const cekIndex = this.lsData.findIndex(cek => cek.prodID === id)
      console.log(' cek data ', cekIndex, 'data : ', this.lsData[cekIndex])
      const dtEdit = this.lsData[cekIndex]
      this.id = dtEdit.prodID
      this.nama = dtEdit.nama
      this.hpp = dtEdit.hpp
      this.harga = dtEdit.harga
      this.kode = dtEdit.kode
      this.kat = dtEdit.katID
      this.fileGbr = dtEdit.image
      this.katID = dtEdit.katID
      this.fileGbr2 = dtEdit.fgbr
      this.dialog = true
      console.log(' cek show dtEdit.katID ', dtEdit.katID)
      // this.itemsPaginated[cekIndex].qty = Number(this.itemsPaginated[cekIndex].qty) + 1
    },
    clsForm () {
      this.nama = ''
      this.harga = ''
      this.fileGbr = ''
      this.kode = ''
      this.hpp = 0
      this.id = 0
      this.namaKat = ''
      this.katID = 0
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
      this.searchTextsLoading = false
    }, 2000)
     // this.fnKat()
    this.fnKelasLokal()
    this.fnKelas()
    this.fnData()
    this.fnMapel()
    this.fnGuru()
    this.fnTA()
  }
}
</script>

<style lang="scss" scoped></style>
