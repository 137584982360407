<template>
  <div id="history" class="fill-height">
    <Navbar />
    <v-container>
      <template>
        <v-snackbar v-model="snackbar" :timeout="timeout">
          {{ text }}
          <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </template>
      <v-toolbar flat class="">
        <v-row>

        </v-row>
      </v-toolbar>
      
    <br>
    <v-data-table
      :headers="headers"
      :items="ftData"
      :enabled-pagination="true"
      :hide-default-footer="false"
    >
      <template v-slot:[`item.actions`]="{ item }">
        {{ item.nomor}} . 
          <div class="description mt-20" v-html="item.soal"></div>
        <br>
        {{ item.soal }}
        <input type="radio" v-model="item.cek_a" @click="fnCek(item.soal_id,'a')" />
        <label for="one"> a. {{item.a}}</label>
        <br>
        <input type="radio" v-model="item.cek_b" @click="fnCek(item.soal_id,'b')"/>
        <label for="two"> b. {{item.b}}</label>
        <br>
        <input type="radio" v-model="item.cek_c" @click="fnCek(item.soal_id,'c')"/>
        <label for="two"> c. {{item.c}}</label>
        <br>
        <input type="radio" v-model="item.cek_d" @click="fnCek(item.soal_id,'d')"/>
        <label for="two"> d. {{item.d}}</label>
        <br>
        <input type="radio" v-model="item.cek_e"  @click="fnCek(item.soal_id,'e')"/>
        <label for="two"> e. {{item.e}}</label>
      </template>
    </v-data-table>
    
    </v-container>
    
    <v-navigation-drawer app color="white" right width="200">
        <v-card elevation="2" class="mt-2 mx-2 pa-3" color="cyan" >
          <v-container>
            <v-row>
              Sisa waktu <br> <h1>{{ format_jam }} </h1>
            </v-row>
          </v-container>      
        </v-card>
     <br>
      <v-divider></v-divider>
       <br>
  <b-container class="bv-example-row">
    <b-row v-for="item in ftData" :key="item.soal_id" class="ml-0">
      <b-col> 
        <v-btn outlined color="cyan"
        class="mx-0"
        active-class=""
        depressed
        rounded
        @click="pilihKat(item.soal_id), toggle"
      >
      {{ item.nomor }}. {{item.jawab}}
    </v-btn></b-col>
    </b-row>
     <br>
  </b-container>
      <v-toolbar class="pl-0">
        <v-btn outlined color="cyan" @click="saveUjian">Simpan</v-btn>
      </v-toolbar>
    </v-navigation-drawer>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import { api } from '@/components/Apiku'
import axios from 'axios'
export default {
  data: () => ({
    snackbar: false, text: 'OK', timeout: 2000,
    title:'', cr_pelanggan:'',  cari_meja:'', cari_order:'',
    loading: true, DPelanggan: false, DLsOrder:false,
    DBayar:false, cariNama:'', no_order:'', bayar: 0,
    rp_kembali:0, lsData : [],  ftData : [],
    picked:'',
    soalBlm: 'white',
    soalSudah: 'cyan',
    searchTextsLoading: true,
    nama: '',
    cek_ni:'',
    kode: '',
    id: 0,
    idMeja:0,
    timerCount: 400,
    format_jam:0,
    namaMeja:'', idKons:0,
    namaKons:'',
    orderID:0,
    bayarID:0, stTblBayar : false, stTblOrder : false, stTblBayar1 : false,
      headers: [
      { text: "Soal", value: "actions", sortable: false },
    ],
  }),
  components:{
    Navbar
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
              this.timerCount--;
          }, 1000);
          var hours = Math.floor(this.timerCount / 60);  
          var minutes = this.timerCount % 60;
          if (minutes + ''.length < 2) {
            minutes = '0' + minutes; 
          }
          this.format_jam = hours + ' : ' + minutes
        }
      },
      immediate: true // This ensures the watcher is triggered upon creation
    }
  },
  NumToTime(num) { 
    var hours = Math.floor(num / 60);  
    var minutes = num % 60;
    if (minutes + ''.length < 2) {
      minutes = '0' + minutes; 
    }
    return hours + ":" + minutes;
  },
  methods: {
    fnCek(i,b){
      const cekIndex = this.lsData.findIndex(cek => cek.soal_id === i)
      this.lsData[cekIndex]['jawab']=b
    },
    cekKey(){
      if(this.cariNama.length > 1) {
        this.ftData =  this.lsData.filter(post => post.nama.toLowerCase().includes(this.cariNama))
      }else{
        this.ftData =  this.lsData
      }
    },
    async saveUjian () {
        const dataku = {
          data: this.lsData,
          sista_id: 1,
          dtUjian: this.lsData,
          id: this.id,
          ujian_id: 1
        }
        axios
          .post(api + 'saveUjian', dataku)
          .then(response => {
            console.log('cek dataku ', dataku)
            console.log('cek ujian ', response.data)
            if (response.data.cek === 1) {
              this.text = 'Data tersimpan'
              this.snackbar=true
              
            } else {
              this.text = 'Error'
              this.snackbar=true
              console.log(' tidak sukses ')
            }
          })
    },
    async fnData () {
      axios
        .get(api + 'lsGSoal&ujian_id=1')
        .then(response => {
          if (response.data.cek === 1) {
            this.lsData = response.data.data
            this.ftData = response.data.data
          }
        })
    },
    async fnBaru() {
      this.clsForm()
      this.dialog=true
    },
    clsForm () {
      this.nama = ''
      
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
      this.searchTextsLoading = false
    }, 2000)
    this.strUser=this.$store.state.user
    this.nmUser=this.$store.state.user
    this.fnData()
    this.fnKat()
    this.clsForm()
  }
}
</script>

<style lang="scss" scoped></style>
