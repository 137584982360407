<template>
  <v-menu offset-y left>
    <template v-slot:activator="{ on }">
      <v-avatar v-on="on">
        <img :src="strUser.f_foto_user" />
      </v-avatar>
    </template>

    <v-card color="cyan"
      flat>
      <v-list class="overflow-y-auto" style="max-height: calc(100vh - 100px)">
        <v-list-item>
          <v-list-item-avatar>
            <img  :src="strUser.f_foto_user" />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title> {{ strUser.uname }}</v-list-item-title>
            <v-list-item-subtitle class="blue--text"
              >Setting Profil</v-list-item-subtitle
            >
          </v-list-item-content>
        </v-list-item>

      <v-divider></v-divider>

        <v-list-item to="/profil">
          <v-list-item-icon>
            <v-icon>mdi-account-box</v-icon>
          </v-list-item-icon>
          <v-list-item-title >Profil Sekolah</v-list-item-title>
        </v-list-item>
        <v-list-item >
          <v-list-item-icon>
            <v-icon>mdi-youtube-studio</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Media Studio</v-list-item-title>
        </v-list-item>
        
        <v-list-item >
          <v-list-item-icon>
            <v-icon>mdi-help-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Bantuan</v-list-item-title>
        </v-list-item>
        <v-list-item >
          <v-list-item-icon>
            <v-icon>mdi-comment-alert</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Send Feedback</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  data: () => ({
    strUser:[]
  }),

  methods: {
      toggle_dark_mode () {
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
        localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    }
    },
    mounted() {
      const theme = localStorage.getItem("dark_theme");
        if (theme) {
            if (theme == "true") {
                this.$vuetify.theme.dark = true;
            } else {
                this.$vuetify.theme.dark = false;
            }
        }
        this.strUser=this.$store.state.user

    }
}
</script>

<style>

</style>