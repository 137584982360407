<style scoped>
  .v-navigation-drawer__content{
    background-color: rgb(210, 155, 25) !important; border-color: rgb(210, 155, 25) !important;
  }
</style>
<template>
  <nav id="navbar" >
    <v-app-bar app clipped-left color="orange" dense >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title  class=" mt-3">
      <v-btn text plain class="mb-3 font-weight-bold" to="/">JidiSmart</v-btn>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <AccountList />
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      app
      :clipped="$route.name !== 'Watch'"
      :temporary="$route.name === 'Watch'"
      id="nav"
      color="primary red"
      dark
      flat
    >
      <div tag="div" class="v-navigation-drawer__content">
        <v-list dense nav class="py-0" tag="div"  color="#d29b19">
          <v-list-item
            :class="{
              'hidden-lg-and-up': $route.name === 'Watch' ? false : true
            }"
          >
            <v-app-bar-nav-icon
              @click="drawer = !drawer"
              class="mr-5"
            ></v-app-bar-nav-icon>
            <v-toolbar-title class="font-weight-bold">JidiSmart</v-toolbar-title>
          </v-list-item>
          <v-divider class="hidden-lg-and-up"></v-divider>
           <div>
            <v-list-item-avatar>
              <img :src="dtUser.flogo"/>
            </v-list-item-avatar>

        {{ dtUser.nmSekolah }}
          <br>
           </div>
           <div v-if="!isOnLine">
             Koneksi Internet Putus!
            </div>
           <div>
        TA : {{ dtUser.nm_ta }}
           </div>
         <!--  
        <v-list>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>

            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>
        <v-list-group
          :value="true"
          no-action
          sub-group
        >
          <template>
            <v-list-item-content>
              <v-list-item-title>Admin</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="([title, icon], i) in admins"
            :key="i"
            link
          >
            <v-list-item-title v-text="title"></v-list-item-title>

            <v-list-item-icon>
              <v-icon v-text="icon"></v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>

        <v-list-group
          no-action
          sub-group
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title>Actions</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="([title, icon], i) in cruds"
            :key="i"
            link
          >
            <v-list-item-title v-text="title"></v-list-item-title>

            <v-list-item-icon>
              <v-icon v-text="icon"></v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
     
    </v-list>
    
-->

          <div v-for="parentItem in items3" :key="parentItem.id">
            <div v-if="parentItem.kepala == '1'" class="font-weight-medium">
              {{ parentItem.nmGroup }}  
            </div>
            <v-list-item
              link
              class="mb-0" 
              :to="'/' + parentItem.direktori" 
              active-class="active-item"   v-else
            >
              <v-list-item-content>
                  <v-list-item-title class=" font-weight-medium" >
                    <v-icon v-text="parentItem.gbr" class="mr-2"></v-icon> {{ parentItem.nama }}
                  </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>

<!--
  <router-link :to="'/' + parentItem.direktori" >
    </router-link>


  v-on:click="cek_link(parentItem.direktori)"
          <span v-for="link in links" :key="link.text">
            <span v-if="link.text === 'Terms'" class="mb-2 d-block"> </span>
            <v-btn
              href
              :to="link.link"
              text
              class="text-capitalize px-1"
              small
              >{{ link.text }} halooooo</v-btn
            >
          </span>
-->
          <span>
            <span class="mb-2 d-block"> </span>
            <v-btn
              @click="tbLogout"
              text
              class="text-capitalize px-1"
              small
              >Logout</v-btn
            >
          </span>
        </v-list>
      </div>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import AccountList from '@/components/AccountList'
import { api } from '@/components/Apiku'
import axios from 'axios'
export default {
  data: () => ({
    drawer: false,
    dtUser : [],
    items2:[],
    items3:[],
    items4:[],
    modGroup:[],       
    searchText: '',
    isOnLine:navigator.onLine
    
  }),
  //this.dtUser = this.$store.state.user,
  components: {
      AccountList
    },
  methods: {
    search() {
      if (!this.searchText) return
      this.$router.push({
        name: 'Search',
        query: { 'search-query': this.searchText }
      })
    },
    cek_link(a){
      console.log('cek lin k : ', a)
      window.location = '/'+a  
    },
    tbLogout () {
      this.$store.commit('SET_RESET')
      localStorage.removeItem('Usr-jidismart')
      window.location = '/login'     
    }, 
    async fnModul2 () {
      axios
        .get(api + 'lsNavMenu&ug_id='+this.$store.state.user.ug_id)
        .then(response => {
          this.items4 = response.data.data
        })
    },
    async fnModul () {
      this.items3 = this.$store.state.menuNav
      this.dtUser = this.$store.state.user
      console.log(this.$store.state);
    },
    
  },
  mounted() {
    this.fnModul()
    this.drawer = this.$vuetify.breakpoint.mdAndDown ? false : true
    this.drawer = this.$route.name === 'Watch' ? false : this.drawer
    window.addEventListener('online', ()=>{this.isOnLine=true});
    window.addEventListener('offline', ()=>{this.isOnLine=false}); 
  }
}
</script>

<style lang="scss">
#navbar {
  .active-item {
    .v-list-item__icon {
      color: rgb(4, 143, 11) !important;
    }
  }
  .v-navigation-drawer__border {
    width: 0 !important;
  }

  .vuebar-element {
    height: 250px;
    width: 100%;
    max-width: 500px;
  }

  .vb > .vb-dragger {
    z-index: 5;
    width: 10px;
    right: 0;
  }

  .vb > .vb-dragger > .vb-dragger-styler {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: rotate3d(0, 0, 0, 0);
    transform: rotate3d(0, 0, 0, 0);
    -webkit-transition: background-color 100ms ease-out, margin 100ms ease-out,
      height 100ms ease-out;
    transition: background-color 100ms ease-out, margin 100ms ease-out,
      height 100ms ease-out;

    margin: 5px 5px 5px 0;
    border-radius: 20px;
    height: calc(100% - 10px);
    display: block;
  }

  .v-navigation-drawer__content:hover .vb > .vb-dragger > .vb-dragger-styler {
    width: 10px;
  }

  .vb.vb-scrolling-phantom > .vb-dragger > .vb-dragger-styler {
    background-color: rgba(4, 90, 238, 0.3);
    background-color: rgba(255, 255, 255, 0.3);
  }

  .vb > .vb-dragger:hover > .vb-dragger-styler {
    margin: 0px;
    width: 10px;
  }

  .vb.vb-dragging > .vb-dragger > .vb-dragger-styler {
    background-color: rgba(48, 121, 244, 0.5);
    margin: 0px;
    height: 100%;
  }

  .vb.vb-dragging-phantom > .vb-dragger > .vb-dragger-styler {
    background-color: rgba(48, 121, 244, 0.5);
  }
}
</style>
