<template>

  <section>
    <template>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </template>
  <div cols="6" sm="6">
    <v-stepper v-model="e1">
      <v-stepper-header>
        <template v-for="n in steps">
          <v-stepper-step
            :key="`${n}-step`"
            :complete="e1 > n"
            :step="n"
            editable
          >
            Step {{ n }}
          </v-stepper-step>

          <v-divider
            v-if="n !== steps"
            :key="n"
          ></v-divider>
        </template>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content
          :key="`1-content`"
          :step="1"
        >
          <v-card
            class="mb-12"
            
            height="200px"
          >
          
          halo ada apa ini minta form donk
          </v-card>

          <v-btn
            color="primary"
            @click="nextStep(1)"
          >
            Continue
          </v-btn>

          <v-btn text>
            Cancel
          </v-btn>
        </v-stepper-content>
        <v-stepper-content
          :key="`2-content`"
          :step="2"
        >
          <v-card
            class="mb-12"

            height="200px"
          >
          tessssssssss
          
          </v-card>

          <v-btn
            color="primary"
            @click="nextStep(2)"
          >
            Continue
          </v-btn>

          <v-btn text>
            Cancel
          </v-btn>
        </v-stepper-content>
        <v-stepper-content
          :key="`3-content`"
          :step="3"
        >
          <v-card
            class="mb-12"
            color="grey lighten-1"
            height="200px"
          ></v-card>

          <v-btn
            color="primary"
            @click="nextStep(3)"
          >
            Continue
          </v-btn>

          <v-btn text>
            Cancel
          </v-btn>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
  
</section>

</template>

<script>
import { api } from '@/components/Apiku'
import axios from 'axios'
// import VueSession from 'vue-session'

export default {
  name: 'Login',

  components: {

  },
  
  data: () => ({
    kode:'1234',
    email:'a',
    pwd:'a',
    snackbar : false,
    dialog: true,
    count:2,
    timeout: 2000,
    text: 'OK',
    e1: 1,
    steps: 3,
  }),
  watch: {
    steps (val) {
      if (this.e1 > val) {
        this.e1 = val
      }
    },
  },

  methods: {
    nextStep (n) {
      if (n === this.steps) {
        this.e1 = 1
      } else {
        this.e1 = n + 1
      }
    },
    tbLogin () {
    this.$store.commit('increment')
    console.log('icrament ',this.$store.state.user) // -> 1
    if (this.kode === '' || this.email === '' || this.pwd === '') {
      this.text = ' Data tidak lengkap'
      this.snackbar =true
        console.log('cek order error')
    } else {
      console.log('cek order ')
      const dataku = {
        kode: this.kode,
        email: this.email,
        pwd: this.pwd
      }
      axios
        .post(api + 'login', dataku)
        .then(response => {
          
          if (response.data.cek === 1) {
            
            this.text = 'Data tersimpan'
            this.snackbar=true
            localStorage.setItem('Usr-kasirr', JSON.stringify(response.data.data))
              //const token = 'response.token'
            //const user = response.data.data
            this.$store.commit('SET_USER', 'user')
            //this.$store.dispatch('login', { token, user });
            //this.$store.dispatch('nmUser', {token, user } );
            //this.$store.commit('set_nmUser', 'response.data.data');
            console.log('cek order okeeeeeeeee', response.data)
            //this.$store.commit('SET_USERX', response.data.data);
            console.log(' cek login ', response.data.data)
            //this.$store.getters.Usr_kasirr
            // Vue.http.headers.common['Authorization'] = 'Bearer ' + response.body.token
            //this.$router.push('/panel/search')
            window.location = '/'
            //location.reload()
            //this.fnData()
            //this.clsForm()
          } else {
            this.text = ' error'
            this.snackbar =true
            console.log(' tidak sukses ')
          }
        })
      }
    },
    mounted() {
      this.count = this.$store.getters.getcount
    }
    
  }
}
</script>
