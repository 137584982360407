<template>
  <div id="history" class="fill-height">
    <Navbar />
    <v-container fluid class="pt-0 pb-0 fill-height">
      <v-row class="fill-height">
        <v-col
          cols="12"
          sm="12"
          order="2"
          order-sm="2"
          order-md="1"
          order-lg="1"
          class="pt-8 pl-8"
        >
          <h4 class="title">Guru Bidang Studi per Lokal Kelas</h4>
          <template>
            <v-snackbar
              v-model="snackbar"
              :timeout="timeout"
            >
              {{ text }}

              <template v-slot:action="{ attrs }">
                <v-btn
                  color="blue"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
                >
                  Close
                </v-btn>
              </template>
            </v-snackbar>
            <v-dialog v-model="dialogImpor" max-width="700px">
              <v-card>
                <v-card-title>
                  <span>Impor Data</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <input type="file" @change="onFileChange" />
                    </v-col>
                    <v-col cols="12" sm="6"  md="6">
                      <a href="/data_mapel_guru.xlsx">Download template Excel Data Mata Pelajaran - Guru</a>
                    </v-col>
                  </v-row>
                </v-container>
                <small v-if="lsErrorImpor.length<=0">*harus diisi</small>
                <div v-for="(item, i) in lsErrorImpor" :key="i" style="color:red;">
                  - {{ item }}
                </div>
                <small v-if="lsErrorImpor.length>0"><i>Silahkan perbaiki data excel anda, kemudian import kembali</i></small>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="cyan" @click="dialogImpor = false">Cancel</v-btn>
                  <v-btn color="cyan" @click="saveImpor">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialog" max-width="700px">
              <v-card>
                <v-card-title>
                  <span>Data Lokal Kelas</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                  <v-row>
                    <v-col  cols="12" sm="6" md="6">
                      <v-select item-text="nm_ta" item-value="ta_id" :items="lsTA" label="Tahun Ajaran" v-model="ta_id" 
                      required v-on:change="gtTA()"></v-select>
                    </v-col>
                    <v-col  cols="12" sm="6" md="6">
                      <v-select item-text="nm_lokal_kelas" item-value="kelas_lok_id" :items="ftKelasLokal" label="Kelas" v-model="kelas_lok_id" required></v-select>
                    </v-col>
                    
                  <v-col  cols="12" sm="6" md="6">
                      <v-select item-text="label" item-value="id"  :items="ddMapel" label="Mata Pelajaran" v-model="mapel_id" required></v-select>
                    </v-col>
                    <v-col  cols="12" sm="6" md="6">
                      <v-select item-text="nama" item-value="guru_id" :items="lsGuru" label="Guru Kelas" v-model="guru_id" required></v-select>
                    </v-col>

                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                    </v-col>
                  </v-row>
                </v-container>
                <small>*harus diisi</small>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="cyan" @click="dialog = false">Cancel</v-btn>
                  <v-btn color="cyan" @click="saveData">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="konfirmHapus" max-width="400px">
              <v-card>
                <v-card-title>
                <span>Konfirmasi</span>
                </v-card-title>
                <v-card-text>
                <v-container>
                  <span>Anda yakin menghapus data ini?</span>
                </v-container>
                </v-card-text>

                <v-card-actions>
                <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" @click="konfirmHapus = false">Batal</v-btn>
                  <v-btn color="blue darken-1" @click="delData">Ya</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
             <v-row align="center" class="list px-0 mx-auto">
            <v-col cols="12" md="4">
            <v-select item-text="nm_ta" item-value="ta_id" :items="lsTA" label="Tahun Ajaran" v-model="ta_id" 
            required v-on:change="gtTA()">
            </v-select>
          </v-col>
          <v-col cols="12" md="4">
            <v-select item-text="nm_lokal_kelas" item-value="kelas_lok_id" :items="ftKelasLokal" 
            label="Lokal Kelas" v-model="kelas_lok_id" required  v-on:change="gtLokal()"></v-select>
          </v-col>
              <v-col cols="12" md="4">
                <v-btn color="cyan" class="mb-0"  @click="fnBaru" small>+ Data Guru</v-btn>
                <v-btn color="cyan" class="mb-0"  @click="fnImpor" small>Import Data</v-btn>
              </v-col>
              <v-col cols="12" sm="12">
                <v-card class="mx-auto" tile>
                  <v-data-table
                    :headers="headers"
                    :items="ftData"
                    :disable-pagination="false"
                    :hide-default-footer="false"
                    :loading="loading"
                    loading-text="Loading... Please wait"
                  >
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-icon small class="mr-2" @click="fnEdit(item.gmap_id)">mdi-pencil </v-icon>
                      <v-icon small @click="fnKonfirmasi(item.gmap_id)">mdi-delete</v-icon>
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import { api } from '@/components/Apiku'
import axios from 'axios'
import readXlsxFile from 'read-excel-file';

export default {
  data: () => ({
    snackbar: false,
    text: 'OK', konfirmHapus:false,
    timeout: 2000,
    title:'',
    kelas_lok_id:0,
    loading: true, lsKelasLokal:[],
    lsData : [],ftKelasLokal:[],lsErrorImpor:[],
    ftData : [],
    dialog : false,
    menuKat : false,
    dialogImpor:false,
    searchTextsLoading: true,
    judul: '',
    kompetensi: '',
    yt_id: '',
    id: 0,
    lokal:'',
    ddMapel : [],
    dtImpor:[],
    files: null,
    mapel_id:0,
    kelas_id:1,
    guru_id:0,
    ta_id:0,
    lsGuru:[],
    lsKelas:[],
    lsTA:[],
    ddSemester:[{"id":"1","label":"Semester 1"},{"id":"2","label":"Semester 2"}],
    headers: [
        { text: "Mata Pelajaran", align: "start", sortable: false, value: "nm_mapel" },
        { text: "Guru", value: "nm_guru", sortable: false },
        { text: "Kelas", value: "nm_lokal", sortable: false },
        { text: "HP Guru", value: "hp_guru", sortable: false },
        { text: "Aksi", value: "actions", sortable: false },
      ]
  }),
  components:{
    Navbar
  },
  methods: {
    onFileChange(event) {
      this.lsErrorImpor = [];
      let xlsxfile = event.target.files ? event.target.files[0] : null;
      readXlsxFile(xlsxfile).then((rows) => {
        this.dtImpor=rows
      })
    },
    async fnKelasLokal() {
      axios
        .get(api + 'lsKelasLokal&sk_id=' + this.$store.state.user.sk_id+'&jenjang_id='+this.$store.state.user.jenjang_id)
        .then(response => {
          console.log('lsKelasLokal', response.data)
          if (response.data.cek === 1) {
            this.lsKelasLokal = response.data.data
          }
        })
    },
    fnKonfirmasi(id){
      this.id=id
      this.konfirmHapus=true
    },
    delData () {
      const dataku = {
        user_id: this.$store.state.user.user_id,
        id: this.id
      }
      axios
        .post(api + 'delGMapKls', dataku)
        .then(response => {
          console.log('cek ', response.data)
          if (response.data.cek === 1) {
            this.text = 'Data tersimpan'
            this.snackbar=true
            this.konfirmHapus=false
            this.fnData()          
          } else {
            console.log(' tidak sukses ')
          }
        })
    },
    gtTA(){
      this.ftKelasLokal = this.lsKelasLokal.filter(m => m.ta_id.toUpperCase().includes(this.ta_id.toUpperCase()))
    },
    saveData () {
      if (this.kelas_id === 0) {
        this.snackbar =true
      } else {
       
        const dataku = {
          user_id: this.$store.state.user.user_id,
          kelas_lok_id: this.kelas_lok_id,
          guru_id: this.guru_id,
          ta_id: this.ta_id,
          mapel_id: this.mapel_id,
          sk_id:this.$store.state.user.sk_id,
          id:this.id
        }
        console.log('cek data ', dataku)
        axios
          .post(api + 'savegMapelKls', dataku)
          .then(response => {
            console.log('cek simpan ', response.data)
            if (response.data.cek === 1) {
              this.text = 'Data tersimpan'
              this.snackbar=true
              this.dialog = false
              this.fnData()
              //this.clsForm()
              
            } else {
              this.text = 'Data Sudah ada!'
              this.snackbar=true
            }
          })
      }
    },
    saveImpor () {
      const cekImpor = Number(this.dtImpor.length);
      console.log('cek im', cekImpor)
      if (cekImpor < 3) {
        this.text = 'Data kosong'
        this.snackbar =true
      } else {
        console.log('cek mapel ', this.dtImpor)
        const dataku = {
          sk_id:this.$store.state.user.sk_id,
          user_id: this.$store.state.user.user_id,
          data:this.dtImpor
        }
        axios
          .post(api + 'imporMapelGuru', dataku)
          .then(response => {
            console.log('cek ', response.data)
            if (response.data.cek === 1) {
              this.text = 'Data tersimpan'
              this.snackbar=true
              this.dialogImpor = false
              this.fnData()
              //this.clsForm()
              
            } else {
              this.lsErrorImpor = response.data.error_list; 
              console.log(' tidak sukses ', this.lsErrorImpor)
            }
          })
      }
    },
    async fnKelas() {
      axios
        .get(api + 'lsKelasLokal&sk_id=' + this.$store.state.user.sk_id)
        .then(response => {
          console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.lsKelas = response.data.data
          }
        })
    },
    async fnGuru() {
      axios
        .get(api + 'lsGuru&sk_id=' + this.$store.state.user.sk_id)
        .then(response => {
          console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.lsGuru = response.data.data
          }
        })
    },
    async fnMapel () {
      axios
        .get(api + 'ddMapel&merID=2')
        .then(response => {
          if (response.data.cek === 1) {
            this.ddMapel = response.data.data
          }
        })
    },
    async fnTA () {
      axios
        .get(api + 'lsTA&merID=2')
        .then(response => {
          console.log('kat', response.data)
          if (response.data.cek === 1) {
            this.lsTA = response.data.data
          }
        })
    },
    async fnData () {
      this.lsData = []
      this.ftData =[]
      axios
        .get(api + 'lsGMapKls&sk_id=' + this.$store.state.user.sk_id+ '&kelas_lok_id=' + this.kelas_lok_id)
        .then(response => {
          console.log('cek data halooooo kls', response.data)
          if (response.data.cek === 1) {
            this.lsData = response.data.data
            this.ftData = response.data.data
          }
        })
    },
    async fnBaru() {
      this.clsForm()
      this.dialog=true
    },
    async fnImpor() {
      this.clsForm()
      this.dialogImpor=true
    },
    gtLokal (){
      this.fnData()
    },
    async fnEdit (id) {
      const cekIndex = this.lsData.findIndex(cek => cek.gmap_id === id)
      const dtEdit = this.lsData[cekIndex]
      this.id = id
      this.guru_id = dtEdit.guru_id
      this.ta_id = dtEdit.ta_id
      this.mapel_id = dtEdit.mapel_id
      this.kelas_lok_id = dtEdit.klok_id
      this.dialog = true
    },
    clsForm () {
      this.guru_id = ''
      this.mapel_id = ''
      this.kelas_lok_id = ''
      this.id = 0
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = false
      this.searchTextsLoading = false
    }, 1000)
     // this.fnKat()
    this.fnKelasLokal()
    this.fnKelas()
    this.fnData()
    this.fnMapel()
    this.fnGuru()
    this.fnTA()
  }
}
</script>

<style lang="scss" scoped></style>
